// ClientListView.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
  TextField,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  CssBaseline,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, Visibility as VisibilityIcon, Close as CloseIcon } from '@mui/icons-material';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import api from "../authentication/axiosInstance";
import debounce from 'lodash/debounce';
import SiteContactsManager from './SiteContactsManager';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');
      `,
    },
  },
  typography: {
    fontFamily: '"Plus Jakarta Sans", sans-serif',
  },
  palette: {
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#666666',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  fontWeight: 600,
  padding: theme.spacing(1, 3),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1),
  },
}));

const ClientListView = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingClient, setEditingClient] = useState(null);
  const [deletePopoverId, setDeletePopoverId] = useState(null);
  const [selectedClientSites, setSelectedClientSites] = useState([]);
  const [isSitesModalOpen, setIsSitesModalOpen] = useState(false);
  const [sitesSearchText, setSitesSearchText] = useState('');
  const [filteredClientSites, setFilteredClientSites] = useState([]);
  const [clientContacts, setClientContacts] = useState([]);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [editingContact, setEditingContact] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const fetchClients = useCallback(async () => {
    try {
      const response = await api.get('/clients/');
      setClients(response.data);
      setFilteredClients(response.data);
    } catch (error) {
      console.error('Failed to fetch clients:', error);
    }
  }, []);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleSearch = useCallback((value) => {
    setSearchText(value);
    const filtered = clients.filter((client) =>
      client.client_name.toLowerCase().includes(value.toLowerCase()) ||
      `${client.contact_first_name} ${client.contact_last_name}`.toLowerCase().includes(value.toLowerCase()) ||
      client.contact_email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClients(filtered);
  }, [clients]);

  const debouncedSearch = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  const showModal = (client = null) => {
    setIsEditMode(!!client);
    setEditingClient(client || {
      client_name: '',
      contact_first_name: '',
      contact_last_name: '',
      contact_phone: '',
      contact_email: '',
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingClient(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (isEditMode) {
        await api.put(`/clients/${editingClient.client_id}/`, editingClient);
      } else {
        await api.post('/clients/', editingClient);
      }
      handleCloseModal();
      fetchClients();
    } catch (error) {
      console.error('Failed to submit client:', error);
    }
  };

  const handleDelete = async (clientId) => {
    try {
      await api.delete(`/clients/${clientId}/`);
      fetchClients();
    } catch (error) {
      console.error('Failed to delete client:', error);
    }
    setDeletePopoverId(null);
  };

  const fetchClientSites = async (clientId) => {
    try {
      const response = await api.get(`/clients/${clientId}/sites/`);
      setSelectedClientSites(response.data);
      setFilteredClientSites(response.data);
      setIsSitesModalOpen(true);
      setSitesSearchText('');
    } catch (error) {
      console.error('Failed to fetch client sites:', error);
    }
  };

  const handleCloseSitesModal = () => {
    setIsSitesModalOpen(false);
    setSelectedClientSites([]);
    setFilteredClientSites([]);
    setSitesSearchText('');
  };

  const handleSitesSearch = (value) => {
    setSitesSearchText(value);
    const filtered = selectedClientSites.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase()) ||
      site.region.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClientSites(filtered);
  };

  const debouncedSitesSearch = useMemo(() => debounce(handleSitesSearch, 300), [handleSitesSearch]);

  const fetchClientContacts = async (clientId) => {
    try {
      const response = await api.get(`/clients/${clientId}/contacts/`);
      setClientContacts(response.data);
    } catch (error) {
      console.error('Failed to fetch client contacts:', error);
    }
  };

  const handleContactSubmit = async (event) => {
    event.preventDefault();
    try {
      if (editingContact.contact_id) {
        await api.put(`/contacts/${editingContact.contact_id}/`, editingContact);
      } else {
        const contactData = {
          ...editingContact,
          make_primary: !clientContacts.length
        };
        await api.post(`/clients/${editingClient.client_id}/contacts/`, contactData);
      }
      await fetchClientContacts(editingClient.client_id);
      await fetchClients();
      setEditingContact(null);
    } catch (error) {
      console.error('Failed to submit contact:', error);
    }
  };

  const handleSetPrimaryContact = async (clientId, contactId) => {
    try {
      await api.put(`/clients/${clientId}/primary-contact/${contactId}/`);
      await fetchClientContacts(clientId);
      await fetchClients();
    } catch (error) {
      console.error('Failed to set primary contact:', error);
    }
  };

  const showContactModal = (contact = null) => {
    setEditingContact(contact || {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      notes: ''
    });
    setIsContactModalOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        id: 'clientName',
        header: 'Client Name',
        accessorKey: 'client_name',
      },
      {
        id: 'primaryContact',
        header: 'Primary Contact',
        accessorFn: (row) => row.primary_contact ? 
          `${row.primary_contact.first_name} ${row.primary_contact.last_name}` : 'No primary contact',
      },
      {
        id: 'contactPhone',
        header: 'Contact Phone',
        accessorFn: (row) => row.primary_contact?.phone || '',
      },
      {
        id: 'contactEmail',
        header: 'Contact Email',
        accessorFn: (row) => row.primary_contact?.email || '',
      },
      {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => (
          <Box>
            <IconButton onClick={() => showModal(row.original)} color="primary" size="small">
              <EditIcon />
            </IconButton>
            <IconButton 
              onClick={() => {
                setEditingClient(row.original);
                fetchClientContacts(row.original.client_id);
                setIsContactModalOpen(true);
              }} 
              color="primary" 
              size="small"
            >
              <AddIcon />
            </IconButton>
            <IconButton onClick={(event) => setDeletePopoverId(event.currentTarget)} color="error" size="small">
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => fetchClientSites(row.original.client_id)} color="primary" size="small">
              <VisibilityIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: filteredClients,
    getCoreRowModel: getCoreRowModel(),
  });

  const renderMobileView = () => (
    <Box>
      {filteredClients.map((client) => (
        <Paper key={client.client_id} elevation={1} sx={{ p: 2, mb: 2 }}>
          <Typography variant="subtitle1">{client.client_name}</Typography>
          <Typography variant="body2">{`${client.contact_first_name} ${client.contact_last_name}`}</Typography>
          <Typography variant="body2">{client.contact_phone}</Typography>
          <Typography variant="body2">{client.contact_email}</Typography>
          <Box sx={{ mt: 1 }}>
            <IconButton onClick={() => showModal(client)} color="primary" size="small">
              <EditIcon />
            </IconButton>
            <IconButton onClick={(event) => setDeletePopoverId(event.currentTarget)} color="error" size="small">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Paper>
      ))}
    </Box>
  );

  const renderSitesModal = () => (
    <Dialog open={isSitesModalOpen} onClose={handleCloseSitesModal} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Client Sites</Typography>
          <IconButton onClick={handleCloseSitesModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search Sites"
            value={sitesSearchText}
            onChange={(e) => debouncedSitesSearch(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon color="action" />,
            }}
          />
        </Box>
        {filteredClientSites.length > 0 ? (
          <List>
            {filteredClientSites.map((site) => (
              <Accordion key={site.site_id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" fontWeight="bold">
                        {site.site_name}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" color="textSecondary">
                          Region: {site.region}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Address: {site.address}
                        </Typography>
                      </>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <SiteContactsManager 
                    site={site}
                    clientId={editingClient?.client_id}
                    onContactsUpdate={() => fetchClientSites(editingClient?.client_id)}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary" align="center">
            No sites found.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={handleCloseSitesModal} color="primary">
          Close
        </StyledButton>
      </DialogActions>
    </Dialog>
  );

  const renderContactsModal = () => (
    <Dialog 
      open={isContactModalOpen} 
      onClose={() => setIsContactModalOpen(false)} 
      fullWidth 
      maxWidth="md"
      aria-labelledby="contacts-dialog-title"
      keepMounted
      slotProps={{
        backdrop: {
          'data-inert': true
        }
      }}
    >
      <DialogTitle id="contacts-dialog-title">
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Manage Contacts - {editingClient?.client_name}</Typography>
          <IconButton 
            onClick={() => setIsContactModalOpen(false)}
            aria-label="close dialog"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 2 }}>
          <StyledButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => showContactModal()}
            aria-label="add new contact"
          >
            Add New Contact
          </StyledButton>
        </Box>
        <List>
          {clientContacts.map((contact) => (
            <ListItem
              key={contact.contact_id}
              divider
              secondaryAction={
                <Box>
                  <StyledButton
                    variant={contact.is_primary ? "contained" : "outlined"}
                    onClick={() => handleSetPrimaryContact(editingClient.client_id, contact.contact_id)}
                    size="small"
                    sx={{ mr: 1 }}
                    aria-label={contact.is_primary ? 
                      "current primary contact" : `set ${contact.first_name} ${contact.last_name} as primary contact`}
                    disabled={contact.is_primary}
                  >
                    {contact.is_primary ? "Primary Contact" : "Set as Primary"}
                  </StyledButton>
                  <IconButton 
                    onClick={() => showContactModal(contact)} 
                    size="small"
                    aria-label={`edit ${contact.first_name} ${contact.last_name}`}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {contact.first_name} {contact.last_name}
                    {contact.is_primary && (
                      <Typography 
                        component="span" 
                        variant="caption" 
                        color="primary" 
                        sx={{ ml: 1 }}
                      >
                        (Primary)
                      </Typography>
                    )}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Email: {contact.email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Phone: {contact.phone}
                    </Typography>
                    {contact.notes && (
                      <Typography variant="body2" color="textSecondary">
                        Notes: {contact.notes}
                      </Typography>
                    )}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );

  const renderContactEditModal = () => (
    <Dialog 
      open={Boolean(editingContact)} 
      onClose={() => setEditingContact(null)}
      fullWidth 
      maxWidth="sm"
      aria-labelledby="contact-edit-dialog-title"
      keepMounted
      slotProps={{
        backdrop: {
          'data-inert': true
        }
      }}
    >
      <DialogTitle id="contact-edit-dialog-title">
        {editingContact?.contact_id ? "Edit Contact" : "Add New Contact"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleContactSubmit}>
          <StyledTextField
            fullWidth
            label="First Name"
            value={editingContact?.first_name || ''}
            onChange={(e) => setEditingContact({...editingContact, first_name: e.target.value})}
            required
            margin="normal"
            aria-label="first name"
            autoFocus
          />
          <StyledTextField
            fullWidth
            label="Last Name"
            value={editingContact?.last_name || ''}
            onChange={(e) => setEditingContact({...editingContact, last_name: e.target.value})}
            required
            margin="normal"
            aria-label="last name"
          />
          <StyledTextField
            fullWidth
            label="Phone"
            value={editingContact?.phone || ''}
            onChange={(e) => setEditingContact({...editingContact, phone: e.target.value})}
            required
            margin="normal"
            aria-label="phone number"
          />
          <StyledTextField
            fullWidth
            label="Email"
            type="email"
            value={editingContact?.email || ''}
            onChange={(e) => setEditingContact({...editingContact, email: e.target.value})}
            required
            margin="normal"
            aria-label="email address"
          />
          <StyledTextField
            fullWidth
            label="Notes"
            multiline
            rows={3}
            value={editingContact?.notes || ''}
            onChange={(e) => setEditingContact({...editingContact, notes: e.target.value})}
            margin="normal"
            aria-label="additional notes"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <StyledButton 
          onClick={() => setEditingContact(null)}
          aria-label="cancel"
        >
          Cancel
        </StyledButton>
        <StyledButton 
          onClick={handleContactSubmit} 
          variant="contained" 
          color="primary"
          aria-label={editingContact?.contact_id ? "update contact" : "add contact"}
        >
          {editingContact?.contact_id ? 'Update Contact' : 'Add Contact'}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} sx={{ maxWidth: { xs: '100%', sm: '100%', md: '1200px', lg: '1600px' }, px: { xs: 2, sm: 3 } }}>
        <Paper elevation={0} sx={{ p: { xs: 2, sm: 3, md: 4 }, mt: 4, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: { xs: 'stretch', sm: 'center' }, mb: 4 }}>
            <Typography variant="h4" component="h1" fontWeight="bold" sx={{ mb: { xs: 2, sm: 0 } }}>
              Clients
            </Typography>
            <StyledButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => showModal()}
              fullWidth={isMobile}
            >
              Add New Client
            </StyledButton>
          </Box>
          <StyledTextField
            fullWidth
            placeholder="Search clients"
            onChange={(e) => debouncedSearch(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon color="action" />,
            }}
            sx={{ mb: 4 }}
          />
          {isMobile ? (
            renderMobileView()
          ) : (
            <TableContainer component={Paper} elevation={0} sx={{ borderRadius: 2 }}>
              <Table>
                <TableHead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <StyledTableCell key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows.map(row => (
                    <TableRow key={row.id} hover>
                      {row.getVisibleCells().map(cell => (
                        <TableCell key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>

        <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
          <DialogTitle>{isEditMode ? "Edit Client" : "Add New Client"}</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <StyledTextField
                fullWidth
                label="Client Name"
                name="client_name"
                value={editingClient?.client_name || ''}
                onChange={(e) => setEditingClient({...editingClient, client_name: e.target.value})}
                required
                margin="normal"
              />
              <StyledTextField
                fullWidth
                label="First Name"
                name="contact_first_name"
                value={editingClient?.contact_first_name || ''}
                onChange={(e) => setEditingClient({...editingClient, contact_first_name: e.target.value})}
                required
                margin="normal"
              />
              <StyledTextField
                fullWidth
                label="Last Name"
                name="contact_last_name"
                value={editingClient?.contact_last_name || ''}
                onChange={(e) => setEditingClient({...editingClient, contact_last_name: e.target.value})}
                required
                margin="normal"
              />
              <StyledTextField
                fullWidth
                label="Phone"
                name="contact_phone"
                value={editingClient?.contact_phone || ''}
                onChange={(e) => setEditingClient({...editingClient, contact_phone: e.target.value})}
                required
                margin="normal"
              />
              <StyledTextField
                fullWidth
                label="Email"
                name="contact_email"
                type="email"
                value={editingClient?.contact_email || ''}
                onChange={(e) => setEditingClient({...editingClient, contact_email: e.target.value})}
                required
                margin="normal"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleCloseModal}>Cancel</StyledButton>
            <StyledButton onClick={handleSubmit} variant="contained" color="primary">
              {isEditMode ? 'Update Client' : 'Add Client'}
            </StyledButton>
          </DialogActions>
        </Dialog>

        {renderContactsModal()}
        {renderContactEditModal()}
        {renderSitesModal()}
      </Container>
    </ThemeProvider>
  );
};

export default ClientListView;