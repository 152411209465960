// WalkwayCrewDispatchPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
  ConfigProvider,
  Table,
  Button,
  Spin,
  message,
  Input,
  Select,
  Modal,
  Form,
  Divider,
  DatePicker,
  TimePicker,
  Popconfirm,
  Dropdown,
  Tag,
  Tooltip,
  AutoComplete,
  Space,
  Radio,
} from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import api from '../authentication/axiosInstance';
import { IconTractor, IconUser, IconBuildingFactory2, IconTool, IconDotsVertical, IconPlus, IconRefresh, IconX } from '@tabler/icons-react';
import moment from 'moment';
import styles from './WalkwayCrewAllocationPage.module.css';
import { useNavigate } from 'react-router-dom';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;

const WalkwayCrewDispatchPage = () => {
  // State Variables
  const [loading, setLoading] = useState(false);
  const [dispatchMessage, setDispatchMessage] = useState('');
  const [selectedDispatches, setSelectedDispatches] = useState([]); // Array to handle multiple dispatches
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [storms, setStorms] = useState([]);
  const [stormType, setStormType] = useState("");
  const [stormSeverity, setStormSeverity] = useState("");
  const [dateOfStorm, setDateOfStorm] = useState(null);
  const [stormModal, setStormModal] = useState(false);
  const [dispatchObjects, setDispatchObjects] = useState([]);
  const [savedDispatchObjects, setSavedDispatchObjects] = useState([]);
  const [dispatchLogs, setDispatchLogs] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedStorm, setSelectedStorm] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [sites, setSites] = useState([]);
  const [staff, setStaff] = useState([]);
  const [selectedStaffToAdd, setSelectedStaffToAdd] = useState({}); // Tracks selected staff per dispatch_id
  const [selectedSiteToAdd, setSelectedSiteToAdd] = useState({}); // Tracks selected site per dispatch_id
  const [selectedVehicleToSwap, setSelectedVehicleToSwap] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // For multi-select dispatch
  const [selectedRegion, setSelectedRegion] = useState('all'); 
  const [warningMessages, setWarningMessages] = useState([]);
  const [showWarnModal, setShowWarnModal] = useState(false);
  const [warnMessage, setWarnMessage] = useState('');
  const [showStandDownModal, setShowStandDownModal] = useState(false);
  const [standDownMessage, setStandDownMessage] = useState('');
  const [selectedWarnDispatches, setSelectedWarnDispatches] = useState([]); // For warning multiple dispatches
  const navigate = useNavigate();

  const [regionFilter, setRegionFilter] = useState('all'); // For region dropdown
  const [vehicleFilter, setVehicleFilter] = useState(''); // For vehicle search
  const [staffFilter, setStaffFilter] = useState(''); // For staff search
  const [siteFilter, setSiteFilter] = useState(''); // For site search

  const [dateOfStormEnd, setDateOfStormEnd] = useState(null); 
  const [isEndDateManuallyChanged, setIsEndDateManuallyChanged] = useState(false); // New state to track manual changes

  const [timeOfStorm, setTimeOfStorm] = useState(null); // New state for storm start time
  const [timeOfStormEnd, setTimeOfStormEnd] = useState(null); // New state for storm end time\

  const [sendMessages, setSendMessages] = useState(true);

  const [lastSyncedTime, setLastSyncedTime] = useState(null);
  const [selectedSavedRowKeys, setSelectedSavedRowKeys] = useState([]); // For Saved Dispatches


  const regions = useMemo(() => {
    const allRegions = dispatchObjects.map(obj => obj.region).filter(region => region);
    return Array.from(new Set(allRegions));
  }, [dispatchObjects, savedDispatchObjects]);

  // Filter dispatch objects based on selectedRegion
  const filteredDispatchObjects = useMemo(() => {
    return dispatchObjects.filter((obj) => {
      const matchesRegion = !regionFilter || regionFilter === 'all' || obj.region === regionFilter;
      const matchesVehicle = !vehicleFilter || (obj.vehicle_name && obj.vehicle_name.toLowerCase().includes(vehicleFilter.toLowerCase()));
      const matchesStaff =
        !staffFilter ||
        (obj.staff_details &&
          obj.staff_details.some((staff) => staff.staff_name.toLowerCase().includes(staffFilter.toLowerCase())));
      const matchesSite =
        !siteFilter ||
        (obj.site_details &&
          obj.site_details.some((site) => site.site_name.toLowerCase().includes(siteFilter.toLowerCase())));

      return matchesRegion && matchesVehicle && matchesStaff && matchesSite;
    });
  }, [dispatchObjects, regionFilter, vehicleFilter, staffFilter, siteFilter, savedDispatchObjects]);

    // Filter dispatch objects based on selectedRegion
    const filteredSavedDispatchObjects = useMemo(() => {
      return savedDispatchObjects.filter((obj) => {
        const matchesRegion = !regionFilter || regionFilter === 'all' || obj.region === regionFilter;
        const matchesVehicle = !vehicleFilter || (obj.vehicle_name && obj.vehicle_name.toLowerCase().includes(vehicleFilter.toLowerCase()));
        const matchesStaff =
          !staffFilter ||
          (obj.staff_details &&
            obj.staff_details.some((staff) => staff.staff_name.toLowerCase().includes(staffFilter.toLowerCase())));
        const matchesSite =
          !siteFilter ||
          (obj.site_details &&
            obj.site_details.some((site) => site.site_name.toLowerCase().includes(siteFilter.toLowerCase())));
  
        return matchesRegion && matchesVehicle && matchesStaff && matchesSite;
      });
    }, [dispatchObjects, regionFilter, vehicleFilter, staffFilter, siteFilter, savedDispatchObjects]);

  // Fetch Functions
  const fetchStaff = async () => {
    try {
      const response = await api.get("/staff/walkway_staff/");
      setStaff(response.data);
    } catch (error) {
      console.error("Failed to fetch staff:", error);
      message.error("Failed to fetch staff");
    }
  };

  const fetchSites = async () => {
    try {
      const response = await api.get("/sites/");
      setSites(response.data);
    } catch (error) {
      console.error("Failed to fetch sites:", error);
      message.error("Failed to fetch sites");
    }
  };

  const fetchStorms = async () => {
    try {
      const response = await api.get("/dispatch/storm/");
      const fetchedStorms = response.data;
      setStorms(fetchedStorms);
    } catch (error) {
      console.error("Failed to fetch storms:", error);
      message.error("Failed to fetch storms");
      return []; // Return empty array on error
    }
  };

  const fetchCrewVehicles = async () => {
    setLoading(true);
    try {
      const response = await api.get('/vehicles/crew_vehicles/');
      setVehicles(response.data);
    } catch (error) {
      console.error('Failed to fetch crew vehicles:', error);
      message.error('Failed to fetch crew vehicles');
    } finally {
      setLoading(false);
    }
  };

  const getLatestStorm = (fetchedStorms) => {
    if (fetchedStorms && fetchedStorms.length > 0) {
      // Find the storm with the latest 'date_created'
      const latestStorm = fetchedStorms.reduce((latest, current) => {
        const latestDate = latest.date_created ? new Date(latest.date_created) : new Date(0);
        const currentDate = current.date_created ? new Date(current.date_created) : new Date(0);
        return currentDate > latestDate ? current : latest;
      }, fetchedStorms[0]);
      return latestStorm;
    }
    return null;
  };

  const fetchDispatchObjects = async () => {
    try {
      const response = await api.get("/dispatch/dispatchObjects/Walkway/false/");
      if (response.data && response.data.length > 0) {
        const firstObj = response.data[0];
        if (firstObj.updated_at) {
          setLastSyncedTime(firstObj.updated_at);
        } else {
          setLastSyncedTime(null);
        }
      } else {
        setLastSyncedTime(null);
      }
      // Ensure response.data is an array, or set an empty array
      setDispatchObjects(response.data);
    } catch (error) {
      console.error("Failed to fetch dispatch objects:", error);
      message.error("Failed to fetch dispatch objects");
      setDispatchObjects([]); // Ensure it defaults to an empty array on error
    }
  };

  const fetchSavedDispatchObjects = async () => {
    try {
      const response = await api.get("/dispatch/dispatchObjects/Walkway/true/");
      // Ensure response.data is an array, or set an empty array
      setSavedDispatchObjects(response.data);
    } catch (error) {
      console.error("Failed to fetch saved dispatch objects:", error);
      message.error("Failed to fetch saved dispatch objects");
      setSavedDispatchObjects([]); // Ensure it defaults to an empty array on error
    }
  };

  const fetchDispatchLogs = async () => {
    try {
      const response = await api.get("/dispatch/dispatchLogs/Walkway/");
      // Ensure response.data is an array, or set an empty array
      setDispatchLogs(response.data);
    } catch (error) {
      console.error("Failed to fetch dispatch logs:", error);
      message.error("Failed to fetch dispatch logs");
      setDispatchLogs([]); // Ensure it defaults to an empty array on error
    }
  };

  useEffect(() => {
    fetchStorms();
    fetchDispatchLogs();
    fetchSavedDispatchObjects();
    fetchDispatchObjects();
    fetchStaff();
    fetchSites();
    fetchCrewVehicles();
  }, []);

  // Handler Functions

  // Handle selecting a staff member to add
  const handleSelectStaffToAdd = (dispatchId, staffId) => {
    setSelectedStaffToAdd((prev) => ({
      ...prev,
      [dispatchId]: staffId,
    }));
  };

  const handleSelectVehicleToSwap = (dispatchId, vehicleId) => {
    setSelectedVehicleToSwap((prev) => ({
      ...prev,
      [dispatchId]: vehicleId,
    }))
  };

  // Handle adding a staff member to the dispatch object
  const handleAddStaff = async (dispatchId) => {
    const staffId = selectedStaffToAdd[dispatchId];
    if (!staffId) {
      message.warning("Please select a staff member to add.");
      return;
    }
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        add_staff_to_dispatch: true,
        dispatch_id: dispatchId,
        staff_id: staffId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
        fetchSavedDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to add staff.");
      }
    } catch (error) {
      console.error("Failed to add staff:", error);
      message.error(error.response?.data?.error || "Failed to add staff.");
    } finally {
      // Reset the selected staff for this dispatch
      setSelectedStaffToAdd((prev) => ({
        ...prev,
        [dispatchId]: null,
      }));
    }
  };

  const handleSwapVehicle = async (dispatchId) => {
    const VehicleToSwap = selectedVehicleToSwap[dispatchId];
    if (!selectedVehicleToSwap) {
      message.warning("Please select a vehicle to swap.");
      return;
    }
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        swap_dispatch_vehicle: true,
        dispatch_id: dispatchId,
        vehicle_id: VehicleToSwap,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
        fetchSavedDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to swap vehicle.");
      }
    } catch (error) {
      console.error("Failed to swap vehicle:", error);
      message.error(error.response?.data?.error || "Failed to swap vehicle.");
    } finally {
      // Reset the selected vehicle for this dispatch
      setSelectedVehicleToSwap((prev) => ({
        ...prev,
        [dispatchId]: null,
      }));
    }
  };

  // Handle removing a staff member from the dispatch object
  const handleRemoveStaff = async (dispatchId, staffId) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        remove_staff_from_dispatch: true,
        dispatch_id: dispatchId,
        staff_id: staffId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
        fetchSavedDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to remove staff.");
      }
    } catch (error) {
      console.error("Failed to remove staff:", error);
      message.error(error.response?.data?.error || "Failed to remove staff.");
    }
  };

  // Handle selecting a site to add
  const handleSelectSiteToAdd = (dispatchId, siteId) => {
    setSelectedSiteToAdd((prev) => ({
      ...prev,
      [dispatchId]: siteId,
    }));
  };

  // Handle adding a site to the dispatch object
  const handleAddSite = async (dispatchId) => {
    const siteId = selectedSiteToAdd[dispatchId];
    if (!siteId) {
      message.warning("Please select a site to add.");
      return;
    }
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        add_site_to_dispatch: true,
        dispatch_id: dispatchId,
        site_id: siteId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
        fetchSavedDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to add site.");
      }
    } catch (error) {
      console.error("Failed to add site:", error);
      message.error(error.response?.data?.error || "Failed to add site.");
    } finally {
      // Reset the selected site for this dispatch
      setSelectedSiteToAdd((prev) => ({
        ...prev,
        [dispatchId]: null,
      }));
    }
  };

  // Handle removing a site from the dispatch object
  const handleRemoveSite = async (dispatchId, siteId) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        remove_site_from_dispatch: true,
        dispatch_id: dispatchId,
        site_id: siteId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
        fetchSavedDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to remove site.");
      }
    } catch (error) {
      console.error("Failed to remove site:", error);
      message.error(error.response?.data?.error || "Failed to remove site.");
    }
  };

  const resetStatus = async (type) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        reset_dispatch_status: true,
        type: type,
      });
      message.success("Status reset successfully");
      fetchDispatchObjects();
      fetchSavedDispatchObjects();
    } catch (error) {
      console.error("Failed to reset status:", error);
      message.error("Failed to reset status");
    }
  }

  const resetStaffStatus = async (type) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        reset_staff_warning_status: true,
        type: type,
      });
      message.success("Status reset successfully");
      fetchDispatchObjects();
      fetchSavedDispatchObjects();
    } catch (error) {
      console.error("Failed to reset status:", error);
      message.error("Failed to reset status");
    }
  }

  const validateStormDates = () => {
    if (!dateOfStorm || !timeOfStorm || !dateOfStormEnd || !timeOfStormEnd) {
      message.error("Please fill in all storm date and time fields.");
      return false;
    }

    const startDateTime = dayjs(dateOfStorm).hour(timeOfStorm.hour()).minute(timeOfStorm.minute());
    const endDateTime = dayjs(dateOfStormEnd).hour(timeOfStormEnd.hour()).minute(timeOfStormEnd.minute());

    if (endDateTime.isBefore(startDateTime)) {
      message.error("End date/time cannot be before start date/time.");
      return false;
    }

    return true;
  };

  const handleStormOk = () => {
    if (validateStormDates()) {
      createStorm();
    }
  };

  // Create Storm
  const createStorm = async () => {
    if (!stormType || !stormSeverity || !dateOfStorm || !timeOfStorm || !dateOfStormEnd || !timeOfStormEnd) {
      message.error("Please fill out all fields");
      return;
    }

    // Combine date and time for start and end
    const startDateTime = dayjs.tz(
      `${dateOfStorm.format("YYYY-MM-DD")} ${timeOfStorm.format("HH:mm")}`,
      "America/New_York"
    ).utc();

    const endDateTime = dayjs.tz(
      `${dateOfStormEnd.format("YYYY-MM-DD")} ${timeOfStormEnd.format("HH:mm")}`,
      "America/New_York"
    ).utc();

    try {
      await api.post("/dispatch/storm/", {
        type: stormType,
        severity: stormSeverity,
        date_of_storm: startDateTime.toISOString(),
        date_of_storm_end: endDateTime.toISOString(),
      });
      message.success("Storm created successfully");
      // Reset states after creation
      fetchStorms();
      setStormType("");
      setStormSeverity("");
      setDateOfStorm(null);
      setTimeOfStorm(null);
      setDateOfStormEnd(null);
      setTimeOfStormEnd(null);
    } catch (error) {
      console.error("Failed to create storm:", error);
      message.error("Failed to create storm");
    }
  };

  const handleOpenMultiConfirmModal = async (selectedRows) => {
    setLoading(true); // Optional: Show loading indicator
    try {
      // Fetch the latest storms
      const latestStorm = getLatestStorm(storms); // Determine latest storm
      
      if (latestStorm && latestStorm.storm_id) {
        setSelectedStorm(latestStorm.storm_id); // Set as selected storm
      } else {
        setSelectedStorm(null); // No storms available
        message.warning("No storms available. Please create a storm first.");
        return; // Exit the function if no storms are available
      }
  
      // Transform the selected rows for dispatching
      const transformedDispatches = selectedRows.map(record => ({
        vehicle_id: record.vehicle,
        staff_ids: record.staff_details.map(staff => staff.staff_id),
        site_ids: record.site_details.map(site => site.site_id),
        equipment_ids: record.equipment_details.map(equip => equip.equipment_id),
        dispatch_id: record.dispatch_id
      }));
      
      setSelectedDispatches(transformedDispatches);
      setShowConfirmModal(true); // Open the Confirm Dispatch modal
    } catch (error) {
      console.error("Error opening Multi Confirm Dispatch modal:", error);
      message.error("Failed to open Confirm Dispatch modal.");
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const handleOpenMultiWarnModal = async (selectedRows) => {
    setLoading(true); // Optional: Show loading indicator
    try {
      // Fetch the latest storms
      const latestStorm = getLatestStorm(storms); // Determine latest storm
      if (latestStorm && latestStorm.storm_id) {
        setSelectedStorm(latestStorm.storm_id); // Set as selected storm
      } else {
        setSelectedStorm(null); // No storms available
        message.warning("No storms available. Please create a storm first.");
        return; // Exit the function if no storms are available
      }
  
      // Transform the selected rows for sending warnings
      const transformedDispatches = selectedRows.map(record => ({
        staff_ids: record.staff_details.map(staff => staff.staff_id),
        dispatch_id: record.dispatch_id
      }));
      
      setSelectedWarnDispatches(transformedDispatches);
      setShowWarnModal(true); // Open the Confirm Warning modal
    } catch (error) {
      console.error("Error opening Multi Confirm Warning modal:", error);
      message.error("Failed to open Confirm Warning modal.");
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const handleStandDown = async () => {
    setShowStandDownModal(true);
    setStandDownMessage("");
  }

  const handleConfirmStandDown = async () => {
    try {
      const response = await api.post("/dispatch/warning_messages/", {
        send_stand_down_messages: true,
        custom_message: standDownMessage,
      });
  
      const { results } = response.data;
  
      if (!results || results.length === 0) {
        message.info("No stand down messages were processed.");
        return;
      }
  
      results.forEach((result) => {
        const { staff_id, staff_name, status, message: msg, error, whatsapp_response } = result;
        
        const identifier = `Staff ID ${staff_id} (${staff_name})`;
  
        if (status === "success") {
          message.success(`${identifier}: ${msg}`);
          // Optionally, you can log or handle whatsapp_response here
        } else if (status === "partial_success") {
          message.warning(`${identifier}: ${msg}`);
          if (error) {
            message.error(`Error for ${identifier}: ${error}`);
          }
          // Optionally, log the whatsapp_response if available
          if (whatsapp_response) {
            console.error(`WhatsApp Response for ${identifier}:`, whatsapp_response);
          }
        } else {
          // Handle any other unexpected statuses
          message.error(`${identifier}: Unexpected status "${status}".`);
        }
      });
    } catch (error) {
      console.error("Failed to send stand down messages:", error);
      message.error(error.response?.data?.message || "Failed to send stand down messages.");
    } finally {
      setShowStandDownModal(false);
      setStandDownMessage("");
      fetchStaff();
      fetchDispatchObjects();
      fetchSavedDispatchObjects();
    }
  };

  // Modify handleConfirmDispatch to clear selections from both tables
  const handleConfirmDispatch = async () => {
    if (!selectedDispatches || selectedDispatches.length === 0) {
      message.error("No dispatch details selected.");
      setShowConfirmModal(false);
      return;
    }

    if (!selectedStorm) {
      message.error("Please select a storm to dispatch the crew.");
      setShowConfirmModal(false);
      return;
    }

    // Check if both date and time are selected
    if (!selectedDate || !selectedTime) {
      message.error("Please select both a date and time for the dispatch.");
      return;
    }

    // Prepare the dispatched_for datetime
    const dispatchedFor = moment(`${selectedDate.format("YYYY-MM-DD")} ${selectedTime.format("HH:mm")}`).toISOString();

    // Prepare dispatch list with shared parameters
    const dispatchList = selectedDispatches.map(dispatch => ({
      vehicle_id: dispatch.vehicle_id,
      staff_ids: dispatch.staff_ids,
      site_ids: dispatch.site_ids,
      equipment_ids: dispatch.equipment_ids,
      dispatch_id: dispatch.dispatch_id,
      storm_id: selectedStorm,
      message: dispatchMessage,
      dispatched_for: dispatchedFor,
    }));

    try {
      await createWalkwayCrewDispatch(dispatchList);
      setShowConfirmModal(false);
      setDispatchMessage(""); // Reset the message after dispatch
      setSelectedDispatches([]);
      setSelectedDate(null);
      setSelectedTime(null);
      setSelectedStorm(null);
      setSelectedRowKeys([]); // Clear selected rows from Active Dispatches
      setSelectedSavedRowKeys([]); // Clear selected rows from Saved Dispatches
      fetchSavedDispatchObjects(); // Refresh Saved Dispatches as well
      fetchDispatchObjects();
    } catch (error) {
      console.error("Failed to create dispatch:", error);
      message.error(error.message || "Failed to create dispatch");
    }
  };

  // Modify handleConfirmWarn to clear selections from both tables
  const handleConfirmWarn = async () => {
    if (!selectedWarnDispatches || selectedWarnDispatches.length === 0) {
      message.error("No dispatch details selected.");
      setShowWarnModal(false);
      return;
    }

    if (!selectedStorm) {
      message.error("Please select a storm to send the message.");
      setShowWarnModal(false);
      return;
    }

    // Prepare dispatch list with shared parameters
    const dispatchList = selectedWarnDispatches.map(dispatch => ({
      staff_ids: dispatch.staff_ids,
      dispatch_id: dispatch.dispatch_id,
      storm_id: selectedStorm,
      message: warnMessage,
    }));

    try {
      await sendWarnings(dispatchList);
      setShowWarnModal(false);
      setWarnMessage(""); // Reset the message after dispatch
      setSelectedWarnDispatches([]);
      setSelectedStorm(null);
      setSelectedRowKeys([]); // Clear selected rows from Active Dispatches
      setSelectedSavedRowKeys([]); // Clear selected rows from Saved Dispatches
      fetchSavedDispatchObjects(); // Refresh Saved Dispatches as well
      fetchDispatchObjects();
    } catch (error) {
      console.error("Failed to create dispatch:", error);
      message.error(error.message || "Failed to create dispatch");
    }
  };

  const handleSaveDispatch = async (dispatchId) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        save_dispatch_object: true,
        dispatch_id: dispatchId,
      });
  
      if (response.data.status === "success") {
        message.success(response.data.message);
        fetchDispatchObjects();
        fetchSavedDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to save dispatch object.");
      }
    } catch (error) {
      console.error("Failed to save dispatch object:", error);
      message.error(error.response?.data?.error || "Failed to save dispatch object.");
    }
  };

  const handleDeleteDispatch = async (dispatchId) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        delete_dispatch_object: true,
        dispatch_id: dispatchId,
      });
  
      if (response.data.status === "success") {
        message.success(response.data.message);
        fetchDispatchObjects();
        fetchSavedDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to delete dispatch object.");
      }
    } catch (error) {
      console.error("Failed to delete dispatch object:", error);
      message.error(error.response?.data?.error || "Failed to delete dispatch object.");
    }
  };

  // Function to handle dispatching (calls the API)
  const createWalkwayCrewDispatch = async (dispatchList) => {
    try {
      let response;
      if (sendMessages) {
        response = await api.post("/dispatch/dispatchLogs/", {
          create_walkway_crew_dispatch_logs: true,
          dispatches: dispatchList,
        });
      } else {
        response = await api.post("/dispatch/dispatchLogs/", {
          manual_walkway_crew_dispatch: true,
          dispatches: dispatchList,
        });
      }

      // Destructure response data for easier access
      const { results, success, message: responseMessage } = response.data;

      // Check if 'results' array exists and is an array with elements
      if (Array.isArray(results) && results.length > 0) {
        // Process each result in the 'results' array
        results.forEach((result) => {
          const { dispatch_number, status, message: msg, error, whatsapp_response } = result;

          if (status === "success") {
            message.success(`Dispatch ${dispatch_number}: ${msg}`);
          } else if (status === "partial_success") {
            message.warning(`Dispatch ${dispatch_number}: ${msg}`);

            if (error) {
              message.error(`Error: ${error}`);
            }

            if (whatsapp_response) {
              console.error(`WhatsApp Response for Dispatch ${dispatch_number}:`, whatsapp_response);
            }
          } else if (status === "failed") {
            message.error(`Dispatch ${dispatch_number}: ${msg}`);
          } else {
            // Handle unexpected status values
            message.error(`Dispatch ${dispatch_number}: Unknown status "${status}".`);
          }
        });
      } else if (success) {
        // If 'results' array is not present, display a single success message
        message.success(responseMessage || 'Operation completed successfully.');
      } else {
        // Handle cases where neither 'results' nor 'success' is present
        // This could be due to an unexpected response structure or an error
        message.error(responseMessage || 'An unexpected error occurred.');
      }
    } catch (error) {
      console.error("Failed to create dispatch:", error);
      message.error(error.response?.data?.message || "Failed to create dispatch");
    }
  };

    // Function to handle dispatching (calls the API)
    const sendWarnings = async (dispatchList) => {
      try {
        const response = await api.post("/dispatch/warning_messages/", {
          send_warning_messages: true,
          type: "Walkway",
          dispatches: dispatchList,
        });
    
        // Assuming the backend returns a results array as per the updated backend function
        const { results } = response.data;
    
        // Process results to show messages to the user
        results.forEach((result) => {
          const { dispatch_number, status, message: msg, error, whatsapp_response } = result;
        if (status === "success") {
            message.success(`Dispatch ${dispatch_number}: ${msg}`);
          } else if (status === "partial_success") {
            message.warning(`Dispatch ${dispatch_number}: ${msg}`);
            if (error) {
              message.error(`Error: ${error}`);
            }
            if (whatsapp_response) {
              console.error(`WhatsApp Response for Dispatch ${dispatch_number}:`, whatsapp_response);
            }
          } else if (status === "failed") {
            message.error(`Dispatch ${dispatch_number}: ${msg}`);
          }
        });
      } catch (error) {
        console.error("Failed to create dispatch:", error);
        message.error(error.response?.data?.message || "Failed to create dispatch");
      }
    };

  // Sync Dispatch Data
  const syncDispatchData = async () => {
    try {
      setLoading(true); // Optional: Show loading spinner while syncing
      const response = await api.post("/dispatch/dispatchObjects/", {
        create_walkway_crew_dispatch_objects: true, // Trigger the backend function
      });
      message.success("Walkway Crew Dispatch data synced successfully!");
      fetchDispatchObjects(); // Refresh the dispatch objects after syncing
      fetchSavedDispatchObjects();
    } catch (error) {
      console.error("Failed to sync dispatch data:", error);
      message.error("Failed to sync dispatch data");
    } finally {
      setLoading(false); // Optional: Hide loading spinner
    }
  };

  // Handle row selection change for multi-select
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Define rowSelection for the table
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const rowSelectionSaved = {
    selectedRowKeys: selectedSavedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedSavedRowKeys(newSelectedRowKeys);
    },
  };

  // Get the selected dispatch objects based on selectedRowKeys
  const selectedRows = dispatchObjects.filter(obj => selectedRowKeys.includes(obj.dispatch_id));

  // Modify DispatchSelectedButton to handle both Active and Saved Dispatch selections
  const DispatchSelectedButton = () => {
    const handleDispatchSelected = () => {
      const totalSelected = selectedRowKeys.length + selectedSavedRowKeys.length;
      if (totalSelected === 0) {
        message.warning("Please select at least one dispatch to proceed.");
        return;
      }

      // Combine selected dispatches from both tables
      const selectedActiveRows = dispatchObjects.filter(obj => selectedRowKeys.includes(obj.dispatch_id));
      const selectedSavedRows = savedDispatchObjects.filter(obj => selectedSavedRowKeys.includes(obj.dispatch_id));
      const combinedSelectedRows = [...selectedActiveRows, ...selectedSavedRows];

      // Check if all selected dispatches have at least one staff assigned
      const dispatchesWithoutStaff = combinedSelectedRows.filter(dispatch => !dispatch.staff_details || dispatch.staff_details.length === 0);
      if (dispatchesWithoutStaff.length > 0) {
        message.error("One or more selected dispatches have no staff assigned.");
        return;
      }

      handleOpenMultiConfirmModal(combinedSelectedRows);
    };

    return (
      <Button
        type="primary"
        onClick={handleDispatchSelected}
        disabled={selectedRowKeys.length + selectedSavedRowKeys.length === 0}
        style={{ borderRadius: "5px", height: '34px', boxShadow: 'none' }}
      >
        Dispatch ({selectedRowKeys.length + selectedSavedRowKeys.length})
      </Button>
    );
  };

  // Modify WarnSelectedButton to handle both Active and Saved Dispatch selections
  const WarnSelectedButton = () => {
    const handleWarnSelected = () => {
      const totalSelected = selectedRowKeys.length + selectedSavedRowKeys.length;
      if (totalSelected === 0) {
        message.warning("Please select at least one dispatch to proceed.");
        return;
      }

      // Combine selected dispatches from both tables
      const selectedActiveRows = dispatchObjects.filter(obj => selectedRowKeys.includes(obj.dispatch_id));
      const selectedSavedRows = savedDispatchObjects.filter(obj => selectedSavedRowKeys.includes(obj.dispatch_id));
      const combinedSelectedRows = [...selectedActiveRows, ...selectedSavedRows];

      // Check if all selected dispatches have at least one staff assigned
      const dispatchesWithoutStaff = combinedSelectedRows.filter(dispatch => !dispatch.staff_details || dispatch.staff_details.length === 0);
      if (dispatchesWithoutStaff.length > 0) {
        message.error("One or more selected dispatches have no staff assigned.");
        return;
      }

      handleOpenMultiWarnModal(combinedSelectedRows);
    };

    return (
      <Button
        type="primary"
        onClick={handleWarnSelected}
        disabled={selectedRowKeys.length + selectedSavedRowKeys.length === 0}
        style={{ borderRadius: "5px", height: '34px', boxShadow: 'none' }}
      >
        Warn ({selectedRowKeys.length + selectedSavedRowKeys.length})
      </Button>
    );
  };

  // Columns Definition
  const columns = [
    {
      title: 'Crew Name',
      key: 'walkway_crew_name',
      width: 150,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '85px' }}>
          <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.walkway_crew_name}</span>
        </div>
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region', // Added dataIndex for better integration with sorting
      key: 'region',
      width: 100,
      sorter: (a, b) => {
        const regionA = a.region ? a.region.toLowerCase() : '';
        const regionB = b.region ? b.region.toLowerCase() : '';
        return regionA.localeCompare(regionB);
      },
      sortDirections: ['ascend', 'descend'], // Optional: Define sort directions
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '85px' }}>
          <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.region}</span> 
        </div>
      ),
    },
    {
      title: 'Vehicle Info',
      key: 'vehicle_info',
      width: 80,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '85px' }}>
          <span style={{ fontWeight: '400', fontSize: '16px' }}>
            <IconTractor size={14} style={{ marginRight: '3px' }} stroke={2} />
            {record.vehicle_name}
          </span>
          {/* <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_type}</span> */}
          {/* <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_model}</span> */}
        </div>
      ),
    },
    {
      title: 'Staff Assigned',
      key: 'staff_assigned',
      width: 200,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', height: '85px' }}>
          {record.staff_details.slice(0, 3).map((staff) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: "ellipsis",
                maxWidth: '250px'
              }}
              key={staff.staff_id}
            >
              <span style={{ fontWeight: '400', fontSize: '16px', marginRight: "4px" }}>
                <IconUser size={14} style={{ marginRight: '3px' }} stroke={2.5} />
                {staff.staff_name}
              </span>
              {/* <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                - {staff.staff_position}
              </span> */}
            </div>
          ))}

          {/* Show "+ x more staff" if there are more than 3 staff */}
          {record.staff_details.length > 3 && (
            <span style={{ fontSize: '14px', color: '#888' }}>
              + {record.staff_details.length - 3} more staff
            </span>
          )}

          {record.staff_details.length === 0 && 'No Staff Assigned'}
        </div>
      ),
    },
    {
      title: 'Sites Assigned',
      key: 'sites_assigned',
      width: 260,
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start', // Ensures left alignment
            justifyContent: 'center',
            height: '85px',
            overflow: 'hidden',
          }}
        >
          {record.site_details && record.site_details.length > 0 ? (
            <>
              {record.site_details.slice(0, 3).map((site, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '300px',
                  }}
                  key={site.site_id}
                >
                  {/* **Ordering Number** */}
                  <span
                    style={{
                      fontWeight: '600',
                      fontSize: '14px',
                      minWidth: '24px', // Increased width to accommodate double digits
                      textAlign: 'left',
                    }}
                  >
                    {index + 1}.
                  </span>
  
                  {/* **Site Information** */}
                  <span style={{ fontWeight: '400', fontSize: '16px', marginRight: '4px' }}>
                    <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} strokeWidth={2}/>
                    {site.site_name}
                  </span>
                  
                </div>
              ))}
  
              {/* Show "+ x more sites" if there are more than 3 sites */}
              {record.site_details.length > 3 && (
                <span style={{ fontSize: '14px', color: '#888' }}>
                  + {record.site_details.length - 3} more sites
                </span>
              )}
            </>
          ) : (
            <span style={{ fontSize: '14px', color: '#888' }}>No Sites Assigned</span>
          )}
        </div>
      ),
    },
    {
      title: 'Equipment Assigned',
      key: 'equipment_assigned',
      width: 120,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', height: '85px' }}>
          {record.equipment_details.slice(0, 3).map((equipment, index) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: "ellipsis",
                maxWidth: '280px'
              }}
              key={index}
            >
              <span style={{ fontWeight: '400', fontSize: '16px', marginRight: "4px" }}>
                <IconTool size={14} stroke={2} style={{ marginRight: '3px' }} />
                {equipment.equipment_name}
              </span>
              {/* <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                - {equipment.equipment_type_name}
              </span> */}
            </div>
          ))}

          {/* Show "+ x more equipment" if there are more than 3 equipment */}
          {record.equipment_details.length > 3 && (
            <span style={{ fontSize: '14px', color: '#888' }}>
              + {record.equipment_details.length - 3} more pieces of equipment
            </span>
          )}

          {record.equipment_details.length === 0 && 'No Equipment Assigned'}
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      width: 100,
      render: (text, record) => {
        // Determine the color and display text based on record.status using conditional rendering
        const tagColor =
          record.status === 'Ready'
            ? 'green'
            : record.status === 'Warned'
            ? 'yellow'
            : record.status === 'Dispatched'
            ? 'lightblue'
            : 'green'; // Default to green if status is not recognized
  
        const statusText =
          record.status === 'Ready'
            ? 'Ready'
            : record.status === 'Warned'
            ? 'Warned'
            : record.status === 'Dispatched'
            ? 'Dispatched'
            : record.status; //default value
  
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              height: '85px',
            }}
          >
            <Tag color={tagColor} key={record.status}>
              {statusText}
            </Tag>
            {record.status !== 'Ready' && (
              <Button onClick={() => {resetStatus(record.dispatch_id)}} type="Primary" style={{ backgroundColor: '#c3c4c2', borderColor: '#c3c4c2', color: 'white', width: '70px' }}>
                Reset
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 80, // Adjusted width to accommodate Popconfirm if needed
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '6px',
            width: '100%', // Ensure the container takes full width if necessary
          }}
        >
          {/* Save Button with Popconfirm */}
          <Popconfirm
            title="Are you sure you want to save this dispatch?"
            onConfirm={() => handleSaveDispatch(record.dispatch_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              style={{ width: '80px' }}
            >
              Save
            </Button>
          </Popconfirm>
    
          {/* Conditionally render the Unsave Button with Popconfirm */}
          {record.saved && (
            <Popconfirm
              title="Are you sure you want to unsave this dispatch?"
              onConfirm={() => handleDeleteDispatch(record.dispatch_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: '#FF4D4E',
                  borderColor: '#FF4D4E',
                  color: 'white',
                  width: '80px',
                }}
              >
                Unsave
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];


  const items = [
    {
      key: '1',
      label: 'Create New Storm',
      icon: <IconPlus size={16} />,
      onClick: () => setStormModal(true),
    },
    {
      key: '2',
      label: (
        <Popconfirm
          title="Are you sure you want to sync the dispatch data?"
          onConfirm={syncDispatchData}
          okText="Yes"
          cancelText="No"
        >
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <IconRefresh size={16} style={{ marginRight: 8 }} />
            Sync Dispatch Data
          </div>
        </Popconfirm>
      ),
    },
    {
      key: '3',
      label: (
        <Popconfirm
          title="Are you sure you want to reset the status of the dispatch data?"
          onConfirm={() => {resetStatus('Walkway')}}
          okText="Yes"
          cancelText="No"
        >
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <IconRefresh size={16} style={{ marginRight: 8 }} />
            Reset Status of Dispatch Data
          </div>
        </Popconfirm>
      ),
    },
    {
      key: '4',
      label: (
        <Popconfirm
          title="Are you sure you want to reset the status of the walkway Staff?"
          onConfirm={() => {resetStaffStatus('Walkway')}}
          okText="Yes"
          cancelText="No"
        >
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <IconRefresh size={16} style={{ marginRight: 8 }} />
            Reset Status of Walkway Staff
          </div>
        </Popconfirm>
      ),
    },
  ];

  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
      <DndProvider backend={HTML5Backend}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px' }}>
          <div style={{ width: 'calc(100% - 10px)', marginLeft: '20px' }}>
            {/* Custom Styles */}
            <style>
              {`.locked-row {
                  background-color: #f0f0f0 !important;
                }
              `}
            </style>
            <style>
              {`.ant-table-tbody > tr:hover > td {
                  background: none !important;
                }
              `}
            </style>
            {/* Header */}
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: '30px',
                flexDirection: 'column'
              }}
            >
              {/* Left-aligned elements: Title and Region Filter */}
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span
                  style={{
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    fontWeight: '600',
                    fontSize: '40px',
                  }}
                >
                  Dispatch <span style={{ fontWeight: '400' }}>- Walkway Crew</span>
                </span>
                <Dropdown trigger={['click']} menu={{items}}>
                  <IconDotsVertical size={24} style={{ cursor: 'pointer' }}/>
                </Dropdown>

                {/* Region Filter */}
              </div>

              {/* Right-aligned elements: Action Buttons */}
              <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom:15, justifyContent:'flex-start', width: '100%'}}>
              {/* Region Dropdown */}
              <Select
                placeholder="Filter by Region"
                style={{ width: 190 }}
                value={regionFilter}
                onChange={(value) => setRegionFilter(value)}
              >
                <Option value="all">All Regions</Option>
                {regions.map((region) => (
                  <Option key={region} value={region}>
                    {region}
                  </Option>
                ))}
              </Select>

              {/* Vehicle Search */}
              <Input.Search
                placeholder="Search by Vehicle"
                value={vehicleFilter}
                onChange={(e) => setVehicleFilter(e.target.value)}
                style={{ width: 190 }}
              />

              {/* Staff Search */}
              <Input.Search
                placeholder="Search by Staff"
                value={staffFilter}
                onChange={(e) => setStaffFilter(e.target.value)}
                style={{ width: 190 }}
              />

              {/* Site Search */}
              <Input.Search
                placeholder="Search by Site"
                value={siteFilter}
                onChange={(e) => setSiteFilter(e.target.value)}
                style={{ width: 190, marginRight: 'auto' }}
              />
              {lastSyncedTime && (
                <div style={{ fontSize: '17px', color: '#555' }}>
                  <strong>Time last synced:</strong> {new Date(lastSyncedTime).toLocaleString()}
                </div>
              )}
              <DispatchSelectedButton />
              <WarnSelectedButton />
              <Button
                type="primary"
                onClick={handleStandDown}
                style={{ borderRadius: "5px", height: '34px', boxShadow: 'none' }}
              >
                Send Stand Down message
              </Button>
            </div>
            </div>
            
            {/* Loading Spinner */}
            <Spin spinning={loading}>
              <div style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
                <Table
                  dataSource={filteredDispatchObjects}
                  columns={columns}
                  rowKey="dispatch_id"
                  pagination={false}
                  scroll={{ y: 690 }}
                  rowSelection={rowSelection} // Enable row selection
                  expandable={{
                    expandedRowRender: (record) => (
                      <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row', gap: '60px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '500px' }}>
                          <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>Staff Members</span>
                          <Space.Compact style={{ width: '518px', marginBottom: '10px' }}>
                            <Select
                              allowClear 
                              showSearch
                              style={{ width: '100%' }} 
                              placeholder='Select a staff member' 
                              value={selectedStaffToAdd[record.dispatch_id] || null} 
                              onChange={(value) => handleSelectStaffToAdd(record.dispatch_id, value)} 
                              options={staff.map((staffMember) => ({ value: staffMember.staff_id, label: `${staffMember.name} - ${staffMember.position}`,}))}
                              filterOption={(inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase())}
                            />
                            <Tooltip title="Add Staff">
                              <Button style={{ boxShadow: 'none'  }} onClick={() => handleAddStaff(record.dispatch_id)} disabled={!selectedStaffToAdd[record.dispatch_id]}>
                                <IconPlus size={14} style={{ color: 'black', marginTop: '4px'}}/>
                              </Button>
                            </Tooltip>
                          </Space.Compact>
                          <div style={{ maxHeight: '225px', overflow: 'hidden', width: '518px', overflowY: 'scroll' }}>
                          {record.staff_details && record.staff_details.length > 0 ? (
                              record.staff_details.map((staffMember) => (
                                <div
                                  key={staffMember.staff_id}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    padding: '8px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    backgroundColor: 'white',
                                    width: '500px'
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                    <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                                      <IconUser size={14} style={{ marginRight: '3px' }} />
                                      {staffMember.staff_name}
                                    </span>
                                    <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                                      - {staffMember.staff_position}
                                    </span>
                                    {staffMember.warning_status && (
                                      <>
                                        <span style={{ margin: '0 4px' }}> - </span>
                                        {staffMember.warning_status === "Ready" && (
                                          <Tag color={'green'}>
                                            Ready
                                          </Tag>
                                        )}
                                        {staffMember.warning_status === "Warned" && (
                                          <>
                                            <Tag color={'yellow'}>
                                              Warned
                                            </Tag>
                                            <Button onClick={() => {resetStaffStatus(staffMember.staff_id)}} type="Primary" style={{ backgroundColor: '#c3c4c2', borderColor: '#c3c4c2', color: 'white', width: '55px', height:"25px", padding:0, fontSize:"13px" }}>
                                              Reset
                                            </Button>
                                          </>
                                        )}
                                        {staffMember.warning_status === "Dispatched" && (
                                          <>
                                            <Tag color={'lightblue'}>
                                              Dispatched
                                            </Tag>
                                            <Button onClick={() => {resetStaffStatus(staffMember.staff_id)}} type="Primary" style={{ backgroundColor: '#c3c4c2', borderColor: '#c3c4c2', color: 'white', width: '55px', height:"25px", padding:0, fontSize:"13px" }}>
                                              Reset
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <Tooltip title="Remove Staff">
                                    <IconX size={16} stroke={2} onClick={() => handleRemoveStaff(record.dispatch_id, staffMember.staff_id)} style={{ cursor: 'pointer', marginRight: "10px" }} />
                                  </Tooltip>
                                </div>
                              ))
                            ) : (
                              <p style={{ fontSize: '14px', fontWeight: '400', marginTop: '0px', marginLeft: '2px' }}>No staff assigned.</p>
                            )}
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '500px' }}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>Sites</span>
                        <Space.Compact style={{ width: '518px', marginBottom: '10px' }}>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select a site"
                              style={{ width: "100%" }}
                              value={selectedSiteToAdd[record.dispatch_id] || null}
                              onChange={(value) => handleSelectSiteToAdd(record.dispatch_id, value)}
                              options={sites.map((site) => ({
                                value: site.site_id,
                                label: `${site.client_name} - ${site.site_name}`,
                              }))}
                              filterOption={(inputValue, option) =>
                                option.label.toLowerCase().includes(inputValue.toLowerCase())
                              }
                            />
                            <Tooltip title="Add Site">
                              <Button style={{ boxShadow: 'none'  }} onClick={() => handleAddSite(record.dispatch_id)} disabled={!selectedSiteToAdd[record.dispatch_id]}>
                                <IconPlus size={14} style={{ color: 'black', marginTop: '4px'}}/>
                              </Button>
                            </Tooltip>
                          </Space.Compact>
                           {record.site_details && record.site_details.length > 0 ? (
                              record.site_details.map((site, index) => (
                                <div
                                  key={site.site_id}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    padding: '8px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    backgroundColor: 'white',
                                    width: '100%'
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                    {/* Order Number */}
                                    <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "8px" }}>
                                      {index + 1}.
                                    </span>
                                    
                                    {/* Client Name with Icon */}
                                    <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                                      <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                                      {site.client_name}
                                    </span>
                                    
                                    {/* Site Name */}
                                    <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                                      - {site.site_name}
                                    </span>
                                  </div>
                                  
                                  {/* Remove Site Button */}
                                  <Tooltip title="Remove Site">
                                    <IconX size={16} stroke={2} onClick={() => handleRemoveSite(record.dispatch_id, site.site_id)} style={{ cursor: 'pointer' }} />
                                  </Tooltip>
                                </div>
                              ))
                            ) : (
                              <p>No sites assigned.</p>
                            )}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '500px' }}>
                          <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>Vehicle</span>
                          <Space.Compact style={{ width: '518px', marginBottom: '10px' }}>
                            <Select
                              allowClear 
                              showSearch
                              style={{ width: '100%' }} 
                              placeholder='Select a Vehicle' 
                              value={selectedVehicleToSwap[record.dispatch_id] || null} 
                              onChange={(value) => handleSelectVehicleToSwap(record.dispatch_id, value)} 
                              options={vehicles.map((vehicle) => ({ value: vehicle.vehicle_id, label: `${vehicle.vehicle_name} - ${vehicle.vehicle_model} - ${vehicle.vehicle_type}`,}))}
                              filterOption={(inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase())}
                            />
                            <Tooltip title="Swap Vehicle">
                              <Button style={{ boxShadow: 'none'  }} onClick={() => handleSwapVehicle(record.dispatch_id)} disabled={!selectedVehicleToSwap[record.dispatch_id]}>
                                <IconPlus size={14} style={{ color: 'black', marginTop: '4px'}}/>
                              </Button>
                            </Tooltip>
                          </Space.Compact>
                          <div style={{ maxHeight: '225px', overflow: 'hidden', width: '518px', overflowY: 'scroll' }}>
                          {record.vehicle_name ? (
                                <div
                                  key={record.vehicle_name}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    padding: '8px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    backgroundColor: 'white',
                                    width: '500px'
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                    <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                                      <IconTractor size={14} style={{ marginRight: '3px' }} />
                                      {record.vehicle_name}
                                    </span>
                                    <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                                      - {record.vehicle_model} - {record.vehicle_type}
                                    </span>
                                  </div>
                                </div>
                            ) : (
                              <p style={{ fontSize: '14px', fontWeight: '400', marginTop: '0px', marginLeft: '2px' }}>No vehicle assigned.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    ),
                  }}
                />
              </div>
              <div style={{ marginTop: '40px' }}>
              <h2>Saved Dispatches</h2>
              <Table
                dataSource={filteredSavedDispatchObjects}
                columns={columns}
                rowKey="dispatch_id"
                pagination={false}
                scroll={{ y: 690 }}
                rowSelection={rowSelectionSaved} // Enable row selection for Saved Dispatches
                expandable={{
                  expandedRowRender: (record) => (
                    <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row', gap: '60px' }}>
                      {/* Staff Members Section */}
                      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '500px' }}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>Staff Members</span>
                        <Space.Compact style={{ width: '518px', marginBottom: '10px' }}>
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a staff member"
                            value={selectedStaffToAdd[record.dispatch_id] || null}
                            onChange={(value) => handleSelectStaffToAdd(record.dispatch_id, value)}
                            options={staff.map((staffMember) => ({
                              value: staffMember.staff_id,
                              label: `${staffMember.name} - ${staffMember.position}`,
                            }))}
                            filterOption={(inputValue, option) =>
                              option.label.toLowerCase().includes(inputValue.toLowerCase())
                            }
                          />
                          <Tooltip title="Add Staff">
                            <Button
                              style={{ boxShadow: 'none' }}
                              onClick={() => handleAddStaff(record.dispatch_id)}
                              disabled={!selectedStaffToAdd[record.dispatch_id]}
                            >
                              <IconPlus size={14} style={{ color: 'black', marginTop: '4px' }} />
                            </Button>
                          </Tooltip>
                        </Space.Compact>
                        <div style={{ maxHeight: '225px', overflow: 'hidden', width: '518px', overflowY: 'scroll' }}>
                          {record.staff_details && record.staff_details.length > 0 ? (
                            record.staff_details.map((staffMember) => (
                              <div
                                key={staffMember.staff_id}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginBottom: '8px',
                                  padding: '8px',
                                  border: '1px solid #f0f0f0',
                                  borderRadius: '4px',
                                  backgroundColor: 'white',
                                  width: '500px',
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                  <span style={{ fontWeight: '600', fontSize: '16px', marginRight: '4px' }}>
                                    <IconUser size={14} style={{ marginRight: '3px' }} />
                                    {staffMember.staff_name}
                                  </span>
                                  <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    - {staffMember.staff_position}
                                  </span>
                                  {staffMember.warning_status && (
                                    <>
                                      <span style={{ margin: '0 4px' }}> - </span>
                                      {staffMember.warning_status === 'Ready' && <Tag color="green">Ready</Tag>}
                                      {staffMember.warning_status === 'Warned' && (
                                        <>
                                          <Tag color="yellow">Warned</Tag>
                                          <Button
                                            onClick={() => resetStaffStatus(staffMember.staff_id)}
                                            type="Primary"
                                            style={{
                                              backgroundColor: '#c3c4c2',
                                              borderColor: '#c3c4c2',
                                              color: 'white',
                                              width: '55px',
                                              height: '25px',
                                              padding: 0,
                                              fontSize: '13px',
                                            }}
                                          >
                                            Reset
                                          </Button>
                                        </>
                                      )}
                                      {staffMember.warning_status === 'Dispatched' && (
                                        <>
                                          <Tag color="lightblue">Dispatched</Tag>
                                          <Button
                                            onClick={() => resetStaffStatus(staffMember.staff_id)}
                                            type="Primary"
                                            style={{
                                              backgroundColor: '#c3c4c2',
                                              borderColor: '#c3c4c2',
                                              color: 'white',
                                              width: '55px',
                                              height: '25px',
                                              padding: 0,
                                              fontSize: '13px',
                                            }}
                                          >
                                            Reset
                                          </Button>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                                <Tooltip title="Remove Staff">
                                  <IconX
                                    size={16}
                                    stroke={2}
                                    onClick={() => handleRemoveStaff(record.dispatch_id, staffMember.staff_id)}
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                  />
                                </Tooltip>
                              </div>
                            ))
                          ) : (
                            <p style={{ fontSize: '14px', fontWeight: '400', marginTop: '0px', marginLeft: '2px' }}>
                              No staff assigned.
                            </p>
                          )}
                        </div>
                      </div>
                
                      {/* Sites Section */}
                      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '500px' }}>
                        <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>Sites</span>
                        <Space.Compact style={{ width: '518px', marginBottom: '10px' }}>
                          <Select
                            showSearch
                            allowClear
                            placeholder="Select a site"
                            style={{ width: '100%' }}
                            value={selectedSiteToAdd[record.dispatch_id] || null}
                            onChange={(value) => handleSelectSiteToAdd(record.dispatch_id, value)}
                            options={sites.map((site) => ({
                              value: site.site_id,
                              label: `${site.client_name} - ${site.site_name}`,
                            }))}
                            filterOption={(inputValue, option) =>
                              option.label.toLowerCase().includes(inputValue.toLowerCase())
                            }
                          />
                          <Tooltip title="Add Site">
                            <Button
                              style={{ boxShadow: 'none' }}
                              onClick={() => handleAddSite(record.dispatch_id)}
                              disabled={!selectedSiteToAdd[record.dispatch_id]}
                            >
                              <IconPlus size={14} style={{ color: 'black', marginTop: '4px' }} />
                            </Button>
                          </Tooltip>
                        </Space.Compact>
                        {record.site_details && record.site_details.length > 0 ? (
                          record.site_details.map((site, index) => (
                            <div
                              key={site.site_id}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '8px',
                                padding: '8px',
                                border: '1px solid #f0f0f0',
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                width: '100%',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                {/* Order Number */}
                                <span style={{ fontWeight: '500', fontSize: '16px', marginRight: '8px' }}>
                                  {index + 1}.
                                </span>
                
                                {/* Client Name with Icon */}
                                <span style={{ fontWeight: '600', fontSize: '16px', marginRight: '4px' }}>
                                  <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                                  {site.client_name}
                                </span>
                
                                {/* Site Name */}
                                <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                  - {site.site_name}
                                </span>
                              </div>
                
                              {/* Remove Site Button */}
                              <Tooltip title="Remove Site">
                                <IconX
                                  size={16}
                                  stroke={2}
                                  onClick={() => handleRemoveSite(record.dispatch_id, site.site_id)}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Tooltip>
                            </div>
                          ))
                        ) : (
                          <p>No sites assigned.</p>
                        )}
                      </div>

                      {/* Vehicles Section */}
                      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '500px' }}>
                          <span style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>Vehicle</span>
                          <Space.Compact style={{ width: '518px', marginBottom: '10px' }}>
                            <Select
                              allowClear 
                              showSearch
                              style={{ width: '100%' }} 
                              placeholder='Select a Vehicle' 
                              value={selectedVehicleToSwap[record.dispatch_id] || null} 
                              onChange={(value) => handleSelectVehicleToSwap(record.dispatch_id, value)} 
                              options={vehicles.map((vehicle) => ({ value: vehicle.vehicle_id, label: `${vehicle.vehicle_name} - ${vehicle.vehicle_model} - ${vehicle.vehicle_type}`,}))}
                              filterOption={(inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase())}
                            />
                            <Tooltip title="Swap Vehicle">
                              <Button style={{ boxShadow: 'none'  }} onClick={() => handleSwapVehicle(record.dispatch_id)} disabled={!selectedVehicleToSwap[record.dispatch_id]}>
                                <IconPlus size={14} style={{ color: 'black', marginTop: '4px'}}/>
                              </Button>
                            </Tooltip>
                          </Space.Compact>
                          <div style={{ maxHeight: '225px', overflow: 'hidden', width: '518px', overflowY: 'scroll' }}>
                          {record.vehicle_name ? (
                                <div
                                  key={record.vehicle_name}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    padding: '8px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    backgroundColor: 'white',
                                    width: '500px'
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                    <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                                      <IconTractor size={14} style={{ marginRight: '3px' }} />
                                      {record.vehicle_name}
                                    </span>
                                    <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                                      - {record.vehicle_model} - {record.vehicle_type}
                                    </span>
                                  </div>
                                </div>
                            ) : (
                              <p style={{ fontSize: '14px', fontWeight: '400', marginTop: '0px', marginLeft: '2px' }}>No vehicle assigned.</p>
                            )}
                          </div>
                        </div>
                    </div>
                  ),
                }}
              />
            </div>
            </Spin>
          </div>
        </div>

        {/* Confirmation Modal */}
        <Modal
          title="Confirm Dispatch"
          open={showConfirmModal}
          onCancel={() => {
            setShowConfirmModal(false);
            setSelectedStorm(null);
            setSelectedDate(null);
            setSelectedTime(null);
            setDispatchMessage("");
            setSelectedDispatches([]);
          }}
          onOk={handleConfirmDispatch}
          okText="Confirm"
          cancelText="Cancel"
        >
          <p>Are you sure you want to dispatch the selected crew(s)?</p>
            {/* Toggle for sending messages */}
          <div style={{ marginBottom: 16 }}>
            <label style={{ display: "block", marginBottom: 8 }}>
              Send Whatsapp Messages:
            </label>
            <Radio.Group
              onChange={(e) => setSendMessages(e.target.value)}
              value={sendMessages}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>

          {/* Dropdown for selecting a storm */}
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="storm-select">Select Storm:</label>
            <Select
              id="storm-select"
              placeholder="Select a storm"
              style={{ width: "100%" }}
              value={selectedStorm}
              onChange={(value) => setSelectedStorm(value)}
              allowClear
            >
              {storms.map((storm) => (
                <Option key={storm.storm_id} value={storm.storm_id}>
                  {`${storm.type.charAt(0).toUpperCase() + storm.type.slice(1)} - ${storm.severity.charAt(0).toUpperCase() + storm.severity.slice(1)} (${new Date(storm.date_of_storm).toLocaleDateString()})`}
                </Option>
              ))}
            </Select>
          </div>

          {/* Date Picker for selecting the dispatch date */}
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="date-picker" style={{ display: "block", marginBottom: 8 }}>
              Select Dispatch Date:
            </label>
            <DatePicker
              id="date-picker"
              style={{ width: "100%" }}
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />
          </div>

          {/* Time Picker for selecting the dispatch time */}
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="time-picker" style={{ display: "block", marginBottom: 8 }}>
              Select Dispatch Time:
            </label>
            <TimePicker
              id="time-picker"
              style={{ width: "100%" }}
              value={selectedTime}
              onChange={(time) => setSelectedTime(time)}
              format="HH:mm" // Ensure the time is in 24-hour format
            />
          </div>
          <label style={{ display: "block", marginBottom: 8 }}>
            Optional Message:
          </label>
          <Input.TextArea
            placeholder="Optional message (max 500 characters)"
            maxLength={500}
            value={dispatchMessage}
            onChange={(e) => setDispatchMessage(e.target.value)}
            rows={4}
          />
          <label style={{ display: "block", marginBottom: 8 }}>
            Note: Custom Message will be condensed into one line
          </label>
        </Modal>


        {/* Warning Confirmation Modal */}
        <Modal
          title="Confirm Warning"
          open={showWarnModal}
          onCancel={() => {
            setShowWarnModal(false)
            setSelectedStorm(null)
            setWarnMessage("")
            setSelectedWarnDispatches([])
          }}
          onOk={handleConfirmWarn}
          okText="Confirm"
          cancelText="Cancel"
        >
          <p>Are you sure you want to warn the selected staff</p>

          {/* Dropdown for selecting a storm */}
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="storm-select">Select Storm:</label>
            <Select
              id="storm-select"
              placeholder="Select a storm"
              style={{ width: "100%" }}
              value={selectedStorm}
              onChange={(value) => setSelectedStorm(value)}
              allowClear
            >
              {storms.map((storm) => (
                <Option key={storm.storm_id} value={storm.storm_id}>
                  {`${storm.type.charAt(0).toUpperCase() + storm.type.slice(1)} - ${storm.severity.charAt(0).toUpperCase() + storm.severity.slice(1)} (${new Date(storm.date_of_storm).toLocaleDateString()})`}
                </Option>
              ))}
            </Select>
          </div>

          <label style={{ display: "block", marginBottom: 8 }}>
            Custom Message:
          </label>
          <Input.TextArea
            placeholder="Custom message (max 500 characters)"
            maxLength={500}
            value={warnMessage}
            onChange={(e) => setWarnMessage(e.target.value)}
          />
          <label style={{ display: "block", marginBottom: 8 }}>
            Note: Custom Message will be condensed into one line
          </label>
        </Modal>

        {/* Stand Down Confirmation Modal */}
        <Modal
          title="Confirm Stand Down Message"
          open={showStandDownModal}
          onCancel={() => {
            setShowStandDownModal(false)
            setStandDownMessage("")
          }}
          onOk={handleConfirmStandDown}
          okText="Confirm"
          cancelText="Cancel"
        >
          <p>Are you sure you want to tell the warned staff to stand down?</p>
          <label style={{ display: "block", marginBottom: 8 }}>
            Custom Message:
          </label>
          <Input.TextArea
            placeholder="Custom message (max 500 characters)"
            maxLength={500}
            value={standDownMessage}
            onChange={(e) => setStandDownMessage(e.target.value)}
          />
          <label style={{ display: "block", marginBottom: 8 }}>
            Note: Custom Message will be condensed into one line
          </label>
        </Modal>


        {/* Modal to Create a Storm */}
        <Modal
          title="Create Storm"
          visible={stormModal}
          onCancel={() => {
            setStormModal(false);
            setStormType("");
            setStormSeverity("");
            setDateOfStorm(null);
            setTimeOfStorm(null);
            setDateOfStormEnd(null);
            setTimeOfStormEnd(null);
            setIsEndDateManuallyChanged(false);
          }}
          onOk={handleStormOk}
          okText="Create"
          cancelText="Cancel"
        >
          <Form layout="vertical">
            {/* Storm Type */}
            <Form.Item label="Storm Type">
              <Select
                placeholder="Select storm type"
                value={stormType}
                onChange={(value) => setStormType(value)}
              >
                <Option value="snow">Snow</Option>
                <Option value="freezing_rain">Freezing Rain</Option>
                <Option value="salt">Salt</Option>
              </Select>
            </Form.Item>

            {/* Storm Severity */}
            <Form.Item label="Storm Severity">
              <Select
                placeholder="Select storm severity"
                value={stormSeverity}
                onChange={(value) => setStormSeverity(value)}
              >
                <Option value="light">Light</Option>
                <Option value="medium">Medium</Option>
                <Option value="heavy">Heavy</Option>
              </Select>
            </Form.Item>

            {/* Date of Storm */}
            <Form.Item label="Start of Storm Date">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select start date"
                value={dateOfStorm}
                onChange={(date) => {
                  setDateOfStorm(date);
                  // Auto-set end date if not manually changed
                  if (date && !isEndDateManuallyChanged) {
                    setDateOfStormEnd(date.add(1, "day"));
                  }
                }}
                format="YYYY-MM-DD"
              />
            </Form.Item>

            {/* Time of Storm */}
            <Form.Item label="Start of Storm Time">
              <TimePicker
                style={{ width: "100%" }}
                placeholder="Select start time"
                value={timeOfStorm}
                onChange={(time) => setTimeOfStorm(time)}
                format="HH:mm"
                use12Hours={false} // 24-hour format
              />
            </Form.Item>

            {/* End of Storm Date */}
            <Form.Item label="End of Storm Date">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select end date"
                value={dateOfStormEnd}
                onChange={(date) => {
                  setDateOfStormEnd(date);
                  setIsEndDateManuallyChanged(true);
                }}
                format="YYYY-MM-DD"
              />
            </Form.Item>

            {/* End of Storm Time */}
            <Form.Item label="End of Storm Time">
              <TimePicker
                style={{ width: "100%" }}
                placeholder="Select end time"
                value={timeOfStormEnd}
                onChange={(time) => setTimeOfStormEnd(time)}
                format="HH:mm"
                use12Hours={false} // 24-hour format
              />
            </Form.Item>
          </Form>
        </Modal>
      </DndProvider>
    </ConfigProvider>
  );
};

export default WalkwayCrewDispatchPage;
