import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
} from '@mui/material';
import api from '../authentication/axiosInstance';

const SiteContactsManager = ({ site, clientId, onContactsUpdate }) => {
  const [availableContacts, setAvailableContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  useEffect(() => {
    fetchAvailableContacts();
  }, [site.site_id]);

  const fetchAvailableContacts = async () => {
    try {
      // Get all contacts (already includes is_assigned) from /sites/<id>/contacts
      const { data: siteContacts } = await api.get(`/sites/${site.site_id}/contacts/`);
  
      // siteContacts is an array with { contact_id, is_assigned, ... }
  
      // For the list to render:
      setAvailableContacts(siteContacts);
  
      // For the checkboxes:
      setSelectedContacts(
        siteContacts
          .filter(contact => contact.is_assigned)
          .map(contact => contact.contact_id)
      );
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    }
  };
  

  const handleToggleContact = async (contactId) => {
    const newSelectedContacts = selectedContacts.includes(contactId)
      ? selectedContacts.filter(id => id !== contactId)
      : [...selectedContacts, contactId];
    
    setSelectedContacts(newSelectedContacts);
    
    try {
      await api.post(`/sites/${site.site_id}/contacts/`, {
        contact_ids: newSelectedContacts
      });
      if (onContactsUpdate) {
        onContactsUpdate();
      }
    } catch (error) {
      console.error('Failed to update site contacts:', error);
      // Revert the selection if the API call fails
      setSelectedContacts(selectedContacts);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Manage Contacts
      </Typography>
      <List>
      {availableContacts.map((contact) => (
        <ListItem key={contact.contact_id} dense>
            <Checkbox
            edge="start"
            checked={selectedContacts.includes(contact.contact_id)}
            onChange={() => handleToggleContact(contact.contact_id)}
            />
            <ListItemText
            primary={`${contact.first_name} ${contact.last_name}`}
            secondary={
                <>
                <Typography variant="body2" component="span">
                    Email: {contact.email}
                </Typography>
                <br />
                <Typography variant="body2" component="span">
                    Phone: {contact.phone}
                </Typography>
                </>
            }
            />
        </ListItem>
        ))}

      </List>
    </Box>
  );
};

export default SiteContactsManager; 