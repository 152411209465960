import React, { useState, useEffect } from 'react';
import { Table, Card, Row, Col, Statistic, Spin, message, Collapse, Typography, Space } from 'antd';
import { IconChartBar, IconCalendar } from '@tabler/icons-react';
import api from '../authentication/axiosInstance';
import moment from 'moment';
import styles from './StormStrategySurveys.module.css';

const { Panel } = Collapse;
const { Text } = Typography;

const StormStrategySurveys = () => {
    const [loading, setLoading] = useState(true);
    const [surveys, setSurveys] = useState([]);
    const [groupedSurveys, setGroupedSurveys] = useState({});
    const [strategies, setStrategies] = useState({});

    const fetchStrategies = async (strategyIds) => {
        try {
            const strategiesData = {};
            for (const id of strategyIds) {
                const response = await api.get(`/weatherworks/storm-strategies/${id}/`);
                if (response.data.success) {
                    strategiesData[id] = response.data.strategy;
                }
            }
            setStrategies(strategiesData);
        } catch (error) {
            console.error('Error fetching strategies:', error);
        }
    };

    const fetchSurveys = async () => {
        try {
            setLoading(true);
            const response = await api.get('/weatherworks/storm-strategy-surveys/');
            
            if (response.data.success) {
                const allSurveys = response.data.surveys;
                
                // Get unique strategy IDs
                const strategyIds = [...new Set(allSurveys.map(survey => survey.storm_strategy))];
                await fetchStrategies(strategyIds);
                
                // Group surveys by storm strategy ID
                const grouped = allSurveys.reduce((acc, survey) => {
                    const strategyId = survey.storm_strategy;
                    if (!acc[strategyId]) {
                        acc[strategyId] = {
                            region: survey.strategy_region,
                            responses: []
                        };
                    }
                    acc[strategyId].responses.push(survey);
                    return acc;
                }, {});

                setSurveys(allSurveys);
                setGroupedSurveys(grouped);
            } else {
                message.error('Failed to fetch survey responses');
            }
        } catch (error) {
            console.error('Error fetching surveys:', error);
            message.error('Failed to fetch survey responses');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSurveys();
    }, []);

    const columns = [
        {
            title: 'Client',
            dataIndex: 'client_name',
            key: 'client_name',
            width: 150,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 200,
        },
        {
            title: 'Timeliness',
            dataIndex: 'timeliness_rating',
            key: 'timeliness_rating',
            width: 100,
            render: (rating) => <span className={styles.rating}>{rating}/5</span>
        },
        {
            title: 'Performance',
            dataIndex: 'performance_rating',
            key: 'performance_rating',
            width: 100,
            render: (rating) => <span className={styles.rating}>{rating}/5</span>
        },
        {
            title: 'Communication',
            dataIndex: 'communication_rating',
            key: 'communication_rating',
            width: 120,
            render: (rating) => <span className={styles.rating}>{rating}/5</span>
        },
        {
            title: 'Professionalism',
            dataIndex: 'professionalism_rating',
            key: 'professionalism_rating',
            width: 120,
            render: (rating) => <span className={styles.rating}>{rating}/5</span>
        },
        {
            title: 'Overall',
            dataIndex: 'overall_rating',
            key: 'overall_rating',
            width: 100,
            render: (rating) => <span className={styles.rating}>{rating}/5</span>
        },
        {
            title: 'Feedback',
            dataIndex: 'feedback',
            key: 'feedback',
            width: 300,
        }
    ];

    const calculateStrategyAverages = (responses) => {
        if (!responses.length) return { timeliness: 0, performance: 0, communication: 0, professionalism: 0, overall: 0 };

        const sum = responses.reduce((acc, survey) => ({
            timeliness: acc.timeliness + survey.timeliness_rating,
            performance: acc.performance + survey.performance_rating,
            communication: acc.communication + survey.communication_rating,
            professionalism: acc.professionalism + survey.professionalism_rating,
            overall: acc.overall + survey.overall_rating
        }), { timeliness: 0, performance: 0, communication: 0, professionalism: 0, overall: 0 });

        const count = responses.length;
        return {
            timeliness: (sum.timeliness / count).toFixed(1),
            performance: (sum.performance / count).toFixed(1),
            communication: (sum.communication / count).toFixed(1),
            professionalism: (sum.professionalism / count).toFixed(1),
            overall: (sum.overall / count).toFixed(1)
        };
    };

    const calculateOverallAverages = () => {
        return calculateStrategyAverages(surveys);
    };

    const averages = calculateOverallAverages();

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>
                <IconChartBar size={24} />
                Storm Strategy Survey Responses
            </h1>

            <Row gutter={16} className={styles.statsContainer}>
                <Col span={4}>
                    <Card>
                        <Statistic title="Overall Timeliness" value={averages.timeliness} suffix="/5" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card>
                        <Statistic title="Overall Performance" value={averages.performance} suffix="/5" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card>
                        <Statistic title="Overall Communication" value={averages.communication} suffix="/5" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card>
                        <Statistic title="Overall Professionalism" value={averages.professionalism} suffix="/5" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card>
                        <Statistic title="Overall Rating" value={averages.overall} suffix="/5" />
                    </Card>
                </Col>
            </Row>

            <div className={styles.tableContainer}>
                <Spin spinning={loading}>
                    <Collapse 
                        defaultActiveKey={[]} 
                        className={styles.collapse}
                    >
                        {Object.entries(groupedSurveys)
                            .sort(([idA, a], [idB, b]) => {
                                const dateA = strategies[idA]?.created_at ? moment(strategies[idA].created_at) : moment(0);
                                const dateB = strategies[idB]?.created_at ? moment(strategies[idB].created_at) : moment(0);
                                return dateB.valueOf() - dateA.valueOf();
                            })
                            .map(([strategyId, data]) => {
                                const strategyAverages = calculateStrategyAverages(data.responses);
                                const strategyDate = strategies[strategyId]?.created_at;
                                
                                return (
                                    <Panel
                                        key={strategyId}
                                        header={
                                            <div className={styles.panelHeader}>
                                                <Space size="large">
                                                    <Text strong>Strategy #{strategyId}</Text>
                                                    <Text type="secondary">
                                                        <IconCalendar size={16} style={{ marginRight: 4 }} />
                                                        {strategyDate ? moment(strategyDate).format('MMMM D, YYYY') : 'Date not available'}
                                                    </Text>
                                                    <Text>{data.region}</Text>
                                                    <Text type="secondary">{data.responses.length} responses</Text>
                                                    <Text strong className={styles.rating}>
                                                        Overall: {strategyAverages.overall}/5
                                                    </Text>
                                                </Space>
                                            </div>
                                        }
                                    >
                                        <Row gutter={16} className={styles.strategyStats}>
                                            <Col span={4}>
                                                <Statistic title="Timeliness" value={strategyAverages.timeliness} suffix="/5" />
                                            </Col>
                                            <Col span={4}>
                                                <Statistic title="Performance" value={strategyAverages.performance} suffix="/5" />
                                            </Col>
                                            <Col span={4}>
                                                <Statistic title="Communication" value={strategyAverages.communication} suffix="/5" />
                                            </Col>
                                            <Col span={4}>
                                                <Statistic title="Professionalism" value={strategyAverages.professionalism} suffix="/5" />
                                            </Col>
                                        </Row>
                                        <Table
                                            columns={columns}
                                            dataSource={data.responses}
                                            rowKey="id"
                                            pagination={false}
                                            className={styles.table}
                                        />
                                    </Panel>
                                );
                            })}
                    </Collapse>
                </Spin>
            </div>
        </div>
    );
};

export default StormStrategySurveys; 