import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal, Popconfirm, Input, Select, Collapse, DatePicker, Space, Spin } from 'antd';
import { DeleteOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Box, MenuItem, FormControl, } from '@mui/material';
import { jsPDF } from 'jspdf';
import companyLogo from '../assets/company-logo.png';
import api from '../authentication/axiosInstance';
import debounce from 'lodash/debounce';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

const { Option } = Select;
const { Panel } = Collapse;

dayjs.extend(customParseFormat);

const EquipmentListView = () => {
  const [vehicles, setVehicles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const [searchName, setSearchName] = useState('');
  const [searchType, setSearchType] = useState(undefined);
  const [searchModel, setSearchModel] = useState('');
  const [searchMake, setSearchMake] = useState(undefined);
  const [searchYear, setSearchYear] = useState(undefined);
  const [searchSubtype, setSearchSubtype] = useState(undefined);
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs().startOf("year"));
  const [generatingTimesheet, setGeneratingTimesheet] = useState(false);

  const [generateModalVisible, setGenerateModalVisible] = useState(false);
  const [selectedVehicleForTimesheet, setSelectedVehicleForTimesheet] = useState(null);

  // States for editing provider on a vehicle
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedVehicleForEdit, setSelectedVehicleForEdit] = useState(null);
  const [editedProvider, setEditedProvider] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };


  const generateTimeSheet = async () => {
    if (!startDate || !endDate) {
      message.error("Please select both start and end dates");
      return;
    }
  
    if (endDate.isBefore(startDate)) {
      message.error("End date must be after start date");
      return;
    }
  
    const startTimestamp = startDate.unix();
    const endTimestamp = endDate.unix();
  
    try {
      setGeneratingTimesheet(true);
      const response = await api.post("/viaesys/fetch-vehicle-site-visits/", {
        startTimestamp,
        endTimestamp,
      }, { responseType: "blob" });
  
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "vehicle_site_visits.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      message.success("Vehicle site visits downloaded successfully.");
    } catch (error) {
      console.error("Failed to fetch vehicle site visits:", error);
      if (error.response?.data?.error) {
        message.error(`Error: ${error.response.data.error}`);
      } else {
        message.error("Failed to fetch and download vehicle site visits.");
      }
    } finally {
      setGeneratingTimesheet(false);
    }
  };
  
  const handleGenerateSingleTimesheet = async () => {
    if (!startDate || !endDate) {
      message.error("Please select both start and end dates");
      return;
    }
  
    if (endDate.isBefore(startDate)) {
      message.error("End date must be after start date");
      return;
    }
  
    const startTimestamp = startDate.unix();
    const endTimestamp = endDate.unix();
  
    try {
      message.loading({ content: "Generating timesheet...", key: "timesheet" });
  
      const response = await api.post(
        "/viaesys/fetch-vehicle-site-visits-single/",
        {
          vehicleName: selectedVehicleForTimesheet?.vehicle_name, // Pass vehicle name
          startTimestamp,
          endTimestamp,
        },
        { responseType: "blob" }
      );
  
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${selectedVehicleForTimesheet?.vehicle_name}_site_visits.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      message.success({ content: "Timesheet downloaded successfully!", key: "timesheet" });
    } catch (error) {
      console.error("Failed to generate timesheet:", error);
      message.error({ content: "Failed to generate timesheet!", key: "timesheet" });
    } finally {
      setGenerateModalVisible(false);
    }
  };

  const handleGenerateModalCancel = () => {
    setGenerateModalVisible(false);
    setSelectedVehicleForTimesheet(null);
  };
  
  const openGenerateTimesheetModal = (vehicle) => {
    setSelectedVehicleForTimesheet(vehicle);
    setGenerateModalVisible(true);
  };
  
  const fetchVehicles = async () => {
    try {
      const response = await api.get('/vehicles/');
      setVehicles(response.data);
      setFilteredVehicles(response.data);
    } catch (error) {
      console.error("Failed to fetch vehicles:", error);
      message.error("Failed to fetch vehicles");
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  const addGenericLoader = async () => {
    if (!selectedValue) {
      message.error("Please select a generic loader subtype before adding a generic loader.");
      return;
    }

    try {
      const response = await api.post('/vehicles/add_generic_loader/', {
        selected_value: selectedValue, // Include the selected dropdown value
      });
      message.success('Generic loader added successfully');
      fetchVehicles(); // Refresh the list
    } catch (error) {
      console.error("Failed to add generic loader:", error);
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
        message.error(`Failed to add generic loader: ${error.response.data.error || 'Unknown error'}`);
      } else if (error.request) {
        console.error("Error request:", error.request);
        message.error('Failed to add generic loader: No response received from server');
      } else {
        console.error('Error message:', error.message);
        message.error(`Failed to add generic loader: ${error.message}`);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await api.delete(`/vehicles/${id}/`)
      message.success('Vehicle deleted successfully');
      fetchVehicles();
    } catch (error) {
      console.error('Failed to delete vehicle:', error);
      message.error('Failed to delete vehicle')
    }
  };

  const generatePDF = (vehicle) => {
    if (!vehicle) {
      throw new Error('Error generating vehicle details pdf, no vehicle data provided');
    }

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const lineSpacing = 8;
    const indentSpacing = 10;
  
    doc.addImage(companyLogo, 'PNG', margin, margin, 66, 13);

    doc.setFontSize(16);
    const headerText = `${vehicle.vehicle_name} - Vehicle Details`;
    const headerTextWidth = doc.getTextWidth(headerText);
    const rightAlignedX = pageWidth - headerTextWidth - 10;
    doc.text(`${vehicle.vehicle_name} - Vehicle Details`, rightAlignedX, 17);

    doc.setFontSize(14);
    const xPos = 20;
    let yPos = 40;
    let columnSpacing = 70;

    const vehicleCost = vehicle.vehicle_cost
      ? Number(vehicle.vehicle_cost).toFixed(2)
      : '0.00';

    const details = [
      { label: 'Vehicle Name:', value: vehicle.vehicle_name },
      { label: 'Vehicle Type:', value: vehicle.vehicle_type },
      { label: 'Sub Type:', value: vehicle.vehicle_subtype },
      { label: 'Model:', value: vehicle.vehicle_model },
      { label: 'Make:', value: vehicle.vehicle_make },
      { label: 'Year:', value: vehicle.vehicle_year },
      { label: 'Serial Number:', value: vehicle.vehicle_serial_number },
      { label: 'Capacity:', value: vehicle.vehicle_capacity.toString() },
      { label: 'Remaining Capacity:', value: vehicle.remaining_capacity.toString() },
      { label: 'Vehicle Costs:', value: `$${vehicleCost}` },
      { label: 'Surface Type:', value: vehicle.surface_type },
      { label: 'Number of Staff Assigned:', value: vehicle.number_staff_assigned.toString() },
      { label: 'Status:', value: vehicle.status },
      { label: 'Plow Size:', value: vehicle.plow_size },
    ];

    details.forEach((detail) => {
      doc.text(detail.label, xPos, yPos);
      doc.text(detail.value || '', xPos + columnSpacing, yPos);
      yPos += lineSpacing;
    });

    if (vehicle.assigned_plow) {
      yPos += lineSpacing;
      doc.setFontSize(14);
      doc.text('Assigned Plow Details:', xPos, yPos);
      yPos += lineSpacing;
      doc.setFontSize(12);

      const plowDetails = [
        { label: 'Plow Name:', value: vehicle.assigned_plow.plow_name },
        { label: 'Plow Model:', value: vehicle.assigned_plow.plow_model }
      ];

      columnSpacing = 40;
      plowDetails.forEach((detail) => {
        doc.text(detail.label, xPos + indentSpacing, yPos);
        doc.text(detail.value || '', xPos + columnSpacing, yPos);
        yPos += lineSpacing;
      });
    }

    if (vehicle.allocated_sites && vehicle.allocated_sites.length > 0) {
      yPos += lineSpacing;
      doc.setFontSize(14);
      doc.text('Allocated Sites:', xPos, yPos);
      yPos += lineSpacing;
      doc.setFontSize(12);

      vehicle.allocated_sites.forEach((site) => {
        doc.text(`- ${site.site_name} (Client: ${site.client_name ? site.client_name : 'N/A'})`, xPos + indentSpacing, yPos);
        yPos += lineSpacing;
      });
    }

    doc.save(`${vehicle.vehicle_name}.pdf`);
  };
  
  const isAllocationPresent = (vehicles) => {
    return (vehicles.assigned_walkway_crew_bool || vehicles.assigned_salting_route_bool || vehicles.assigned_plow || vehicles.assigned_doubleDown || vehicles.assigned_plow_route_bool ||
      (vehicles.allocated_sites && vehicles.allocated_sites.length > 0) || (vehicles.number_staff_assigned > 0));
  }

  // ----------------------------
  // Handlers for Editing the Provider
  // ----------------------------
  const openEditModal = (vehicle) => {
    setSelectedVehicleForEdit(vehicle);
    setEditedProvider(vehicle.provider); // preload with the current provider
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedVehicleForEdit(null);
    setEditedProvider(null);
  };

  const handleEditModalSubmit = async () => {
    if (!editedProvider) {
      message.error("Please select a provider.");
      return;
    }
    try {
      await api.patch(`/vehicles/${selectedVehicleForEdit.vehicle_id}/patch_vehicle/`, {
        provider: editedProvider,
      });
      message.success("Vehicle provider updated successfully.");
      setIsEditModalVisible(false);
      fetchVehicles(); // Refresh the list
    } catch (error) {
      console.error("Failed to update vehicle:", error);
      message.error("Failed to update vehicle.");
    }
  };


  const columns = [
    {
      title: 'Vehicle Name',
      dataIndex: 'vehicle_name',
      key: 'vehicle_name',
    },
    {
      title: 'Type',
      dataIndex: 'vehicle_type',
      key: 'vehicle_type',
    },
    {
      title: 'Subtype',
      dataIndex: 'vehicle_subtype',
      key: 'vehicle_subtype',
    },
    {
      title: 'Plow Size',
      dataIndex: 'plow_size',
      key: 'plow_size',
    },
    {
      title: 'Model',
      dataIndex: 'vehicle_model',
      key: 'vehicle_model',
    },
    {
      title: 'Make',
      dataIndex: 'vehicle_make',
      key: 'vehicle_make',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 500,
      render: (record) => (
        <div className="action-buttons">
          <Button
            onClick={() => openGenerateTimesheetModal(record)}
            type="primary"
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginRight: 10 }}
          >
            Generate Timesheet
          </Button>
          <Button
            onClick={() => showVehicleDetails(record)}
            type="primary"
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginRight: 10 }}
          >
            View Details
          </Button>
          <Button
            onClick={() => openEditModal(record)}
            type="primary"
            icon={<EditOutlined />}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginRight: 10 }}
          >
            Edit
          </Button>
          {isAllocationPresent(record) ? (
            ""
          ) : (
            <Popconfirm
              title={`Are you sure you want to delete equipment ${record.vehicle_name}?`}
              onConfirm={() => handleDelete(record.vehicle_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                type="danger"
                className="delete-button"
              />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setShowModal(false);
    setSelectedVehicle(null);
  };

  const showVehicleDetails = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
  };

  //Filtering and searching
  const debouncedSearchName = debounce((value) => {
    handleSearchName(value);
  }, 300);

  const handleSearchName = (value) => {
    setSearchName(value);
  };


  const debouncedSearchModel = debounce((value) => {
    handleSearchModel(value);
  }, 300);

  const handleSearchModel = (value) => {
    setSearchModel(value);
  };
  
  const applyFilters = () => {
    const filtered = vehicles.filter((vehicle) => {
      const filterName = vehicle.vehicle_name.toLowerCase().includes(searchName.toLowerCase());
      const filterType = searchType ? vehicle.vehicle_type.toLowerCase().includes(searchType.toLowerCase()) : true;
      const filterModel = vehicle.vehicle_model.toLowerCase().includes(searchModel.toLowerCase());
      const filterMake = searchMake ? vehicle.vehicle_make.toLowerCase().includes(searchMake.toLowerCase()) : true;
      const filterYear = searchYear ? vehicle.vehicle_year.toString() === searchYear.toString() : true;
      const filterSubtype = searchSubtype ? vehicle.vehicle_subtype.toString() === searchSubtype.toString() : true;
      return filterName && filterType && filterModel && filterMake && filterYear && filterSubtype;
    });
    setFilteredVehicles(filtered);
  };
  
  useEffect(() => {
    applyFilters();
  }, [searchName, searchType, searchModel, searchMake, searchYear, searchSubtype]);


  return (
    <Box style={{ padding: 30 }}>
      <Modal
        visible={generatingTimesheet}
        title={null}
        footer={null}
        centered
        closable={false}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center', padding: 40 }}
      >
        <Spin size="large" style={{ marginBottom: 20 }} />
        <h2>Generating Vehicle Timesheet</h2>
        <p>Please wait while we create your csv file...</p>
      </Modal>
      {/* Modal for selecting date range and generating timesheet */}
      <Modal
        title={`Generate Timesheet for ${selectedVehicleForTimesheet?.vehicle_name}`}
        open={generateModalVisible}
        onCancel={handleGenerateModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button
            key="generate"
            type="primary"
            onClick={handleGenerateSingleTimesheet}
          >
            Generate Timesheet CSV
          </Button>,
        ]}
      >
        <Space direction="vertical" size="large">
          <div>
            <label style={{ paddingRight: 10 }}>Start Date:</label>
            <DatePicker
              showTime={{ format: "HH:mm", defaultValue: dayjs("00:00", "HH:mm") }}
              format="YYYY-MM-DD HH:mm"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              allowClear={false}
            />
          </div>
          <div>
            <label style={{ paddingRight: 10 }}>End Date:</label>
            <DatePicker
              showTime={{ format: "HH:mm", defaultValue: dayjs("00:00", "HH:mm") }}
              format="YYYY-MM-DD HH:mm"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              allowClear={false}
            />
          </div>
        </Space>
      </Modal>
      <h3 style={{fontWeight:"bold",}}>
        Vehicles:
      </h3>
      <Box style={{ marginBottom: 16, display: 'flex' }}>
        <div style={{display:"flex", justifyContent:'space-between', width:'100%'}}>
          <div>
            <Button onClick={addGenericLoader} type="primary">
              Add Generic Loader
            </Button>
            <Select
              value={selectedValue}
              allowClear
              onChange={(value) => setSelectedValue(value)}
              placeholder="Generic Loader Subtype"
              style={{ width: 250 , marginLeft: 10}}
            >
              <Option value="321">321</Option>
              <Option value="344">344</Option>
              <Option value="444">444</Option>
              <Option value="524">524</Option>
              <Option value="621">621</Option>
              <Option value="624">624</Option>
              <Option value="644">644</Option>
              <Option value="skidsteer">skidsteer</Option>
            </Select>
          </div>
          <div>
            <Input.Search
              placeholder="Search name"
              onSearch={setSearchName}
              onChange={(e) => debouncedSearchName(e.target.value)}
              style={{ width: 200, marginRight: 8 }}
              prefix={<SearchOutlined />}
            />
            <Select
              placeholder="Search by type"
              value={searchType}
              onChange={(value) => setSearchType(value)} // Updates searchType
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(vehicles.map((vehicle) => vehicle.vehicle_type))) // Extract unique types
                .map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
              ))}
            </Select>
            <Select
              placeholder="Search by subtype"
              value={searchSubtype}
              onChange={(value) => setSearchSubtype(value)} // Updates searchType
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(vehicles.map((vehicle) => vehicle.vehicle_subtype))) // Extract unique types
                .map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
              ))}
            </Select>
            <Input.Search
              placeholder="Search model"
              onSearch={setSearchModel}
              onChange={(e) => debouncedSearchModel(e.target.value)}
              style={{ width: 200, marginRight: 8 }}
              prefix={<SearchOutlined />}
            />
            <Select
              placeholder="Search by make"
              value={searchMake} // Dropdown value
              onChange={(value) => setSearchMake(value)} // Updates searchMake
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(vehicles.map((vehicle) => vehicle.vehicle_make))) // Extract unique makes
                .map((make) => (
                  <Option key={make} value={make}>
                    {make}
                  </Option>
              ))}
            </Select>
          </div>
        </div>
      </Box>
      <Table dataSource={filteredVehicles} columns={columns} rowKey="site_id" pagination={false} scroll={{ y: 640 }} />
      <Modal
        title="Vehicle Details"
        open={showModal}
        onCancel={handleModalCancel}
        footer={[
          <Button key="download" onClick={() => generatePDF(selectedVehicle)}>
              Download PDF
          </Button>,
        ]}
      >
        {selectedVehicle && (
          <div>
            <p>Name: {selectedVehicle.vehicle_name}</p>
            <p>Type: {selectedVehicle.vehicle_type}</p>
            <p>Sub Type: {selectedVehicle.vehicle_subtype}</p>
            <p>Capacity: {selectedVehicle.vehicle_capacity}</p>
            <p>Serial Number: {selectedVehicle.vehicle_serial_number}</p>
            <br />
            <p><b>Financial Details</b></p>
            <p>Vehicle Costs: ${selectedVehicle.vehicle_cost}</p>
          </div>
        )}
      </Modal>


            {/* Edit Vehicle Modal (to update provider) */}
            <Modal
        title="Edit Vehicle Provider"
        open={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="cancel" onClick={handleEditModalCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleEditModalSubmit}>
            Confirm
          </Button>,
        ]}
      >
        <div>
          <p>Please select the provider:</p>
          <Select
            value={editedProvider}
            onChange={(value) => setEditedProvider(value)}
            style={{ width: 200 }}
            placeholder="Select provider"
          >
            <Option value="Viaesys">Viaesys</Option>
            <Option value="Samsara">Samsara</Option>
            <Option value="Ignore">Ignore</Option>
            <Option value="n/a">n/a</Option>
          </Select>
        </div>
      </Modal>


      <h3 style={{fontWeight:"bold",}}>
        Vehicle Time Sheet:
      </h3>
      <Collapse>
        <Collapse.Panel header="Generate Viaesys Vehicles Time Sheet" key="1">
          <Space direction="vertical" size="large">
            <div>
              <label style={{paddingRight: 10}}>Start Date:</label>
              <DatePicker
                showTime={{
                  format: "HH:mm",
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                format="YYYY-MM-DD HH:mm"
                value={startDate}
                onChange={handleStartDateChange}
                allowClear={false}
              />
            </div>
            <div>
              <label style={{paddingRight: 10}}>End Date:</label>
              <DatePicker
                showTime={{
                  format: "HH:mm",
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                format="YYYY-MM-DD HH:mm"
                value={endDate}
                onChange={handleEndDateChange}
                allowClear={false}
              />
            </div>
            <Button type="primary" onClick={generateTimeSheet}>
              Generate Time Sheet CSV
            </Button>
          </Space>
        </Collapse.Panel>
      </Collapse>
    </Box>
  );
};

export default EquipmentListView;
