import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { Select, Input, Table, Tag, message, Typography as AntTypography, Modal, Input as AntInput } from 'antd';
import { IconCloudStorm, IconSend, IconX, IconTrash, IconWind, IconAlertTriangle, 
  IconDroplet, IconEye, IconSun, IconThermometer, IconGauge, IconCloud, IconMapPin, IconCalendar, IconPlus, IconFlare, IconUser, IconLogout, IconBell, IconChevronDown, IconUsers, IconSnowflake, IconChartBar, IconRobot, IconClock, IconInfoCircle, IconCloudRain } from '@tabler/icons-react';
import api from '../authentication/axiosInstance';
import styles from './StormStrategyPage.module.css';
import dayjs from 'dayjs';

const { TextArea } = Input;
const { Option } = Select;
const { Text, Paragraph } = AntTypography;
const { Search } = AntInput;

const MAX_MESSAGE_LENGTH = 1000;
const WIND_DIRECTIONS = {
  0: 'N', 45: 'NE', 90: 'E', 135: 'SE',
  180: 'S', 225: 'SW', 270: 'W', 315: 'NW', 360: 'N'
};

const inchesToCm = (inches) => inches * 2.54;
const fahrenheitToCelsius = (f) => Math.round((f - 32) * 5 / 9);

const StormStrategyPage = () => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [regions, setRegions] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [customMessage, setCustomMessage] = useState('');
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState({
    sites: false,
    weather: false,
    sending: false
  });
  const [searchText, setSearchText] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [selectedWeather, setSelectedWeather] = useState(null);
  const [showWeatherModal, setShowWeatherModal] = useState(false);
  const [isTestMode, setIsTestMode] = useState(false);
  const [events, setEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);
  const [generatingMessage, setGeneratingMessage] = useState(false);

  // Add keyboard shortcut handler
  useEffect(() => {
    const handleKeyPress = (event) => {
      // Ctrl + Alt + T to toggle test mode
      if (event.ctrlKey && event.altKey && event.key === 't') {
        setIsTestMode(prev => {
          const newMode = !prev;
          message.success(`Test mode ${newMode ? 'enabled' : 'disabled'}`, 1);
          return newMode;
        });
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // fetch regions on component mount
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await api.get('/sites/site_regions/');
        setRegions(response.data);
      } catch (error) {
        console.error('Error fetching regions:', error);
        message.error('Failed to fetch regions');
      }
    };
    fetchRegions();
  }, []);

  // fetch sites when region changes
  useEffect(() => {
    const fetchSites = async () => {
      if (!selectedRegion) {
        setSites([]);
        return;
      }
      
      setLoading(prev => ({ ...prev, sites: true }));
      try {
        const response = await api.get('/sites/', {
          params: { region: selectedRegion }
        });
        // Filter sites to only show those from the selected region
        const filteredSites = response.data.filter(site => site.region === selectedRegion);
        setSites(filteredSites);
      } catch (error) {
        console.error('Error fetching sites:', error);
        message.error('Failed to fetch sites');
      } finally {
        setLoading(prev => ({ ...prev, sites: false }));
      }
    };
    fetchSites();
  }, [selectedRegion]);

  // fetch weather data when region changes
  useEffect(() => {
    const fetchWeatherData = async () => {
      if (!selectedRegion) return;
      
      setLoading(prev => ({ ...prev, weather: true }));
      try {
        const response = await api.get(`/weatherworks/forecast/${selectedRegion}/`);
        if (response.data.success) {
          setWeatherData({
            ...response.data.forecast,
            location: response.data.location
          });
        } else {
          const fallbackResponse = await api.get('/weatherworks/forecast/Bolton/');
          if (fallbackResponse.data.success) {
            setWeatherData({
              ...fallbackResponse.data.forecast,
              location: fallbackResponse.data.location
            });
          } else {
            message.error('Failed to fetch weather data');
          }
        }
      } catch (error) {
        try {
          const fallbackResponse = await api.get('/weatherworks/forecast/Bolton/');
          if (fallbackResponse.data.success) {
            setWeatherData({
              ...fallbackResponse.data.forecast,
              location: fallbackResponse.data.location
            });
          } else {
            message.error('Failed to fetch weather data');
          }
        } catch (fallbackError) {
          console.error('Error fetching weather data:', error);
          message.error('Failed to fetch weather data');
        }
      } finally {
        setLoading(prev => ({ ...prev, weather: false }));
      }
    };
    fetchWeatherData();
  }, [selectedRegion]);

  // Add new useEffect for fetching events
  useEffect(() => {
    const fetchEvents = async () => {
      if (!selectedRegion) {
        setEvents([]);
        return;
      }

      setLoadingEvents(true);
      
      try {
        const response = await api.get(`/weatherworks/fetch-events-by-region/${encodeURIComponent(selectedRegion)}/`);
        
        if (response.data.success) {
          const eventData = response.data.events || [];
          setEvents(eventData);
        } else {
          console.error('Failed to fetch events:', response.data.message);
          setEvents([]);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        message.error('Failed to fetch weather events');
        setEvents([]);
      } finally {
        setLoadingEvents(false);
      }
    };

    fetchEvents();
  }, [selectedRegion]);

  const handleSiteSelect = async (site) => {
    try {
      // Fetch only associated contacts for the site
      const response = await api.get(`/sites/${site.site_id}/associated_contacts/`);
      // If no contacts and test mode is off, add default contact
      if (response.data.length === 0 && !isTestMode) {
        site.contacts = [{
          first_name: 'Default',
          last_name: 'Contact',
          email: 'mmicic@consalt.ca'
        }];
      } else {
        site.contacts = response.data;
      }
      setSelectedSites(prev => [...prev, site]);
    } catch (error) {
      console.error('Error fetching site contacts:', error);
      message.error('Failed to fetch site contacts');
      // Add default contact on error
      setSelectedSites(prev => [...prev, {
        ...site,
        contacts: [{
          first_name: 'Default',
          last_name: 'Contact',
          email: 'mmicic@consalt.ca'
        }]
      }]);
    }
  };

  const handleSiteRemove = (siteId) => {
    setSelectedSites(prev => prev.filter(site => site.site_id !== siteId));
  };

  const handleSubmit = async () => {
    if (!selectedRegion || selectedSites.length === 0 || !customMessage.trim()) {
      message.error('Please fill in all required fields');
      return;
    }

    setLoading(prev => ({ ...prev, sending: true }));
    try {
      await api.post('/storm-strategy/', {
        region: selectedRegion,
        sites: selectedSites.map(site => site.id),
        message: customMessage,
        weather_data: weatherData
      });
      message.success('Storm strategy sent successfully');
      // reset form
      setSelectedSites([]);
      setCustomMessage('');
    } catch (error) {
      console.error('Error sending storm strategy:', error);
      message.error('Failed to send storm strategy');
    } finally {
      setLoading(prev => ({ ...prev, sending: false }));
    }
  };

  const getWindDirection = (degrees) => {
    const directions = Object.entries(WIND_DIRECTIONS);
    return directions.reduce((closest, [deg, dir]) => {
      return Math.abs(deg - degrees) < Math.abs(closest[0] - degrees) ? [deg, dir] : closest;
    })[1];
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const clearSelectedSites = () => {
    Modal.confirm({
      title: 'Clear Selected Sites',
      content: 'Are you sure you want to remove all selected sites?',
      onOk: () => setSelectedSites([]),
      okText: 'Yes, clear all',
      cancelText: 'No, keep them'
    });
  };

  const handleSubmitConfirm = () => {
    setShowConfirmModal(true);
  };

  const handleSubmitFinal = async () => {
    setShowConfirmModal(false);
    setLoading(prev => ({ ...prev, sending: true }));
    try {
      // Map sites to ensure each has at least one contact
      const sitesWithContacts = selectedSites.map(site => ({
        ...site,
        contacts: isTestMode ? [{
          first_name: 'Test',
          last_name: 'Mode',
          email: 'mmicic@consalt.ca'
        }] : (site.contacts?.length ? site.contacts : [{
          first_name: 'Default',
          last_name: 'Contact',
          email: 'mmicic@consalt.ca'
        }])
      }));

      await api.post('/weatherworks/storm-strategy/', {
        region: selectedRegion,
        sites: sitesWithContacts,
        message: customMessage,
        weather_data: weatherData,
        test_mode: isTestMode
      });
      message.success('Storm strategy sent successfully to all selected sites');
      setSelectedSites([]);
      setCustomMessage('');
    } catch (error) {
      console.error('Error sending storm strategy:', error);
      message.error(
        'Failed to send storm strategy. Please try again or contact support if the problem persists.'
      );
    } finally {
      setLoading(prev => ({ ...prev, sending: false }));
    }
  };

  const retryWeatherFetch = () => {
    setRetryCount(prev => prev + 1);
  };

  const columns = [
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      key: 'site_name',
      sorter: (a, b) => a.site_name.localeCompare(b.site_name),
      render: (text) => <Text strong>{text}</Text>,
      filteredValue: [searchText],
      onFilter: (value, record) => 
        record.site_name.toLowerCase().includes(value.toLowerCase())
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          size="small"
          onClick={() => handleSiteSelect(record)}
          disabled={selectedSites.some(site => site.site_id === record.site_id)}
          className={styles.actionButton}
        >
          Select
        </Button>
      )
    }
  ];

  const handleWeatherClick = (weather, isCurrentWeather = false) => {
    const weatherDetails = isCurrentWeather ? {
      ...weather,
      time: 'Current',
      isCurrentWeather: true
    } : weather;
    setSelectedWeather(weatherDetails);
    setShowWeatherModal(true);
  };

  const renderWeatherModal = () => {
    if (!selectedWeather) return null;

    const isExtremeWeather = 
      selectedWeather.precipitation?.probability > 70 ||
      selectedWeather.wind?.speed > 50;

    return (
      <Modal
        title={`Weather Details - ${selectedWeather.isCurrentWeather ? 'Current' : selectedWeather.time}`}
        open={showWeatherModal}
        onCancel={() => setShowWeatherModal(false)}
        footer={null}
        width={700}
      >
        <div className={styles.weatherModalContent}>
          <div className={styles.weatherModalMain}>
            <div className={styles.weatherModalTemp}>
              <div>
                <span className={styles.tempValue}>{selectedWeather.temperature}°</span>
                <span className={styles.tempUnit}>C</span>
              </div>
              <Text type="secondary" style={{ fontSize: '14px' }}>
                Feels like {selectedWeather.feelslike}°C
              </Text>
            </div>
            <div className={styles.weatherModalConditions}>
              <Text strong style={{ fontSize: '18px' }}>{selectedWeather.conditions}</Text>
              {isExtremeWeather && (
                <IconAlertTriangle size={20} className={styles.warningIcon} />
              )}
            </div>
          </div>

          <div className={styles.weatherModalDetails}>
            {/* Precipitation Details */}
            <div className={styles.weatherModalDetail}>
              <div className={styles.detailHeader}>
                <IconDroplet size={18} />
                <Text type="secondary">Precipitation</Text>
              </div>
              <div className={styles.detailContent}>
                <div>
                  <Text strong>
                    {selectedWeather.precipitation?.probability}% chance of {selectedWeather.precipitation?.type || 'precipitation'}
                  </Text>
                  {selectedWeather.precipitation?.intensity > 0 && (
                    <Text>Intensity: {selectedWeather.precipitation.intensity} mm/hr</Text>
                  )}
                  {selectedWeather.precipitation?.snow > 0 && (
                    <Text>Snow: {selectedWeather.precipitation.snow} cm</Text>
                  )}
                  {selectedWeather.precipitation?.snowdepth > 0 && (
                    <Text>Snow Depth: {selectedWeather.precipitation.snowdepth} cm</Text>
                  )}
                </div>
              </div>
            </div>

            {/* wind details */}
            <div className={styles.weatherModalDetail}>
              <div className={styles.detailHeader}>
                <IconWind size={18} />
                <Text type="secondary">Wind</Text>
              </div>
              <div className={styles.detailContent}>
                <div>
                  <Text strong>{selectedWeather.wind?.speed} km/h</Text>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <IconWind 
                      size={16} 
                      style={{ transform: `rotate(${selectedWeather.wind?.direction}deg)` }} 
                    />
                    <Text>{getWindDirection(selectedWeather.wind?.direction)}</Text>
                  </div>
                  {selectedWeather.wind?.gust > 0 && (
                    <Text>Gusts up to {selectedWeather.wind.gust} km/h</Text>
                  )}
                </div>
              </div>
            </div>

            {/* visibility and uv */}
            <div className={styles.weatherModalDetail}>
              <div className={styles.detailHeader}>
                <IconEye size={18} />
                <Text type="secondary">Visibility & UV</Text>
              </div>
              <div className={styles.detailContent}>
                <div>
                  <Text>Visibility: {selectedWeather.visibility} km</Text>
                  <Text>UV Index: {selectedWeather.uvindex}</Text>
                </div>
              </div>
            </div>

            {/* additional details */}
            <div className={styles.weatherModalDetail}>
              <div className={styles.detailHeader}>
                <IconThermometer size={18} />
                <Text type="secondary">Additional Details</Text>
              </div>
              <div className={styles.detailContent}>
                <div className={styles.additionalDetails}>
                  <div>
                    <Text type="secondary">Humidity</Text>
                    <Text strong>{selectedWeather.humidity}%</Text>
                  </div>
                  <div>
                    <Text type="secondary">Pressure</Text>
                    <Text strong>{selectedWeather.pressure} mb</Text>
                  </div>
                  <div>
                    <Text type="secondary">Cloud Cover</Text>
                    <Text strong>{selectedWeather.cloudcover}%</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderCurrentWeather = () => {
    if (!weatherData?.current) return null;
    
    const isExtremeWeather = 
      weatherData.current.precipitation.probability > 70 ||
      weatherData.current.wind.speed > 50;
    
    return (
      <div 
        className={`${styles.currentWeather} ${styles.clickable}`}
        onClick={() => handleWeatherClick(weatherData.current, true)}
      >
        <div className={styles.timeDisplay}>
          <Typography variant="body2">
            Current as of {dayjs().tz('America/Toronto').format('h:mm A')}
          </Typography>
        </div>
        <div className={styles.mainWeather}>
          <div className={styles.temperature}>
            <span className={styles.tempValue}>{weatherData.current.temperature}°</span>
            <span className={styles.tempUnit}>C</span>
          </div>
          <div className={styles.conditions}>
            <Typography variant="h6">
              {weatherData.current.conditions}
              {isExtremeWeather && (
                <IconAlertTriangle size={20} className={styles.warningIcon} />
              )}
            </Typography>
            <Typography variant="body2">
              {weatherData.current.precipitation.probability}% chance of {weatherData.current.precipitation.type || 'precipitation'}
            </Typography>
          </div>
        </div>
        <div className={styles.weatherDetails}>
          <div className={styles.detailItem}>
            <Typography variant="body2">Wind Speed</Typography>
            <Typography variant="body1">{weatherData.current.wind.speed} km/h</Typography>
          </div>
          <div className={styles.detailItem}>
            <Typography variant="body2">Wind Direction</Typography>
            <div className={styles.windDirection}>
              <IconWind 
                size={20} 
                style={{ 
                  transform: `rotate(${weatherData.current.wind.direction}deg)`,
                  marginRight: '8px'
                }} 
              />
              <Typography variant="body1">
                {getWindDirection(weatherData.current.wind.direction)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHourlyForecast = () => {
    if (!weatherData?.hourly?.length) return null;

    // get current hour in est
    const currentTime = dayjs().tz('America/Toronto');
    const currentHour = currentTime.hour();
    const nextHour = currentHour + 1;  // start from next hour

    // filter and get next 8 hours starting from next hour
    const nextHours = weatherData.hourly
      .filter(hour => {
        const hourTime = parseInt(hour.time.split(':')[0]);
        return hourTime >= nextHour;  // compare with next hour instead of current
      })
      .slice(0, 8);

    return (
      <div className={styles.hourlyForecast}>
        <Typography variant="subtitle1" className={styles.forecastTitle}>
          8-Hour Forecast (Starting Next Hour)
        </Typography>
        <div className={styles.hourlyGrid}>
          {nextHours.map((hour, index) => {
            const hourTime = dayjs()
              .tz('America/Toronto')
              .hour(parseInt(hour.time.split(':')[0]));
            
            return (
              <div 
                key={index} 
                className={`${styles.hourlyItem} ${styles.clickable}`}
                onClick={() => handleWeatherClick({ ...hour, time: hourTime.format('h A') })}
              >
                <Typography variant="body2" className={styles.hourTime}>
                  {hourTime.format('h A')}
                </Typography>
                <Typography variant="h6" className={styles.hourTemp}>
                  {hour.temperature}°
                </Typography>
                <Typography variant="body2" className={styles.hourlyCondition}>
                  {hour.conditions}
                </Typography>
                {hour.precipitation?.probability > 0 && (
                  <Typography variant="caption" className={styles.precipInfo}>
                    Chance of {hour.precipitation.type || 'precipitation'}: {hour.precipitation.probability}%
                  </Typography>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const selectAllSites = () => {
    // filter out sites that are already selected
    const newSites = sites.filter(
      site => !selectedSites.some(selected => selected.site_id === site.site_id)
    );
    setSelectedSites(prev => [...prev, ...newSites]);
  };

  const generateAIMessage = async () => {
    if (!selectedRegion) {
      message.error('Please select a region first');
      return;
    }

    setGeneratingMessage(true);
    try {
      // send events data instead of threats
      const currentEvents = loadingEvents ? [] : events;
      
      const response = await api.post('/weatherworks/generate-message/', {
        region: selectedRegion,
        weather_data: weatherData,
        events: currentEvents
      });

      if (response.data.success) {
        setCustomMessage(response.data.message);
        message.success('Message generated successfully');
      } else {
        message.error('Failed to generate message');
      }
    } catch (error) {
      console.error('Error generating message:', error);
      message.error('Failed to generate message');
    } finally {
      setGeneratingMessage(false);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setShowEventModal(true);
  };

  const renderEventModal = () => {
    if (!selectedEvent) return null;

    const startDate = dayjs(selectedEvent.startDate);
    const endDate = dayjs(selectedEvent.endDate);
    const duration = endDate.diff(startDate, 'hours', true);
    const isOngoing = selectedEvent.ongoing;

    const minTempC = selectedEvent.minTempF ? fahrenheitToCelsius(selectedEvent.minTempF) : null;
    const maxTempC = selectedEvent.maxTempF ? fahrenheitToCelsius(selectedEvent.maxTempF) : null;

    // Get weather icon based on primary weather
    const getWeatherIcon = (weatherPrimary) => {
      const type = weatherPrimary?.toLowerCase() || '';
      if (type.includes('snow')) return <IconSnowflake size={24} />;
      if (type.includes('rain')) return <IconCloudRain size={24} />;
      if (type.includes('ice') || type.includes('freez')) return <IconThermometer size={24} style={{ transform: 'rotate(45deg)' }} />;
      if (type.includes('mix')) return <IconCloudStorm size={24} />;
      return <IconCloud size={24} />;
    };

    return (
      <Modal
        title={
          <div className={styles.modalConfirmHeader}>
            {getWeatherIcon(selectedEvent.weatherPrimary)}
            <Typography variant="h6">
              {selectedEvent.weatherPrimary || 'Weather Event'}
            </Typography>
          </div>
        }
        open={showEventModal}
        onCancel={() => setShowEventModal(false)}
        footer={null}
        width={900}
        className={styles.confirmModal}
      >
        <div className={styles.confirmContent}>
          {selectedEvent.weather && (
            <div className={styles.threatAlert}>
              <IconAlertTriangle size={24} style={{ color: '#ff4d4f' }} />
              <Text style={{ color: '#ff4d4f', fontSize: '16px' }}>
                {selectedEvent.weather}
              </Text>
            </div>
          )}

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px', marginTop: '24px' }}>
            <div>
              <div className={styles.threatModalSection}>
                <div className={styles.threatModalSectionHeader}>
                  <IconCalendar size={18} />
                  <Text strong>Time Period</Text>
                </div>
                <div className={styles.threatModalSectionContent}>
                  <div className={styles.threatTimeDetail}>
                    <Text type="secondary">Start:</Text>
                    <Text strong>{selectedEvent.formattedStartDate}</Text>
                  </div>
                  <div className={styles.threatTimeDetail}>
                    <Text type="secondary">End:</Text>
                    <Text strong>{selectedEvent.formattedEndDate}</Text>
                  </div>
                  <div className={styles.threatTimeDetail}>
                    <Text type="secondary">Duration:</Text>
                    <Text strong>{Math.round(duration * 10) / 10} hours</Text>
                  </div>
                </div>
              </div>

              <div className={styles.threatModalSection} style={{ marginTop: '16px' }}>
                <div className={styles.threatModalSectionHeader}>
                  <IconMapPin size={18} />
                  <Text strong>Location</Text>
                </div>
                <div className={styles.threatModalSectionContent}>
                  <Text>{selectedEvent.siteAddress}</Text>
                </div>
              </div>
            </div>

            <div>
              <div className={styles.threatModalSection}>
                <div className={styles.threatModalSectionHeader}>
                  <IconThermometer size={18} />
                  <Text strong>Weather Details</Text>
                </div>
                <div className={styles.threatModalSectionContent}>
                  {selectedEvent.maxTempF && selectedEvent.minTempF && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Temperature:</Text>
                      <Text strong>{selectedEvent.minTempC}°C - {selectedEvent.maxTempC}°C</Text>
                    </div>
                  )}
                  {selectedEvent.totalSnowCM > 0 && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Snow Accumulation:</Text>
                      <Text strong>{selectedEvent.totalSnowCM.toFixed(1)} cm</Text>
                    </div>
                  )}
                  {selectedEvent.snowTrace && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Snow:</Text>
                      <Tag>Trace Amounts</Tag>
                    </div>
                  )}
                  {selectedEvent.totalIceaccumMM > 0 && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Ice Accumulation:</Text>
                      <Text strong>{selectedEvent.totalIceaccumMM.toFixed(1)} mm</Text>
                    </div>
                  )}
                  {selectedEvent.iceCoating && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Ice:</Text>
                      <Tag>Ice Coating</Tag>
                    </div>
                  )}
                  {selectedEvent.iceTrace && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Ice:</Text>
                      <Tag>Trace Amounts</Tag>
                    </div>
                  )}
                  {selectedEvent.maxPop > 0 && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Probability:</Text>
                      <Text strong>{selectedEvent.maxPop}%</Text>
                    </div>
                  )}
                  {selectedEvent.hasGap && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Pattern:</Text>
                      <Tag color="warning">Intermittent</Tag>
                    </div>
                  )}
                  {selectedEvent.longDuration && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Duration Type:</Text>
                      <Tag color="warning">Extended Event</Tag>
                    </div>
                  )}
                  {selectedEvent.sustainedSnowfallPeriods?.length > 0 && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Snowfall Pattern:</Text>
                      <Tag color="processing">Sustained Periods</Tag>
                    </div>
                  )}
                  {selectedEvent.minDuration !== selectedEvent.maxDuration && selectedEvent.minDuration && selectedEvent.maxDuration && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Duration Range:</Text>
                      <Text strong>{selectedEvent.minDuration}-{selectedEvent.maxDuration} hours</Text>
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.threatModalSection} style={{ marginTop: '16px' }}>
                <div className={styles.threatModalSectionHeader}>
                  <IconWind size={18} />
                  <Text strong>Conditions</Text>
                </div>
                <div className={styles.threatModalSectionContent}>
                  {selectedEvent.blowingSnowIntensity && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Blowing Snow:</Text>
                      <Tag color={selectedEvent.blowingSnowIntensity === 'h' ? 'error' : 'warning'}>
                        {selectedEvent.blowingSnowIntensity === 'h' ? 'High' : 'Moderate'}
                      </Tag>
                    </div>
                  )}
                  {selectedEvent.windChillIntensity && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Wind Chill:</Text>
                      <Tag color={selectedEvent.windChillIntensity === 'h' ? 'error' : 'warning'}>
                        {selectedEvent.windChillIntensity === 'h' ? 'High' : 'Moderate'}
                      </Tag>
                    </div>
                  )}
                  {selectedEvent.rapidSnowAccumulationIntensity && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Snow Rate:</Text>
                      <Tag color={selectedEvent.rapidSnowAccumulationIntensity === 'h' ? 'error' : 'warning'}>
                        Rapid Accumulation
                      </Tag>
                    </div>
                  )}
                  {selectedEvent.heavySleetIntensity && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Sleet:</Text>
                      <Tag color={selectedEvent.heavySleetIntensity === 'h' ? 'error' : 'warning'}>
                        {selectedEvent.heavySleetIntensity === 'h' ? 'Heavy' : 'Moderate'}
                      </Tag>
                    </div>
                  )}
                  {selectedEvent.hardFreezePotentialIntensity && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Hard Freeze Risk:</Text>
                      <Tag color={selectedEvent.hardFreezePotentialIntensity === 'h' ? 'error' : 'warning'}>
                        {selectedEvent.hardFreezePotentialIntensity === 'h' ? 'High' : 'Moderate'}
                      </Tag>
                    </div>
                  )}
                  {selectedEvent.heavySleetCount > 0 && (
                    <div className={styles.threatDetail}>
                      <Text type="secondary">Sleet Periods:</Text>
                      <Text strong>{selectedEvent.heavySleetCount}</Text>
                    </div>
                  )}
                </div>
              </div>

              {selectedEvent.threats?.length > 0 && (
                <div className={styles.threatModalSection} style={{ marginTop: '16px' }}>
                  <div className={styles.threatModalSectionHeader}>
                    <IconAlertTriangle size={18} />
                    <Text strong>Additional Threats</Text>
                  </div>
                  <div className={styles.threatModalSectionContent}>
                    {selectedEvent.threats.map((threat, index) => (
                      <div key={index} className={styles.threatDetail}>
                        <Text type="secondary">{threat.type}:</Text>
                        <Tag color="error">{threat.intensity}</Tag>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderEvents = () => {
    if (loadingEvents) {
      return (
        <div className={styles.loadingState}>
          <IconCloudStorm size={24} className={styles.loadingIcon} />
          <Typography>Loading events data...</Typography>
        </div>
      );
    }

    if (events.length === 0) {
      return (
        <div className={styles.emptyState}>
          <IconCloudStorm size={24} />
          <Typography>No weather events for this region</Typography>
        </div>
      );
    }

    return (
      <div className={styles.threatsGrid}>
        {events.map((event) => (
          <div 
            key={event.id} 
            className={`${styles.threatCard} ${styles.clickable}`}
            onClick={() => handleEventClick(event)}
          >
            <div className={styles.threatHeader}>
              <span className={styles.threatType}>{event.weatherPrimary}</span>
              <span className={styles.threatDuration}>{event.hours} hours</span>
            </div>
            <div className={styles.threatDetails}>
              <div className={styles.threatTime}>
                <IconCalendar size={16} className={styles.threatIcon} />
                <div className={styles.threatTimeRange}>
                  <div>Start: {event.formattedStartDate}</div>
                  <div>End: {event.formattedEndDate}</div>
                </div>
              </div>
              <div className={styles.threatLocation}>
                <IconMapPin size={16} className={styles.threatIcon} />
                <span>{event.siteAddress}</span>
              </div>
              {event.ongoing && (
                <Tag color="error" style={{ alignSelf: 'flex-start' }}>
                  Ongoing
                </Tag>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h5" className={styles.title}>Storm Strategy</Typography>
        <Typography variant="subtitle1" className={styles.subtitle}>
          Create and send storm strategies to your sites
        </Typography>
      </div>

      <div className={styles.content}>
        <div className={styles.leftPanel}>
          {/* Weather Section */}
          <Box className={styles.weatherSection}>
            <div className={styles.sectionHeader}>
              <Typography variant="h6">Weather Conditions</Typography>
              <Select
                placeholder="Select Region"
                style={{ width: '50%' }}
                onChange={value => setSelectedRegion(value)}
                loading={loading.weather}
              >
                {regions.map(region => (
                  <Option key={region} value={region}>{region}</Option>
                ))}
              </Select>
            </div>
            <div className={styles.weatherContent}>
              {loading.weather ? (
                <div className={styles.weatherLoading}>
                  <IconCloudStorm size={24} className={styles.loadingIcon} />
                  <Typography>Loading weather data...</Typography>
                </div>
              ) : weatherData ? (
                <>
                  {renderCurrentWeather()}
                  {renderHourlyForecast()}
                </>
              ) : (
                <div className={styles.emptyState}>
                  <IconCloudStorm size={24} />
                  <Typography>Select a region to view weather data</Typography>
                </div>
              )}
            </div>
          </Box>

          {/* selected sites section */}
          <Box className={styles.selectedSitesSection}>
            <div className={styles.sectionHeader}>
              <div className={styles.headerLeft}>
                <Typography variant="h6">Selected Sites</Typography>
                <Typography variant="caption" color="textSecondary">
                  {selectedSites.length} sites selected
                </Typography>
              </div>
              {selectedSites.length > 0 && (
                <Button
                  size="small"
                  onClick={clearSelectedSites}
                  startIcon={<IconTrash size={16} />}
                  className={styles.clearButton}
                >
                  Clear All
                </Button>
              )}
            </div>
            <div className={styles.selectedSites}>
              {selectedSites.map(site => (
                <Tag
                  key={site.site_id}
                  closable
                  onClose={() => handleSiteRemove(site.site_id)}
                  className={styles.siteTag}
                >
                  {site.site_name}
                </Tag>
              ))}
              {selectedSites.length === 0 && (
                <Typography variant="body2" color="textSecondary" className={styles.noSites}>
                  No sites selected
                </Typography>
              )}
            </div>
          </Box>

          {/* message section */}
          <Box className={styles.messageSection}>
            <div className={styles.sectionHeader}>
              <div className={styles.headerLeft}>
                <Typography variant="h6">Message</Typography>
                <Typography variant="caption" color="textSecondary">
                  {customMessage.length}/{MAX_MESSAGE_LENGTH} characters
                </Typography>
              </div>
              <Button
                size="small"
                onClick={generateAIMessage}
                startIcon={<IconRobot size={16} />}
                className={`${styles.generateButton} ${generatingMessage ? styles.loading : ''}`}
                disabled={generatingMessage || !selectedRegion}
                loading={generatingMessage}
              >
                Generate with AI
              </Button>
            </div>
            <TextArea
              placeholder="Enter your storm strategy message..."
              value={customMessage}
              onChange={e => {
                if (e.target.value.length <= MAX_MESSAGE_LENGTH) {
                  setCustomMessage(e.target.value);
                }
              }}
              rows={4}
              className={styles.messageInput}
              showCount
              maxLength={MAX_MESSAGE_LENGTH}
            />
            <Paragraph type="secondary" className={styles.messageGuideline}>
              Include important details such as expected weather conditions, 
              required preparations, and specific instructions for site management.
            </Paragraph>
            <div className={styles.messageActions}>
              <Button
                type="primary"
                size="large"
                onClick={handleSubmitConfirm}
                disabled={!selectedRegion || selectedSites.length === 0 || !customMessage.trim() || loading.sending}
                loading={loading.sending}
                endIcon={<IconSend size={20} />}
                className={styles.sendButton}
              >
                Send Storm Strategy
              </Button>
            </div>
          </Box>
        </div>

        {/* right panel */}
        <div className={styles.rightPanel}>
          <Box className={styles.sitesSection}>
            <div className={styles.sectionHeader}>
              <div className={styles.headerLeft}>
                <Typography variant="h6">Available Sites</Typography>
              </div>
              <div className={styles.headerControls}>
                <Button
                  size="small"
                  marginRight="10px"
                  onClick={selectAllSites}
                  startIcon={<IconPlus size={16} />}
                  className={styles.selectButton}
                  disabled={sites.length === selectedSites.length}
                >
                  Select All
                </Button>
                <Search
                  placeholder="Search sites..."
                  onSearch={handleSearch}
                  className={styles.siteSearch}
                  allowClear
                />
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={sites}
              loading={loading.sites}
              rowKey="site_id"
              pagination={{ pageSize: 10 }}
              className={styles.sitesTable}
              scroll={{ y: 'calc(100vh - 350px)' }}
            />
          </Box>

          {/* events section */}
          <Box className={styles.eventsSection}>
            <div className={styles.sectionHeader}>
              <Typography variant="h6">Incoming Storms</Typography>
            </div>
            <div className={styles.eventsContent}>
              {renderEvents()}
            </div>
          </Box>
        </div>
      </div>

      {/* confirmation modal */}
      <Modal
        title={
          <div className={styles.modalConfirmHeader}>
            <IconCloudStorm size={24} />
            <Typography variant="h6">Confirm Storm Strategy</Typography>
          </div>
        }
        open={showConfirmModal}
        onOk={handleSubmitFinal}
        onCancel={() => setShowConfirmModal(false)}
        okText="Yes, send strategy"
        cancelText="No, review again"
        className={styles.confirmModal}
        width={600}
      >
        <div className={styles.confirmContent}>
          <div className={styles.confirmMessage}>
            <IconAlertTriangle size={20} className={styles.confirmIcon} />
            <Typography>
              Are you sure you want to send this storm strategy to {selectedSites.length} sites?
              {isTestMode && (
                <Text type="warning" style={{ display: 'block', marginTop: 8 }}>
                  Test Mode: All emails will be sent to mmicic@consalt.ca
                </Text>
              )}
            </Typography>
          </div>

          <div className={styles.confirmDetails}>
            <div className={styles.confirmSection}>
              <Text strong>Region</Text>
              <div className={styles.confirmValue}>{selectedRegion}</div>
            </div>

            <div className={styles.confirmSection}>
              <Text strong>Selected Sites and Contacts</Text>
              <div className={styles.confirmSites}>
                {selectedSites.map(site => (
                  <div key={site.site_id} className={styles.confirmSiteDetails}>
                    <Tag className={styles.confirmSiteTag}>
                      {site.site_name}
                    </Tag>
                    <div className={styles.confirmContacts}>
                      {isTestMode ? (
                        <div className={styles.confirmContact}>
                          <IconUser size={14} />
                          <span>Test Mode</span>
                          <span className={styles.confirmEmail}>mmicic@consalt.ca</span>
                        </div>
                      ) : site.contacts?.length > 0 ? (
                        site.contacts.map((contact, idx) => (
                          <div key={idx} className={styles.confirmContact}>
                            <IconUser size={14} />
                            {contact.first_name === 'Default' ? (
                              <span style={{ color: '#d48806' }}>Fallback Contact</span>
                            ) : (
                              <span>{contact.first_name} {contact.last_name}</span>
                            )}
                            <span className={styles.confirmEmail}>{contact.email}</span>
                          </div>
                        ))
                      ) : (
                        <div className={styles.confirmContact}>
                          <IconUser size={14} />
                          <span style={{ color: '#d48806' }}>Fallback Contact</span>
                          <span className={styles.confirmEmail}>mmicic@consalt.ca</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.confirmSection}>
              <Text strong>Message</Text>
              <div className={styles.confirmMessageBox}>
                {customMessage}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {renderWeatherModal()}
      {renderEventModal()}
    </div>
  );
};

export default StormStrategyPage; 