// StormsListView.js
import React, { useState, useEffect } from 'react';
import { Table, message, Input, Select, Form, Button, Modal, DatePicker, Collapse, List, Spin, Checkbox, Popconfirm} from 'antd';
import { Box } from '@mui/material';
import api from "../authentication/axiosInstance";
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const { Option } = Select;

const StormsListView = () => {
  const [storms, setStorms] = useState([]);

  const [searchStormType, setSearchStormType] = useState(undefined);
  const [searchStormSeverity, setSearchStormSeverity] = useState(undefined);
  const [filteredStorms, setFilteredStorms] = useState([]);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingStorm, setEditingStorm] = useState(null);
  const [editForm] = Form.useForm();

  const [threats, setThreats] = useState([]);
  const [selectedThreats, setSelectedThreats] = useState([]);
  const [loadingThreats, setLoadingThreats] = useState(false);
  const [importingThreats, setImportingThreats] = useState(false);

  const [isViewDetailsModalVisible, setIsViewDetailsModalVisible] = useState(false);
  const [selectedStorm, setSelectedStorm] = useState(null);
  const [selectedStorms, setSelectedStorms] = useState([]);
  const [year, setYear] = useState(null);
  const [fetchedStorms, setFetchedStorms] = useState(null);
  const [loadingStorms, setLoadingStorms] = useState(false);
  const [searchCity, setSearchCity] = useState("");

  const fetchStorms = async () => {
    try {
      const response = await api.get('/dispatch/storm/');
      setStorms(response.data);
      setFilteredStorms(response.data);
    } catch (error) {
      console.error('Error fetching storms:', error);
      message.error('Failed to fetch storms');
    }
  }

  useEffect(() => {
    fetchStorms();
  },[])

  const capitalize = (text) => {
    if (!text) return "N/A"; // Return "N/A" if text is null or undefined
    return text
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const showEditModal = (storm) => {
    setEditingStorm(storm);
  
    editForm.setFieldsValue({
      type: storm.type,
      severity: storm.severity,
      date_of_storm: storm.date_of_storm
        ? dayjs(storm.date_of_storm).tz('America/New_York') // Convert to New York timezone
        : null,
      date_of_storm_end: storm.date_of_storm_end
        ? dayjs(storm.date_of_storm_end).tz('America/New_York') // Convert to New York timezone
        : null,
    });
  
    setIsEditModalVisible(true);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setEditingStorm(null);
    editForm.resetFields();
  };

  const handleEditSubmit = async (values) => {
    // Validation for start and end dates
    const startDateTime = values.date_of_storm;
    const endDateTime = values.date_of_storm_end;
  
    if (!startDateTime || !endDateTime) {
      message.error("Please provide both start and end date/time.");
      return;
    }
  
    if (dayjs(endDateTime).isBefore(dayjs(startDateTime))) {
      message.error("The end date/time cannot be before the start date/time.");
      return;
    }
  
    try {
      const updatedStorm = {
        ...editingStorm,
        ...values,
        date_of_storm: startDateTime
          ? startDateTime.tz('UTC').format('YYYY-MM-DDTHH:mm:ss[Z]')
          : null,
        date_of_storm_end: endDateTime
          ? endDateTime.tz('UTC').format('YYYY-MM-DDTHH:mm:ss[Z]')
          : null,
      };
  
      // Submit updated storm to the API
      await api.patch(`/dispatch/storm/${editingStorm.storm_id}/`, updatedStorm);
      message.success("Storm updated successfully");
      setIsEditModalVisible(false);
      fetchStorms(); // Refresh the storm list
    } catch (error) {
      console.error("Failed to update storm:", error);
      message.error("Failed to update storm");
    }
  };

  // Fetch sites from the WeatherWorks API
  const fetchThreats = async () => {
    setLoadingThreats(true);
    try {
      const response = await api.get('/weatherworks/fetch-threats/');
      setThreats(response.data.threat_id_map || []);
      message.success('Threats fetched successfully!');
    } catch (error) {
      console.error('Error fetching threats:', error);
      message.error('Failed to fetch threats.');
    } finally {
      setLoadingThreats(false);
    }
  };

  const importThreats = async () => {
    setImportingThreats(true);
    try {
      const response = await api.post('/weatherworks/import-threats-to-storms/', {
        threats: selectedThreats,
      });
      message.success(response.data.message);
      message.warning({
        content: "Please make sure to edit imported storms' severities.",
        duration: 7,
      });
      setSelectedThreats([]);
      fetchStorms();
    } catch (error) {
      console.error('Error importing threats:', error);
      message.error('Failed to import threats.');
    } finally {
      setImportingThreats(false);
    }
  };

  const handleThreatSelection = (threatId) => {
    setSelectedThreats((prevSelected) =>
      prevSelected.includes(threatId)
        ? prevSelected.filter((id) => id !== threatId)
        : [...prevSelected, threatId]
    );
  };

  const showViewDetailsModal = (storm) => {
    setSelectedStorm(storm);
    setIsViewDetailsModalVisible(true);
  };

  const handleViewDetailsCancel = () => {
    setIsViewDetailsModalVisible(false);
    setSelectedStorm(null);
    setSelectedStorms([]);
  };

  const fetchWeatherWorksStorms = async () => {
    if (!year) {
      message.error("Please select a year.");
      return;
    }

    setLoadingStorms(true);
    try {
      const response = await api.post("/weatherworks/fetch-storms-for-year/", { year });
      setFetchedStorms(response.data.storms);
      message.success("Storms fetched successfully!");
    } catch (error) {
      console.error("Error fetching storms:", error);
      message.error("Failed to fetch storms.");
    } finally {
      setLoadingStorms(false);
    }
  };

  const renderStorms = () => {
    if (!fetchedStorms) return null;
  
    const stormItems = [];
  
    Object.entries(fetchedStorms).forEach(([locationId, data]) => {
      const { location, storms } = data;
      storms.forEach((storm) => {
        const stormKey = storm.storm_location_id;
        stormItems.push({
          key: stormKey,
          city: location.city,
          start: dayjs(storm.start).tz("America/New_York").format("YYYY-MM-DD hh:mm A z"),
          end: dayjs(storm.end).tz("America/New_York").format("YYYY-MM-DD hh:mm A z"),
        });
      });
    });
  
    const filteredStormItems = stormItems.filter((item) =>
      item.city.toLowerCase().includes(searchCity.toLowerCase())
    );
  
    return (
      <List
        bordered
        dataSource={filteredStormItems}
        renderItem={(item) => (
          <List.Item>
            <Checkbox
              checked={selectedStorms.includes(item.key)}
              onChange={() => handleStormSelection(item.key)}
              style={{ width: "100%" }}
            >
              <div style={{ display: "flex", flexDirection: "column", lineHeight: "1.5" }}>
                <span>
                  City: {item.city}
                </span>
                <span>
                  Start: {item.start}
                </span>
                <span>
                  End: {item.end}
                </span>
              </div>
            </Checkbox>
          </List.Item>
        )}
        style={{ maxHeight: 400, overflowY: "auto" }}
      />
    );
  };

  const handleStormSelection = (stormId) => {
    setSelectedStorms((prevSelected) =>
      prevSelected.includes(stormId)
        ? prevSelected.filter((id) => id !== stormId)
        : [...prevSelected, stormId]
    );
  };

  const handleConfirmSelection = async () => {
    if (selectedStorms.length !== 1) {
      message.error("Please select exactly one storm.");
      return;
    }
  
    const weatherworksId = selectedStorms[0]; // ID of the fetched WeatherWorks storm
    const stormId = selectedStorm?.storm_id; // ID of the currently selected storm in the modal
  
    if (!stormId) {
      message.error("No storm selected.");
      return;
    }
  
    if (!year) {
      message.error("Please select a year.");
      return;
    }
  
    // Locate the selected storm details
    const selectedStormDetails = Object.entries(fetchedStorms).find(([locationId, data]) =>
      data.storms.some(storm => storm.storm_location_id === weatherworksId)
    );
  
    if (!selectedStormDetails) {
      message.error("Storm details could not be found.");
      return;
    }
  
    const { location, storms } = selectedStormDetails[1];
  
    // Special handling for city and year
    const stormCity = location.city; // Extract city from location
    const stormYear = year; // Use the year selected by the user in the UI
  
    // Locate the specific storm details
    const stormDetails = storms.find(storm => storm.storm_location_id === weatherworksId);
  
    try {
      const response = await api.post(`/weatherworks/storm/${stormId}/update-storm-weatherworks-id/`, {
        weatherworks_id: weatherworksId,
        storm_year: stormYear,
        storm_city: stormCity,
        storm_start_weatherworks: stormDetails.start,
        storm_end_weatherworks: stormDetails.end,
        storm_precip_type_weatherworks: stormDetails.precip_type,
        storm_snow_amount_weatherworks: stormDetails.snow_amount,
        storm_snow_trace_weatherworks: stormDetails.snow_trace,
        storm_freezing_rain_amount_weatherworks: stormDetails.freezing_rain_amount,
        storm_freezing_rain_trace_weatherworks: stormDetails.freezing_rain_trace,
      });
  
      if (response.data.success) {
        message.success("Storm updated successfully.");
        setIsViewDetailsModalVisible(false);
        fetchStorms();
        setSelectedStorms([]);
      } else {
        message.error(response.data.message || "Failed to update storm.");
      }
    } catch (error) {
      console.error("Error updating storm:", error);
      message.error("Failed to update storm.");
    }
  };

  const handleUnmatchStorm = async () => {
    try {
      const response = await api.post(`/weatherworks/storm/${selectedStorm.storm_id}/unmatch/`);
      if (response.data.success) {
        message.success("Storm unmatched successfully.");
        setIsViewDetailsModalVisible(false);
        fetchStorms(); // Refresh the storm list
      } else {
        message.error(response.data.message || "Failed to unmatch storm.");
      }
    } catch (error) {
      console.error("Error unmatching storm:", error);
      message.error("Failed to unmatch storm.");
    }
  };

  const columns = [
    {
      title: 'Storm Type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => capitalize(text),
    },
    {
      title: 'Storm Severity',
      dataIndex: 'severity',
      key: 'severity',
      render: (text) => {
        if (text === "N/A") {
          return (
            <>
              N/A
              <span style={{ fontWeight: 'bold', color: 'red' }}>*</span>
            </>
          );
        }
        return capitalize(text);
      },
      sorter: (a, b, sortOrder) => {
        const ascendingOrder = ["N/A", "light", "medium", "heavy"];
        const descendingOrder = ["N/A", "heavy", "medium", "light"];
    
        const sortIndexA = (sortOrder === "ascend" ? ascendingOrder : descendingOrder).indexOf(a.severity);
        const sortIndexB = (sortOrder === "ascend" ? ascendingOrder : descendingOrder).indexOf(b.severity);
    
        return sortIndexA - sortIndexB;
      },
    },
    {
      title: 'Date of Storm (YYYY/MM/DD)',
      dataIndex: 'date_of_storm',
      key: 'date_of_storm',
      render: (utcDate) => {
        return utcDate
          ? dayjs(utcDate).tz('America/New_York').format('YYYY-MM-DD hh:mm A z')
          : 'N/A';
      },
    },
    {
      title: 'Date of Storm End(YYYY/MM/DD)',
      dataIndex: 'date_of_storm_end',
      key: 'date_of_storm_end',
      render: (utcDate) => {
        return utcDate
          ? dayjs(utcDate).tz('America/New_York').format('YYYY-MM-DD hh:mm A z')
          : 'N/A';
      },
    },
    {
      title: 'Date Created (YYYY/MM/DD)',
      dataIndex: 'date_created',
      key: 'date_created',
      render: (utcDate) => {
        return utcDate
          ? dayjs(utcDate).tz('America/New_York').format('YYYY-MM-DD hh:mm A z')
          : 'N/A';
      },
    },
    {
      title: 'Matched WeatherWorks Storm',
      key: 'matched',
      render: (storm) => (
        <Checkbox checked={storm.storm_location_id_weatherworks !== null} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (storm) => (
        <div>
          <Button
            type="primary"
            onClick={() => showEditModal(storm)}
            icon={<EditOutlined />}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
          >
          Edit
          </Button>
          <Button
            type="primary"
            onClick={() => showViewDetailsModal(storm)}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginLeft:10 }}
          >
            View Details
          </Button>
        </div>
      ),
    },
  ];


  const applyFilters = () => {
    const filtered = storms.filter((storm) => {
      const filterStormType = searchStormType ? storm.type === searchStormType : true;
      const filterStormSeverity = searchStormSeverity ? storm.severity === searchStormSeverity : true;
      return filterStormType && filterStormSeverity;
    });
    setFilteredStorms(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [searchStormType, searchStormSeverity]);

  
  return (
    <div style={{display:'flex', flexDirection:'column'}}>
    {/* View Details Modal */}
      <Modal
        title="View Storm Details"
        visible={isViewDetailsModalVisible}
        onCancel={handleViewDetailsCancel}
        footer={null}
      >
        {selectedStorm?.storm_location_id_weatherworks ? (
          <div style={{ padding: "10px" }}>
            <h3 style={{}}>Storm details from matched WeatherWorks storm</h3>
            <div style={{ marginTop: "10px" }}>
              <p><strong>Location ID:</strong> {selectedStorm.storm_location_id_weatherworks || "N/A"}</p>
              <p><strong>Season:</strong> {selectedStorm.storm_year_weatherworks || "N/A"}</p>
              <p><strong>City:</strong> {selectedStorm.storm_city_weatherworks || "N/A"}</p>
              <p><strong>Start Time:</strong> {selectedStorm.storm_start_weatherworks ? dayjs(selectedStorm.storm_start_weatherworks).tz("America/New_York").format("YYYY-MM-DD hh:mm A z") : "N/A"}</p>
              <p><strong>End Time:</strong> {selectedStorm.storm_end_weatherworks ? dayjs(selectedStorm.storm_end_weatherworks).tz("America/New_York").format("YYYY-MM-DD hh:mm A z") : "N/A"}</p>
              <p><strong>Precipitation Type:</strong> {selectedStorm.storm_precip_type_weatherworks || "N/A"}</p>
              <p><strong>Snow Amount:</strong> {selectedStorm.storm_snow_amount_weatherworks !== null && selectedStorm.storm_snow_amount_weatherworks !== undefined ? selectedStorm.storm_snow_amount_weatherworks : "N/A"}</p>
              <p><strong>Snow Trace:</strong> {selectedStorm.storm_snow_trace_weatherworks || "N/A"}</p>
              <p><strong>Freezing Rain Amount:</strong> {selectedStorm.storm_freezing_rain_amount_weatherworks !== null && selectedStorm.storm_freezing_rain_amount_weatherworks !== undefined ? selectedStorm.storm_freezing_rain_amount_weatherworks : "N/A"}</p>
              <p><strong>Freezing Rain Trace:</strong> {selectedStorm.storm_freezing_rain_trace_weatherworks || "N/A"}</p>
            </div>
            <Popconfirm
              title="Are you sure you want to unmatch this storm?"
              onConfirm={handleUnmatchStorm}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                style={{ marginTop: 20, marginLeft: "auto" }}
              >
                Unmatch Storm
              </Button>
            </Popconfirm>
          </div>
          
        ) : (
          <>
            <div style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
              Please choose the season you wish to begin the range from. All storms will be from the selected year to the next.
            </div>

            {/* Year selection dropdown */}
            <Select
              placeholder="Select Year"
              onChange={(value) => setYear(value)}
              style={{ width: "100%", marginBottom: 20 }}
            >
              {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((y) => (
                <Select.Option key={y} value={y}>
                  {y}
                </Select.Option>
              ))}
            </Select>

            {/* Fetch Storms Button */}
            <Button
              type="primary"
              onClick={fetchWeatherWorksStorms}
              loading={loadingStorms}
              style={{ marginBottom: 20, backgroundColor: "#cdaf5e", borderColor: "#cdaf5e" }}
            >
              Fetch Storms - WeatherWorks
            </Button>

            {/* Conditionally render City Search Filter */}
            {fetchedStorms && (
              <Input
                placeholder="Search by city"
                allowClear
                value={searchCity}
                onChange={(e) => setSearchCity(e.target.value)}
                style={{ marginBottom: 20 }}
              />
            )}

            {/* Storms List */}
            {loadingStorms ? '' : renderStorms()}

            {/* Confirm Selection Button */}
            <Button
              type="primary"
              disabled={selectedStorms.length !== 1}
              style={{ marginTop: 20, backgroundColor: "#cdaf5e", borderColor: "#cdaf5e", marginLeft:10 }}
              onClick={handleConfirmSelection}
            >
              Confirm Selection
            </Button>
          </>
        )}
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <div style={{marginLeft:30}}>
            <h2>Storms</h2>
        </div>
        <div style = {{marginRight:30}}>
            <Select
                placeholder="Search storm type"
                value={searchStormType}
                onChange={(value) => setSearchStormType(value)}
                style={{ width: 200, marginRight: 8 }}
                allowClear
                >
                {Array.from(new Set(storms.map((storm) => storm.type)))
                    .map((type) => (
                    <Option key={type} value={type}>
                        {capitalize(type)}
                    </Option>
                ))}
                </Select>
                <Select
                placeholder="Search storm severity"
                value={searchStormSeverity}
                onChange={(value) => setSearchStormSeverity(value)}
                style={{ width: 200, marginRight: 8 }}
                allowClear
                >
                {Array.from(new Set(storms.map((storm) => storm.severity)))
                    .map((severity) => (
                    <Option key={severity} value={severity}>
                        {capitalize(severity)}
                    </Option>
                ))}
                </Select>
        </div>
      </div>
      <div style={{ marginLeft: 30 }}>
        <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
          <span style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>*</span>
          Please edit the severity of any N/A severity storms.
        </span>
      </div>
      <Box style={{ padding: '10px 30px 30px 30px' }}>
        <Table dataSource={filteredStorms} columns={columns} rowKey="" pagination={false} scroll={{ y:640 }}/>
      </Box>
      {/* Edit Storm Modal */}
      <Modal
        title="Edit Storm"
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        footer={null}
      >
        <Form form={editForm} onFinish={handleEditSubmit} layout="vertical">
          <Form.Item
            name="type"
            label="Storm Type"
            rules={[{ required: false, message: 'Please enter the storm type' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="severity"
            label="Storm Severity"
            rules={[{ required: false, message: 'Please enter the storm severity' }]}
          >
            <Select placeholder="Select severity">
              <Option value="light">Light</Option>
              <Option value="medium">Medium</Option>
              <Option value="heavy">Heavy</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="date_of_storm"
            label="Date and Time of Storm"
            rules={[{ required: true, message: 'Please enter the date and time of the storm' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              showTime={{
                format: "HH:mm",
                defaultValue: dayjs("00:00", "HH:mm"), // Default time is 00:00
              }}
              format="YYYY-MM-DD HH:mm" // Display format for both date and time
              allowClear={false} // Disable clearing to ensure a value is always present
            />
          </Form.Item>
          <Form.Item
            name="date_of_storm_end"
            label="Date and Time of Storm End"
            rules={[{ required: true, message: 'Please enter the end date and time of the storm' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              showTime={{
                format: "HH:mm",
                defaultValue: dayjs("00:00", "HH:mm"), // Default time is 00:00
              }}
              format="YYYY-MM-DD HH:mm" // Display format for both date and time
              allowClear={false} // Disable clearing to ensure a value is always present
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Storm
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Threats Section */}
      <div style={{marginLeft: 30, marginRight: 30}}>
        <h3 style={{ paddingTop: 10, paddingBottom: 10 }}>Threats to Storms:</h3>
        <Collapse>
          <Collapse.Panel header="Select Threats to Import as Storms" key="1">
            <Button
              onClick={fetchThreats}
              type="primary"
              style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginBottom: 20 }}
            >
              {loadingThreats ? <Spin /> : 'Fetch Threats'}
            </Button>
            <List
              bordered
              dataSource={Object.entries(threats)}
              renderItem={([threatId, threatData]) => (
                <List.Item>
                  <Checkbox
                    checked={selectedThreats.includes(threatId)}
                    onChange={() => handleThreatSelection(threatId)}
                  >
                    {`${threatData.weatherPrimary} - ${threatData.siteAddress} - Start: ${dayjs(threatData.startDate).tz('America/New_York').format('YYYY-MM-DD hh:mm A z')} - End: ${dayjs(threatData.endDate).tz('America/New_York').format('YYYY-MM-DD hh:mm A z')}`}
                  </Checkbox>
                </List.Item>
              )}
              style={{ maxHeight: 400, overflowY: 'auto' }}
            />
            <Button
              type="primary"
              onClick={importThreats}
              disabled={selectedThreats.length === 0}
              style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginTop: 20 }}
            >
              {importingThreats ? <Spin /> : 'Import Selected Threats as Storms'}
            </Button>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default StormsListView;