import React, { useState } from 'react';
import { Modal, Form, Select, DatePicker, Button, Spin, List, message } from 'antd';
import dayjs from 'dayjs';
import api from "../authentication/axiosInstance";

const SyncJobsModal = ({ open, onClose, storms }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const handleStormSelect = (value) => {
    const selectedStorm = storms.find((storm) => storm.storm_id === value);
    if (selectedStorm) {
      const updatedFields = {};
      if (selectedStorm.date_of_storm) {
        updatedFields.start_time = dayjs(selectedStorm.date_of_storm);
      }
      if (selectedStorm.date_of_storm_end) {
        updatedFields.end_time = dayjs(selectedStorm.date_of_storm_end);
      }
      form.setFieldsValue(updatedFields);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setResult(null);

    try {
      const payload = {
        start_time: values.start_time.toISOString(),
        end_time: values.end_time.toISOString(),
        storm_id: values.storm_id || null,
      };

      const response = await api.post('/debrief/jobs/sync/', payload);
      setResult(response.data);
    } catch (error) {
      message.error("Failed to sync jobs.");
      console.error("Sync error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    form.resetFields();
    setResult(null);
    onClose();
  };

  return (
    <Modal
      title="Sync Jobs"
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin />
          <p>Syncing jobs...</p>
        </div>
      ) : result ? (
        <div>
          <h3>Sync Summary</h3>
          <h4>Jobs Created - {result.created_jobs ? result.created_jobs.length : "0"}</h4>
          {result.created_jobs?.length > 0 ? (
            <List
              size="small"
              bordered
              dataSource={result.created_jobs}
              renderItem={(job) => (
                <List.Item>
                  <strong>Job ID:</strong> {job.job_id}
                  <br/>
                  <strong>Site:</strong> {job.site_name}
                  <br/>
                  <strong>Vehicle:</strong> {job.vehicle_name}
                  <br/>
                  <strong>Start Time:</strong> {job.job_start_date}
                </List.Item>
              )}
            />
          ) : (
            <p>No jobs created.</p>
          )}
          <h4 style={{ marginTop: 16 }}>Jobs Skipped - {result.skipped_jobs ? result.skipped_jobs.length : "0"}</h4>
          {result.skipped_jobs?.length > 0 ? (
            <List
              size="small"
              bordered
              dataSource={result.skipped_jobs}
              renderItem={(job) => (
                <List.Item>
                  <strong>Site:</strong> {job.site_name}
                  <br/>
                  <strong>Vehicle:</strong> {job.vehicle_name}
                  <br/>
                  <strong>Reason:</strong> {job.reason}
                </List.Item>
              )}
            />
          ) : (
            <p>No jobs skipped.</p>
          )}
          <div style={{ textAlign: 'right', marginBottom: 0, marginTop: 16 }}>
            <Button onClick={onClose} type="primary" style={{ backgroundColor: '#cdaf5e' }}>
                Close
            </Button>
          </div>
        </div>
      ) : (
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="storm_id" label="Select Storm">
            <Select
              placeholder="Select a storm (optional)"
              allowClear
              onChange={handleStormSelect}
            >
              {storms.map((storm) => (
                <Select.Option key={storm.storm_id} value={storm.storm_id}>
                  {storm.type} - {dayjs(storm.date_of_storm).format('YYYY-MM-DD')}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="start_time"
            label="Start Time"
            rules={[{ required: true, message: "Please select a start time" }]}
          >
            <DatePicker showTime style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="end_time"
            label="End Time"
            rules={[{ required: true, message: "Please select an end time" }]}
          >
            <DatePicker showTime style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div style={{ textAlign: 'right', marginBottom: 0, marginTop: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#cdaf5e',
                }}
              >
                Sync Jobs
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default SyncJobsModal;