import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography, Box } from '@mui/material';
import companyLogo from '../assets/company-logo.png';
import DatabasesDropDown from '../components/DatabasesDropDown';
import AllocateDropDown from '../components/AllocateDropDown';
import styles from './HomePage.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  IconArrowNarrowRight, 
  IconTrendingDown, 
  IconPackageExport, 
  IconArrowRight, 
  IconFileTime, 
  IconChartAreaLine, 
  IconCalendarQuestion, 
  IconDatabase, 
  IconCar, 
  IconTractor, 
  IconUsers, 
  IconRepeat, 
  IconTrendingUp, 
  IconHistory,
  IconHeartHandshake,
  IconSnowflake,
  IconClipboardList,
  IconForms
} from '@tabler/icons-react';
import api from '../authentication/axiosInstance';
import bgImage from '../assets/dashboard-page-4.jpg';
import { message } from 'antd';
import moment from 'moment-timezone';

const HomePage = () => {
  const navigate = useNavigate();
  const [allocateExpanded, setAllocateExpanded] = useState(false);
  const [dispatchExpanded, setDispatchExpanded] = useState(false);
  const [databasesExpanded, setDatabasesExpanded] = useState(false);
  const [debriefExpanded, setDebriefExpanded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [dispatchlogs, setDispatchLogs] = useState([]);
  const [latestStorm, setLatestStorm] = useState({});
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
  });
  const [clientCareExpanded, setClientCareExpanded] = useState(false);

  function timeOfDayGreeting() {
    const currentTime = new Date().getHours();
    let greeting;
    if (currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    return greeting;
  }

  const fetchLatestStorm = async () => {
    try {
      const response = await api.get('/dispatch/storm/latest/');
      setLatestStorm(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching latest created storm:', error);
      message.error('Error fetching latest created storm');
    }
  }

  const fetchMessages = async () => {
    try {
      const response = await api.get('/dispatch/warning_messages/');
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
      message.error('Failed to fetch messages');
    }
  };

  const fetchDispatchLogs = async () => {
    try {
      const types = ["Loader", "Walkway", "Salting"];
      // Create an array of API requests for each type
      const requests = types.map(type => api.get(`/dispatch/dispatchLogs/${type}/`));
      // Execute all requests concurrently
      const responses = await Promise.all(requests);
      // Combine the data from all responses into a single array
      const combinedLogs = responses.reduce((accumulator, currentResponse) => {
        return accumulator.concat(currentResponse.data);
      }, []);
      setDispatchLogs(combinedLogs);    
    } catch (error) {
      console.error("Failed to fetch dispatch logs:", error);
      message.error("Failed to fetch dispatch logs");
      setDispatchLogs([]);
    }
  };

  useEffect(() => {
    fetchLatestStorm();
    fetchMessages();
    fetchDispatchLogs();
  },[])

  // Helper to count staff statuses
  const countStaffStatuses = (staffArray, status) => {
    return staffArray.filter(staff => staff.status === status).length;
  };
  
  // Filter to get dispatch logs for the latest storm
  const latestStormDispatchLogs = dispatchlogs.filter(
    (log) => log.storm_id === latestStorm.storm_id
  );
  
  // Flatten staff details
  const allStaffForLatestStorm = latestStormDispatchLogs.flatMap(
    (log) => log.staff_details
  );
  
  // Count statuses
  const UnreadCount = countStaffStatuses(allStaffForLatestStorm, 'Unread') + countStaffStatuses(allStaffForLatestStorm, 'Created'); //include created status
  const ConfirmedCount = countStaffStatuses(allStaffForLatestStorm, 'Confirmed');
  const DeniedCount = countStaffStatuses(allStaffForLatestStorm, 'Denied');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await api.get('/users/profile/');
        setUser({
          firstName: data.first_name,
          lastName: data.last_name,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, []);

  const MessageStatusCounter = ({ counts }) => {
    return (
      <div className={styles.messageStatusContainer}>
        <div className={styles.messageStatusRow}>
          <div className={styles.messageStatusItem} style={{ backgroundColor: '#f0f0f0' }}>
            <span className={styles.messageStatusLabel}>Unread</span>
            <span className={styles.messageStatusCount}>{counts.unread || 0}</span>
          </div>
          
          <div className={styles.messageStatusItem} style={{ backgroundColor: '#E5F2EC' }}>
            <span className={styles.messageStatusLabel} style={{ color: '#034832' }}>Confirmed</span>
            <span className={styles.messageStatusCount}>{counts.confirmed || 0}</span>
          </div>
          
          <div className={styles.messageStatusItem} style={{ backgroundColor: '#FDEAEA' }}>
            <span className={styles.messageStatusLabel} style={{ color: '#FF4D4E' }}>Denied</span>
            <span className={styles.messageStatusCount} style={{ backgroundColor: '#FF4D4E' }}>{counts.denied || 0}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Box 
      className={styles.main} 
      style={{ 
        backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${bgImage})`, 
        backgroundPosition: "center", 
        backgroundSize: "cover"  
      }}
    >
      <Box className={styles.topRow}>
        <motion.div
          className={styles.left}
          initial={{ opacity: 0, translateY: 550 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 30,
          }}
        >
          <Typography className={styles.title}>
            {timeOfDayGreeting()}<br/>
            <span style={{ fontWeight: "700" }}>
              {user.firstName !== '' ? user.firstName : 'Pierre-Marc'}
            </span>
          </Typography>
          <Typography className={styles.subtitle}>
            Here's what's happening!
          </Typography>
        </motion.div>
        <AnimatePresence>
          <Box className={styles.right}>
            <motion.div
              className={styles.metricCard}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 1.0,
                duration: 0.3,
              }}
            >
            <Typography className={styles.metricCardTitle}>Latest Created Storm</Typography>
            <Box
              className={styles.metricCardValueContainer}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start", // added to left-align child elements
                gap: "10px",
              }}
            >
              {latestStorm ? (
                <>
                  <Typography className={styles.metricCardValue}>
                    {latestStorm.severity
                      ? latestStorm.severity.charAt(0).toUpperCase() + latestStorm.severity.slice(1)
                      : ''}
                    {' '}
                    {latestStorm.type
                      ? latestStorm.type.replace(/_/g, ' ')
                      : ''}
                  </Typography>
                  <Typography className={styles.metricCardSubtitle}>
                    {latestStorm.date_of_storm
                      ? `Date: ${moment.utc(latestStorm.date_of_storm).tz('America/New_York').format('MMM Do, YYYY')}`
                      : 'No date available'}
                  </Typography>
                </>
              ) : (
                <Typography className={styles.metricCardValue}>No active storm</Typography>
              )}
            </Box>
            </motion.div>
            <motion.div
              className={styles.metricCard}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 1.2,
                duration: 0.3,
              }}
            >
              <Typography className={styles.metricCardTitle}>Warning Message Summary</Typography>
              <Box className={styles.metricCardValueContainer}>
              {latestStorm ? (
                <>
                  <MessageStatusCounter
                    counts={{
                      unread: messages.filter(
                        m =>
                          (!m.status || m.status === 'Unread') &&
                          m.storm === latestStorm.storm_id
                      ).length,
                      confirmed: messages.filter(
                        m =>
                          m.status === 'Confirmed' &&
                          m.storm === latestStorm.storm_id
                      ).length,
                      denied: messages.filter(
                        m =>
                          m.status === 'Denied' &&
                          m.storm === latestStorm.storm_id
                      ).length,
                    }}
                  />
                  <Typography className={styles.metricCardSubtitle}>
                    Total Warning Messages: {messages.filter(m => m.storm === latestStorm.storm_id).length}
                  </Typography>
                </>
              ) : (
                <Typography className={styles.metricCardValue}>No messages available</Typography>
              )}
              </Box>
            </motion.div>
            <motion.div
              className={styles.metricCard}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 1.4,
                duration: 0.3,
              }}
            >
              <Typography className={styles.metricCardTitle}>Dispatch Message Summary</Typography>
              <Box className={styles.metricCardValueContainer}>
                {latestStorm ? (
                  <>
                    <MessageStatusCounter
                      counts={{
                        unread: UnreadCount,
                        confirmed: ConfirmedCount,
                        denied: DeniedCount,
                      }}
                    />
                    <Typography className={styles.metricCardSubtitle}>
                      Total Dispatch Messages: {allStaffForLatestStorm.length}
                    </Typography>
                  </>
                ) : (
                  <Typography className={styles.metricCardValue}>No dispatches available</Typography>
                )}
              </Box>
            </motion.div>
          </Box>
        </AnimatePresence>
      </Box>
      <motion.div
        className={styles.actionsContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1.5,
          duration: 0.5,
        }}
      >
        {/* Allocate Section */}
        <Box 
          className={allocateExpanded ? styles.actionCardActive : styles.actionCard} 
          onClick={() => setAllocateExpanded(!allocateExpanded)}
        >
          <Box className={styles.actionCardTopRow}>
            <Box className={allocateExpanded ? styles.actionCardTopRowLeftActive : styles.actionCardTopRowLeft}>
              <IconPackageExport size={24}/>
              <Typography className={allocateExpanded ? styles.actionCardTitleActive : styles.actionCardTitle}>
                Allocate
              </Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={allocateExpanded ? styles.actionCardBodyActive : styles.actionCardBody}>
            Assign your resources to a specific task.
          </Box>
        </Box>
        {allocateExpanded && (
          <>
          <motion.div
            className={styles.actionCardExpanded}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0,
            }}
          >
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.1,
                duration: 0.3,
              }}
              onClick={() => navigate("/allocation")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconCar size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Loader</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 0.3,
              }}
              onClick={() => navigate("/WalkwayCrew")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconUsers size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Walkway Crew</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.3,
                duration: 0.3,
              }}
              onClick={() => navigate("/SaltingRoute")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconTrendingUp size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Salting Route</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
          </motion.div>
          </>
        )}

        {/* Dispatch Section */}
        <Box 
          className={dispatchExpanded ? styles.actionCardActive : styles.actionCard} 
          onClick={() => setDispatchExpanded(!dispatchExpanded)}
        >
          <Box className={styles.actionCardTopRow}>
            <Box className={dispatchExpanded ? styles.actionCardTopRowLeftActive : styles.actionCardTopRowLeft}>
              <IconPackageExport size={24}/>
              <Typography className={dispatchExpanded ? styles.actionCardTitleActive : styles.actionCardTitle}>
                Dispatch
              </Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={dispatchExpanded ? styles.actionCardBodyActive : styles.actionCardBody}>
            Dispatch your resources
          </Box>
        </Box>
        {dispatchExpanded && (
          <>
          <motion.div
            className={styles.actionCardExpanded}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0,
            }}
          >
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.1,
                duration: 0.3,
              }}
              onClick={() => navigate("/walkwayCrewDispatch")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconUsers size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Walkway Crews</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 0.3,
              }}
              onClick={() => navigate("/saltingRouteDispatch")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconTractor size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Salting Routes</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.3,
                duration: 0.3,
              }}
              onClick={() => navigate("/loaderDispatch")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconCar size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Loaders</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
          </motion.div>
            
          <motion.div
              className={styles.actionCardExpandedLessItems}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0,
              }}
            >
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.4,
                  duration: 0.3,
                }}
                onClick={() => navigate("/dispatchHistory")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconHistory size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Dispatch History</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 0.3,
                }}
                onClick={() => navigate("/messages")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconHistory size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Messages</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
            </motion.div>
          </>
        )}

        {/* Debrief section */}
        <Box 
          className={debriefExpanded ? styles.actionCardActive : styles.actionCard} 
          onClick={() => setDebriefExpanded(!debriefExpanded)}
        >
          <Box className={styles.actionCardTopRow}>
            <Box className={debriefExpanded ? styles.actionCardTopRowLeftActive : styles.actionCardTopRowLeft}>
              <IconPackageExport size={24}/>
              <Typography className={debriefExpanded ? styles.actionCardTitleActive : styles.actionCardTitle}>
                Debrief
              </Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={debriefExpanded ? styles.actionCardBodyActive : styles.actionCardBody}>
            Debrief data.
          </Box>
        </Box>
        {debriefExpanded && (
          <>
          <motion.div
            className={styles.actionCardExpanded}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0,
            }}
          >
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.1,
                duration: 0.3,
              }}
              onClick={() => navigate("/debriefJobs")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconUsers size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Jobs</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
          </motion.div>
          </>
        )}

        {/* Additional Action Cards */}
        <Box className={styles.actionCard} onClick={() => navigate("/estimatepage")}>
          <Box className={styles.actionCardTopRow}>
            <Box className={styles.actionCardTopRowLeft}>
              <IconFileTime size={24}/>
              <Typography className={styles.actionCardTitle}>Estimates</Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={styles.actionCardBody}>
            Create and manage project estimates.
          </Box>
        </Box>
        <Box className={styles.actionCard} onClick={() => navigate("/projection")}>
          <Box className={styles.actionCardTopRow}>
            <Box className={styles.actionCardTopRowLeft}>
              <IconChartAreaLine size={24}/>
              <Typography className={styles.actionCardTitle}>Projections</Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={styles.actionCardBody}>
            View and analyze future projections.
          </Box>
        </Box>
        <Box className={styles.actionCard} onClick={() => navigate("/requests")}>
          <Box className={styles.actionCardTopRow}>
            <Box className={styles.actionCardTopRowLeft}>
              <IconRepeat size={24}/>
              <Typography className={styles.actionCardTitle}>Requests</Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={styles.actionCardBody}>
            View client service requests and take action.
          </Box>
        </Box>
        {/* Databases Section */}
        <Box className={databasesExpanded ? styles.actionCardActive : styles.actionCard} onClick={() => setDatabasesExpanded(!databasesExpanded)}>
          <Box className={styles.actionCardTopRow}>
            <Box className={databasesExpanded ? styles.actionCardTopRowLeftActive : styles.actionCardTopRowLeft}>
              <IconDatabase size={24}/>
              <Typography className={databasesExpanded ? styles.actionCardTitleActive : styles.actionCardTitle}>Databases</Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={databasesExpanded ? styles.actionCardBodyActive : styles.actionCardBody}>
            See all the databases available to you.
          </Box>
        </Box>
        {databasesExpanded && (
          <>
             <motion.div
              className={styles.actionCardExpanded}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0,
              }}
            >
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                  duration: 0.3,
                }}
                onClick={() => navigate("/sites")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Sites</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.2,
                  duration: 0.3,
                }}
                onClick={() => navigate("/vehicles")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Vehicles</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.3,
                  duration: 0.3,
                }}
                onClick={() => navigate("/staff")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Staff</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedLessItems}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0,
              }}
            >
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.4,
                  duration: 0.3,
                }}
                onClick={() => navigate("/subtypes")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Vehicle Subtypes</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 0.3,
                }}
                onClick={() => navigate("/clients")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Clients</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 0.3,
                }}
                onClick={() => navigate("/equipments")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Equipments</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 0.3,
                }}
                onClick={() => navigate("/storms")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Storms</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
            </motion.div>
          </>
        )}

        {/* Add Client Care card after Databases card */}
        <Box 
          className={clientCareExpanded ? styles.actionCardActive : styles.actionCard} 
          onClick={() => setClientCareExpanded(!clientCareExpanded)}
        >
          <Box className={styles.actionCardTopRow}>
            <Box className={clientCareExpanded ? styles.actionCardTopRowLeftActive : styles.actionCardTopRowLeft}>
              <IconHeartHandshake size={24}/>
              <Typography className={clientCareExpanded ? styles.actionCardTitleActive : styles.actionCardTitle}>
                Client Care
              </Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={clientCareExpanded ? styles.actionCardBodyActive : styles.actionCardBody}>
            Manage client communications and feedback.
          </Box>
        </Box>
        {clientCareExpanded && (
          <>
            <motion.div
              className={styles.actionCardExpanded}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0,
              }}
            >
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                  duration: 0.3,
                }}
                onClick={() => navigate("/storm-strategy")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconSnowflake size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Storm Strategy</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.2,
                  duration: 0.3,
                }}
                onClick={() => navigate("/storm-strategies")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconClipboardList size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Strategy History</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.3,
                  duration: 0.3,
                }}
                onClick={() => navigate("/storm-strategy-surveys")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconForms size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Survey Responses</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
            </motion.div>
          </>
        )}
      </motion.div>
    </Box>
  );
}

export default HomePage;
