// DebriefJobs.js
import React, { useEffect, useState, useMemo } from 'react';
import { ConfigProvider, Table, message, Tag, Tooltip, Spin, Select, Input, Button, Dropdown, Popconfirm, Modal, Form, DatePicker } from 'antd';
import { IconBuildingFactory2, IconCloudRain, IconGrain, IconMessage, IconSnowflake, IconTool, IconBriefcase, IconCloudQuestion } from '@tabler/icons-react';
import { SearchOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SyncOutlined} from '@ant-design/icons';
import { IconTractor, IconUser } from '@tabler/icons-react';
import api from "../authentication/axiosInstance";
import { Box } from '@mui/material';
import SyncJobsModal from '../components/SyncJobsModal';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;

const DebriefJobs = () => {
  const [jobs, setJobs] = useState([]);

  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobTypeFilter, setJobTypeFilter] = useState(undefined);
  const [stormTypeFilter, setStormTypeFilter] = useState("");
  const [siteNameFilter, setSiteNameFilter] = useState("");
  const [staffNameFilter, setStaffNameFilter] = useState("");
  const [vehicleNameFilter, setVehicleNameFilter] = useState("");

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingJob, setEditingJob] = useState(null);
  const [editForm] = Form.useForm();

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
  const [addForm] = Form.useForm();   

  const [storms, setStorms] = useState([]);
  const [sites, setSites] = useState([]);
  const [staff, setStaff] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const fetchJobs = async () => {
    try {
      const response = await api.get('/debrief/jobs/');
      setJobs(response.data);
      setFilteredJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      message.error('Failed to fetch jobs');
    }
  };

  useEffect(() => {
    fetchJobs();
  },[])

  const capitalize = (text) => {
    if (!text) return "N/A"; // Return "N/A" if text is null or undefined
    return text
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const fetchOptions = async () => {
    try {
      const [stormsResponse, sitesResponse, staffResponse, vehiclesResponse] = await Promise.all([
        api.get('/dispatch/storm/'),
        api.get('/sites/'),
        api.get('/staff/'),
        api.get('/vehicles/')
      ]);
  
      setStorms(stormsResponse.data);
      setSites(sitesResponse.data);
      setStaff(staffResponse.data);
      setVehicles(vehiclesResponse.data);
    } catch (error) {
      console.error("Failed to fetch options:", error);
      message.error("Failed to load options for editing.");
    }
  };
  
  useEffect(() => {
    fetchOptions();
  }, []);
  
  const stormSeverityColors = [
    { severity: "light", color: "#034832" , bg: "#E5F2EC"},
    { severity: "medium", color: "#FFC107", bg: "#FFF8E1" },
    { severity: "heavy", color: "#FF4D4E", bg: "#FDEAEA" },
    { severity: "N/A", color: "#808080", bg: "#F5F5F5" },
  ]

  const getSeverityColor = (severity) => {
    const severityColor = stormSeverityColors.find(item => item.severity === severity);
    return severityColor ? severityColor : { color: "#000000", bg: "#FFFFFF" }; // Default fallback
  };

  const jobTypes = useMemo(() => {
    return Array.from(new Set(jobs.map((job) => job.type))).filter(Boolean);
  }, [jobs]);

  const handleFilter = () => {
    const filtered = jobs.filter((job) => {
      const matchJobType = jobTypeFilter ? job.type === jobTypeFilter : true;
      const matchStormType = stormTypeFilter
        ? (job.storm_details?.storm_type || "").toLowerCase().includes(stormTypeFilter.toLowerCase())
        : true;
      const matchSiteName = siteNameFilter
        ? (job.site_details?.site_name || "").toLowerCase().includes(siteNameFilter.toLowerCase())
        : true;
      const matchStaffName = staffNameFilter
        ? (job.staff_details?.staff_name || "").toLowerCase().includes(staffNameFilter.toLowerCase())
        : true;
      const matchVehicleName = vehicleNameFilter
        ? (job.vehicle_details?.vehicle_name || "").toLowerCase().includes(vehicleNameFilter.toLowerCase())
        : true;

      return matchJobType && matchStormType && matchSiteName && matchStaffName && matchVehicleName;
    });
    setFilteredJobs(filtered);
  };

  useEffect(() => {
    handleFilter();
  }, [jobTypeFilter, stormTypeFilter, siteNameFilter, staffNameFilter, vehicleNameFilter]);

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };
  
  const handleAddCancel = () => {
    setIsAddModalVisible(false);
    addForm.resetFields();
  };

  const handleAddSubmit = async (values) => {
    try {
      const newJob = {
        storm: values.storm_id || null,
        site: values.site_id || null,
        staff: values.staff_ids || [],  // Send array of staff IDs
        vehicle: values.vehicle_id || null,
        type: values.type,
        job_start_date: values.job_start_date ? values.job_start_date.tz('UTC').format() : null,
        job_end_date: values.job_end_date ? values.job_end_date.tz('UTC').format() : null,
      };
  
      await api.post('/debrief/jobs/', newJob);
      message.success('Job added successfully');
      setIsAddModalVisible(false);
      fetchJobs(); // Refresh the jobs list
    } catch (error) {
      console.error('Failed to add job:', error);
      message.error('Failed to add job');
    }
  };

  const handleSyncModalClose = async () => {
    fetchJobs();
    setIsSyncModalOpen(false);
  };

  const columns = [
    {
      title: 'Job Details',
      render: (text, record) => {
        console.log(record);
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            {/* Briefcase Icon */}
            <div
              style={{
                height: '30px',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconBriefcase size={18} stroke={2} />
            </div>
    
            {/* Time Details */}
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              {/* Job Type */}
              <span style={{ fontWeight: '600', fontSize: '16px' }}>
                {record.type || 'N/A'}
              </span>
    
              {/* Job Start Date */}
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                Start: {record.job_start_date
                  ? dayjs(record.job_start_date)
                      .tz('America/New_York')
                      .format('MMMM DD, YYYY hh:mm A')
                  : 'N/A'}
              </span>
    
              {/* Job End Date */}
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                End: {record.job_end_date
                  ? dayjs(record.job_end_date)
                      .tz('America/New_York')
                      .format('MMMM DD, YYYY hh:mm A')
                  : 'N/A'}
              </span>
    
              {/* Job Date Created */}
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                Created: {record.job_date_created
                  ? dayjs(record.job_date_created)
                      .tz('America/New_York')
                      .format('MMMM DD, YYYY hh:mm A')
                  : 'N/A'}
              </span>
    
              {/* Job ID */}
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                ID: {record.job_id || 'N/A'}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Storm Details',
      render: (text, record) => {
        const storm_details = record.storm_details || {};
        const { storm_type, storm_severity, storm_id, date_of_storm } = storm_details;
        const { color, bg } = getSeverityColor(storm_severity || "N/A");
    
        return (
          <div 
            style={{ 
              display: 'flex', 
              alignItems: 'flex-start', 
              justifyContent: 'center', 
              flexDirection: 'column', 
              width: '100%', 
            }}
          >
            {storm_type ? (
              <div>
            {(storm_type === "snow" || 
              storm_type === "Snow Showers" || 
              storm_type === "Isolated Snow Showers" ||
              storm_type === "Snow" || 
              storm_type === "Chance of Snow" || 
              storm_type === "Light Snow" ||
              storm_type === "Chance of Sleet/Snow" ||
              storm_type === "Scattered Snow Showers") ? (
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  flexDirection: 'row', 
                  gap: '7px' 
                }}>
                  <div style={{ 
                    backgroundColor: bg,
                    height: '30px', 
                    width: '30px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    border: `1px solid ${color}`, 
                    borderRadius: '4px', 
                    color: color 
                  }}>
                    <IconSnowflake size={18} stroke={2} />
                  </div>
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'flex-start', 
                    justifyContent: 'flex-start', 
                    flexDirection: 'column' 
                  }}>
                    <span style={{ fontWeight: '600', fontSize: '16px' }}>
                      {storm_type === "snow" ? "Snow" : storm_type}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      Severity: {storm_severity}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      Start: {dayjs(date_of_storm).tz('America/New_York').format('MMMM DD, YYYY hh:mm A')}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      End: {dayjs(storm_details.date_of_storm_end).tz('America/New_York').format('MMMM DD, YYYY hh:mm A')}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      ID: {storm_id}
                    </span>
                  </div>
                </div>
            ) : (storm_type === "freezing_rain" || 
                 storm_type === "Wintry Mix" || 
                 storm_type === "Chance of Wintry Mix" || 
                 storm_type === "Fog" ||
                 storm_type === "Isolated Freezing Rain" ||
                 storm_type === "Chance of Freezing Rain") ? (
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  flexDirection: 'row', 
                  gap: '7px' 
                }}>
                  <div style={{ 
                    backgroundColor: bg,
                    height: '30px', 
                    width: '30px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    border: `1px solid ${color}`, 
                    borderRadius: '4px', 
                    color: color 
                  }}>
                    <IconCloudRain size={18} stroke={2} />
                  </div>
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'flex-start', 
                    justifyContent: 'flex-start', 
                    flexDirection: 'column' 
                  }}>
                    <span style={{ fontWeight: '600', fontSize: '16px' }}>
                      {storm_type === "freezing_rain" ? "Freezing Rain" : storm_type}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      Severity: {storm_severity}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      Start: {dayjs(date_of_storm).tz('America/New_York').format('MMMM DD, YYYY hh:mm A')}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      End: {dayjs(storm_details.date_of_storm_end).tz('America/New_York').format('MMMM DD, YYYY hh:mm A')}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      ID: {storm_id}
                    </span>
                  </div>
                </div>
            ) : storm_type === "salt" ? (
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  flexDirection: 'row', 
                  gap: '7px' 
                }}>
                  <div style={{ 
                    backgroundColor: bg,
                    height: '30px', 
                    width: '30px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    border: `1px solid ${color}`, 
                    borderRadius: '4px', 
                    color: color 
                  }}>
                    <IconGrain size={18} stroke={2} />
                  </div>
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'flex-start', 
                    justifyContent: 'flex-start', 
                    flexDirection: 'column' 
                  }}>
                    <span style={{ fontWeight: '600', fontSize: '16px' }}>
                      Salting
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      Severity: {storm_severity}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      Start: {dayjs(date_of_storm).tz('America/New_York').format('MMMM DD, YYYY hh:mm A')}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      End: {dayjs(storm_details.date_of_storm_end).tz('America/New_York').format('MMMM DD, YYYY hh:mm A')}
                    </span>
                    <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                      ID: {storm_id}
                    </span>
                  </div>
                </div>
            ) : (
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                flexDirection: 'row', 
                gap: '7px' 
              }}>
                <div style={{ 
                  backgroundColor: '#FDEAEA', 
                  height: '30px', 
                  width: '30px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  border: '1px solid #FF4D4E', 
                  borderRadius: '4px', 
                  color: "#FF4D4E" 
                }}>
                  <IconCloudQuestion size={18} stroke={2} />
                </div>
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  justifyContent: 'flex-start', 
                  flexDirection: 'column' 
                }}>
                  <span style={{ fontWeight: '600', fontSize: '16px' }}>
                    {storm_type}
                  </span>
                  <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                    Severity: {storm_severity}
                  </span>
                  <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                    Start: {dayjs(date_of_storm).tz('America/New_York').format('MMMM DD, YYYY hh:mm A')}
                  </span>
                  <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                    End: {dayjs(storm_details.date_of_storm_end).tz('America/New_York').format('MMMM DD, YYYY hh:mm A')}
                  </span>
                  <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                    ID: {storm_id}
                  </span>
                </div>
              </div>
            )}
              </div>
            ) : (
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                flexDirection: 'row', 
                gap: '7px' 
              }}>
                <div style={{ 
                  backgroundColor: '#F5F5F5', 
                  height: '30px', 
                  width: '30px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  border: '1px solid #808080', 
                  borderRadius: '4px', 
                  color: "#808080" 
                }}>
                  <IconCloudQuestion size={18} stroke={2} />
                </div>
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  justifyContent: 'flex-start', 
                  flexDirection: 'column' 
                }}>
                  <span style={{ fontWeight: '600', fontSize: '16px' }}>
                    No Storm Details
                  </span>
                  <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                    Severity: N/A
                  </span>
                  <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                    Start: N/A
                  </span>
                  <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                    End: N/A
                  </span>
                  <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                    ID: N/A
                  </span>
                </div>
              </div>
            )}
          </div>
        )
      }
    },
    {
      title: 'Site',
      render: (text, record) => {
        const { site_details } = record;
    
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            {/* Building Icon */}
            <div
              style={{
                height: '30px',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconBuildingFactory2 size={18} stroke={2} />
            </div>
    
            {/* Site Details */}
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              {/* Site Name */}
              <span style={{ fontWeight: '600', fontSize: '16px' }}>
                {site_details.site_name || 'N/A'}
              </span>
    
              {/* Client */}
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                {site_details.client || 'N/A'}
              </span>
    
              {/* Region */}
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                Region: {site_details.region || 'N/A'}
              </span>
    
              {/* Site ID */}
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                ID: {site_details.site_id || 'N/A'}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Staff',
      render: (text, record) => {
        const { staff_details } = record;
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            {/* Staff List */}
            {staff_details && staff_details.length > 0 ? (
              staff_details.map((staff, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '10px',
                  }}
                >
                  {/* Icon */}
                  <div
                    style={{
                      height: '30px',
                      width: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconUser size={18} stroke={2} />
                  </div>
    
                  {/* Staff Details */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ fontWeight: '600', fontSize: '14px' }}>
                      {staff.staff_name || 'N/A'}
                    </span>
                    <span style={{ fontSize: '12px', color: '#888' }}>
                      {staff.staff_position || 'N/A'}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <span style={{ fontSize: '14px' }}> No Staff Assigned</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Vehicle',
      render: (text, record) => {
        const { vehicle_details } = record;
    
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            {/* Vehicle Icon */}
            <div
              style={{
                height: '30px',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconTractor size={18} stroke={2} />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontWeight: '600', fontSize: '16px' }}>
                {vehicle_details.vehicle_name || 'N/A'}
              </span>
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                Subtype: {vehicle_details.vehicle_subtype || 'N/A'}
              </span>
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                Model: {vehicle_details.vehicle_model || 'N/A'}
              </span>
              <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                ID: {vehicle_details.vehicle_id || 'N/A'}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width:175,
      render: (record) => (
        <div>
          <Button
            type="primary"
            onClick={() => showEditModal(record)}
            icon={<EditOutlined />}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
          >
          Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this job?"
            onConfirm={() => handleDelete(record.job_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              className='delete-button'
            >
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleEditSubmit = async (values) => {
    try {
      // Construct payload with foreign key IDs and other fields
      const updatedJob = {
        storm: values.storm_id || null,
        site: values.site_id || null,
        staff: values.staff_ids || [],  // Send array of staff IDs
        vehicle: values.vehicle_id || null,
        type: values.type,
        job_start_date: values.job_start_date ? values.job_start_date.tz('UTC').format() : null,
        job_end_date: values.job_end_date ? values.job_end_date.tz('UTC').format() : null,
      };
  
      // Send patch request to the backend
      await api.patch(`/debrief/jobs/${editingJob.job_id}/`, updatedJob);
  
      message.success("Job updated successfully");
      setIsEditModalVisible(false); // Close the modal
      fetchJobs(); // Refresh the jobs list
    } catch (error) {
      console.error("Failed to update job:", error);
      message.error("Failed to update job");
    }
  };

  const showEditModal = (job) => {
    setEditingJob(job);
  
    // Pre-populate the form with the current job details
    editForm.setFieldsValue({
      type: job.type,
      storm_id: job.storm,
      site_id: job.site,
      staff_ids: job.staff_details ? job.staff_details.map((staff) => staff.staff_id) : [],  // Map IDs
      vehicle_id: job.vehicle,
      job_start_date: job.job_start_date ? dayjs(job.job_start_date).tz('America/New_York') : null,
      job_end_date: job.job_end_date ? dayjs(job.job_end_date).tz('America/New_York') : null,
    });
  
    setIsEditModalVisible(true); // Show the modal
  };
  
  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setEditingJob(null);
    editForm.resetFields();
  };

  const handleDelete = async (jobId) => {
    try {
      await api.delete(`/debrief/jobs/${jobId}/`);
      message.success('Job deleted successfully');
      fetchJobs();
    } catch (error) {
      console.error('Failed to delete job:', error);
      message.error('Failed to delete job');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* Edit modal */}
      <Modal
        title="Edit Job"
        open={isEditModalVisible}
        onCancel={handleEditCancel}
        footer={null}
      >
        <Form form={editForm} onFinish={handleEditSubmit} layout="vertical">
          {/* Job Type */}
          <Form.Item name="type" label="Job Type" rules={[{ required: true, message: "Please enter the job type" }]}>
            <Input placeholder="Enter Job Type" />
          </Form.Item>

          {/* Storm Selection */}
          <Form.Item name="storm_id" label="Storm" rules={[{ required: true, message: "Please select a storm" }]}>
            <Select
              showSearch
              placeholder="Search and select a storm"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {storms.map((storm) => (
                <Select.Option key={storm.storm_id} value={storm.storm_id}>
                  {`${capitalize(storm.type)} - ${dayjs(storm.date_of_storm).format('YYYY/MM/DD')} to ${dayjs(storm.date_of_storm_end).format('YYYY/MM/DD')} - ${storm.severity}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Site Selection */}
          <Form.Item name="site_id" label="Site" rules={[{ required: true, message: "Please select a site" }]}>
            <Select
              showSearch
              placeholder="Search and select a site"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {sites.map((site) => (
                <Select.Option key={site.site_id} value={site.site_id}>
                  {site.site_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Staff Selection */}
          <Form.Item
            name="staff_ids"
            label="Staff"
            rules={[{ required: true, message: "Please select at least one staff member" }]}
          >
            <Select
              mode="multiple" // Allow selecting multiple options
              showSearch
              placeholder="Search and select staff members"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {staff.map((person) => (
                <Select.Option key={person.staff_id} value={person.staff_id}>
                  {person.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Vehicle Selection */}
          <Form.Item name="vehicle_id" label="Vehicle" rules={[{ required: true, message: "Please select a vehicle" }]}>
            <Select
              showSearch
              placeholder="Search and select a vehicle"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {vehicles.map((vehicle) => (
                <Select.Option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                  {vehicle.vehicle_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Job Start Date */}
          <Form.Item
            name="job_start_date"
            label="Job Start Date"
            rules={[{ required: true, message: "Please select the start date" }]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
          </Form.Item>

          {/* Job End Date */}
          <Form.Item
            name="job_end_date"
            label="Job End Date"
            rules={[{ required: true, message: "Please select the end date" }]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
          </Form.Item>

          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}>
              Update Job
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Add Modal */}
      <Modal
        title="Add New Job"
        open={isAddModalVisible}
        onCancel={handleAddCancel}
        footer={null}
      >
        <Form form={addForm} onFinish={handleAddSubmit} layout="vertical">
          <Form.Item name="type" label="Job Type" rules={[{ required: true, message: "Please enter the job type" }]}>
            <Input placeholder="Enter Job Type" />
          </Form.Item>
          <Form.Item name="storm_id" label="Storm" rules={[{ required: true, message: "Please select a storm" }]}>
            <Select
              showSearch
              placeholder="Search and select a storm"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {storms.map((storm) => (
                <Select.Option key={storm.storm_id} value={storm.storm_id}>
                  {`${capitalize(storm.type)} - ${dayjs(storm.date_of_storm).format('YYYY/MM/DD')} to ${dayjs(storm.date_of_storm_end).format('YYYY/MM/DD')} - ${storm.severity}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="site_id" label="Site" rules={[{ required: true, message: "Please select a site" }]}>
            <Select
              showSearch
              placeholder="Search and select a site"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {sites.map((site) => (
                <Select.Option key={site.site_id} value={site.site_id}>
                  {site.site_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="staff_ids"
            label="Staff"
            rules={[{ required: true, message: "Please select at least one staff member" }]}
          >
            <Select
              mode="multiple" // Allow selecting multiple options
              showSearch
              placeholder="Search and select staff members"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {staff.map((person) => (
                <Select.Option key={person.staff_id} value={person.staff_id}>
                  {person.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="vehicle_id" label="Vehicle" rules={[{ required: true, message: "Please select a vehicle" }]}>
            <Select
              showSearch
              placeholder="Search and select a vehicle"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {vehicles.map((vehicle) => (
                <Select.Option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                  {vehicle.vehicle_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="job_start_date" label="Job Start Date" rules={[{ required: true, message: "Please select the start date" }]}>
            <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="job_end_date" label="Job End Date" rules={[{ required: true, message: "Please select the end date" }]}>
            <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}>Add Job</Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Sync Jobs Modal */}
      <SyncJobsModal
        open={isSyncModalOpen}
        onClose={handleSyncModalClose}
        storms={storms}
      />

      {/* Jobs Header */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ marginLeft: 30 }}>Jobs</h2>
      </div>
  
      {/* Add Job Button and Filters */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="primary"
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
            onClick={() => setIsSyncModalOpen(true)}
            icon={<SyncOutlined />}
          >
            Sync Jobs
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
            onClick={showAddModal}
            icon={<PlusOutlined />}
          >
            Add Job
          </Button>
        </div>
        {/* Filters */}
        <div style={{ display: 'flex', gap: '10px' }}>
          {/* Job Type Dropdown */}
          <Select
            placeholder="Filter by Job Type"
            value={jobTypeFilter}
            onChange={(value) => setJobTypeFilter(value)}
            style={{ width: 200 }}
            allowClear
          >
            {jobTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
  
          {/* Storm Type Input */}
          <Input
            placeholder="Search Storm Type"
            value={stormTypeFilter}
            onChange={(e) => setStormTypeFilter(e.target.value)}
            style={{ width: 200 }}
            allowClear
            prefix={<SearchOutlined style={{ color: '#969696' }} />}
          />

          {/* Site Name Input */}
          <Input
            placeholder="Search Site Name"
            value={siteNameFilter}
            onChange={(e) => setSiteNameFilter(e.target.value)}
            style={{ width: 200 }}
            allowClear
            prefix={<SearchOutlined style={{ color: '#969696' }} />}
          />

          {/* Staff Name Input */}
          <Input
            placeholder="Search Staff Name"
            value={staffNameFilter}
            onChange={(e) => setStaffNameFilter(e.target.value)}
            style={{ width: 200 }}
            allowClear
            prefix={<SearchOutlined style={{ color: '#969696' }} />}
          />

          {/* Vehicle Name Input */}
          <Input
            placeholder="Search Vehicle Name"
            value={vehicleNameFilter}
            onChange={(e) => setVehicleNameFilter(e.target.value)}
            style={{ width: 200 }}
            allowClear
            prefix={<SearchOutlined style={{ color: '#969696' }} />}
          />
        </div>
      </div>
  
      {/* Table */}
      <Box style={{ padding: '10px 30px 30px 30px' }}>
        <Table
          dataSource={filteredJobs}
          columns={columns}
          rowKey="job_id"
          pagination={false}
          scroll={{ y: 640 }}
        />
      </Box>
    </div>
  );
};

export default DebriefJobs;