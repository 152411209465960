import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { ConfigProvider, List, Button, Select, Input, Typography, message, Tabs, Modal, Spin, Row, Col, Card, Statistic, Space, Divider, Badge, Tooltip, Switch, Upload, Form, Image, Empty, Alert, Descriptions, DatePicker, Collapse, Carousel } from 'antd';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './RequestsPage.css';
import ClientRequestForm from '../components/ClientRequestForm';
import api from '../authentication/axiosInstance';
import ClientRequestsPortal from '../components/ClientRequestsPortal';
import { AuthContext } from '../context/AuthContext';
import { CalendarOutlined, EnvironmentOutlined, InfoCircleOutlined, MailOutlined, UserOutlined, PhoneOutlined, ClockCircleOutlined, UploadOutlined, EditOutlined, SaveOutlined, PictureOutlined, PlusOutlined, LeftOutlined, RightOutlined, FileTextOutlined, DownloadOutlined } from '@ant-design/icons';
import SnowEdgeRequestForm from '../components/SnowEdgeRequestForm';
import axios from 'axios';

import DispatchRequestsTable from '../components/DispatchRequestsTable';
import moment from 'moment';

const { Option } = Select;
const { Text, Title } = Typography;
const { TabPane } = Tabs;

const getDispatchStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'unread':
      return 'default';
    case 'read':
      return 'processing';
    case 'confirmed':
      return 'warning';
    case 'completed':
      return 'success';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};

const RequestLocationMap = React.memo(({ request, requestType }) => {
  const lat = requestType === 'regular' ? request.latitude : (request.site && request.site.latitude);
  const lon = requestType === 'regular' ? request.longitude : (request.site && request.site.longitude);
  
  const customMarkerIcon = L.icon({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    shadowSize: [41, 41],
  });

  if (!lat || !lon) return <Empty description="No location data available" />;

  return (
    <MapContainer
      center={[lat, lon]}
      zoom={16}
      style={{ height: '200px', width: '100%', borderRadius: '8px' }}
      zoomControl={false}
      whenCreated={(map) => map.setView([lat, lon], 16)}
      key={`${lat}-${lon}`}
    >
      <TileLayer
        url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
        attribution='© Mapbox © OpenStreetMap'
      />
      <Marker position={[lat, lon]} icon={customMarkerIcon}>
        <Popup>
          {request.site && request.site.address ? request.site.address : 'Location'}
        </Popup>
      </Marker>
    </MapContainer>
  );
});

const RequestsPage = () => {
  const { userRole, userEmail, hasSnowEdgeAccess, hasRegularRequestAccess } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(() => {
    // Set initial tab based on role and permissions
    if (userRole !== 'client') return '1'; // Admin/staff sees View Requests tab
    if (hasRegularRequestAccess) return '2'; // Regular request form
    if (hasSnowEdgeAccess) return '3'; // SnowEdge request form
    return '4'; // Default to Client Requests tab
  });
  const [requests, setRequests] = useState([]);
  const [clients, setClients] = useState([]);
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [activeRequestId, setActiveRequestId] = useState(null);
  const [map, setMap] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [viewRequestOpen, setViewRequestOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestType, setRequestType] = useState('regular');
  const [snowEdgeRequests, setSnowEdgeRequests] = useState([]);
  const [statusNotes, setStatusNotes] = useState('');
  const [statusPicture, setStatusPicture] = useState(null);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [editedStatusNotes, setEditedStatusNotes] = useState('');
  const [imageKey, setImageKey] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [claimNotes, setClaimNotes] = useState('');
  const [completeNotes, setCompleteNotes] = useState('');
  const [staffMembers, setStaffMembers] = useState([]);
  const [selectedRequestReceiver, setSelectedRequestReceiver] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [dispatchNotes, setDispatchNotes] = useState('');
  const [activeDispatch, setActiveDispatch] = useState(null);
  const [activeDispatches, setActiveDispatches] = useState([]);
  const [showDispatchForm, setShowDispatchForm] = useState(false);
  const [staffSearchValue, setStaffSearchValue] = useState('');
  const [users, setUsers] = useState([]);
  
  const [clientFilter, setClientFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(['pending', 'claimed']);

  const [openRequest, setOpenRequest] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [generatingReport, setGeneratingReport] = useState(false);

  const fetchClients = async () => {
    try {
      const response = await api.get('/clients/');
      setClients(response.data);
      console.log(clients);
    } catch (error) {
      console.error('Failed to fetch clients:', error);
      message.error('Failed to fetch clients');
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const customMarkerIcon = L.icon({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    shadowSize: [41, 41],
  });

  const getMapboxUrl = () => {
    const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    return `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${accessToken}`;
  };

  const handleUpload = () => {
    setUploading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });
    formData.forEach((file) => {
      console.log(file)
      api.post(`/request-manager/requests/${openRequest.id}/photo-upload-signed-url`)
      .then(function (result) {
        var signedUrl = result.data.signed_url;
        var options = {
          headers: {
            'Content-Type': "image/jpeg"
          }
        };
        return axios.put(signedUrl, file, options);
      })
      .then(function (result) {
        console.log(result);
        setFileList([]);
        message.success('upload successfully.');
        fetchRequest(openRequest.id)
      })
      .catch(function (err) {
        console.log(err);
        message.error('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
    })
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    console.log('User Role:', userRole);
    console.log('User Email:', userEmail);
    if (userRole !== 'client') {
      // Only fetch staff members if user is not a client
      fetchStaffMembers();
    }
    fetchRequests();
    fetchSnowEdgeRequests();
  }, [userRole, userEmail]);

  useEffect(() => {
    const fetchActiveDispatches = async () => {
      if (selectedRequest && selectedRequest.status === 'claimed') {
        try {
          // Use the same endpoint as DispatchRequestsTable
          const response = await api.get('/request-manager/request-dispatches/', {
            params: {
              [requestType === 'regular' ? 'regular_request' : 'snowedge_request']: selectedRequest.id,
              status: ['unread', 'read', 'confirmed']
            }
          });
          
          // The response should already contain all the needed fields
          console.log('Fetched dispatches:', response.data);
          setActiveDispatches(response.data);
          
        } catch (error) {
          console.error('Failed to fetch active dispatches:', error);
          message.error('Failed to fetch dispatch information');
        }
      }
    };

    if (viewRequestOpen) {
      fetchActiveDispatches();
    }
  }, [viewRequestOpen, selectedRequest, requestType]);
  
  useEffect(() => {
    if (selectedRequest && selectedRequest.status_pictures) {
      setImageUrl(selectedRequest.status_pictures);
      setImageError(false);
    } else {
      setImageUrl(null);
    }
  }, [selectedRequest]);

  const fetchRequests = async () => {
    try {
      setRequestsLoading(true);
      const response = await api.get('/request-manager/requests/');
      setRequests(response.data);
    } catch (error) {
      console.error('Failed to fetch requests:', error);
      message.error('Failed to fetch requests');
    } finally {
      setRequestsLoading(false);
    }
  };
  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users/');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const fetchRequest = async (request_id) => {
    try {
      setLoading(true);
      api.get(`/request-manager/requests/${request_id}/`)
      .then(function(result) {
        setOpenRequest(result.data)
      })
    } catch (error) {
      console.error('Failed to fetch requests:', error);
      message.error('Failed to fetch requests');
    } finally {
      setLoading(false);
    }
  };

  const fetchSnowEdgeRequests = async () => {
    try {
      setRequestsLoading(true);
      const response = await api.get('/request-manager/snow-edge-requests/');
      setSnowEdgeRequests(response.data);
    } catch (error) {
      console.error('Failed to fetch SnowEdge requests:', error);
      message.error('Failed to fetch SnowEdge requests');
    } finally {
      setRequestsLoading(false);
    }
  };

  const fetchStaffMembers = async () => {
    try {
      const response = await api.get('/staff/');
      setStaffMembers(response.data);
      const currentReceiver = response.data.find(staff => staff.is_request_receiver);
      if (currentReceiver) {
        setSelectedRequestReceiver(currentReceiver.staff_id);
      }
    } catch (error) {
      console.error('Failed to fetch staff members:', error);
      message.error('Failed to fetch staff members');
    }
  };

  const getRequestTypeLabel = (type) => {
    const labels = {
      salting_lots: "Salting Lots",
      plowing_lots: "Plowing Lots",
      emergency_exits: "Emergency Exits",
      loading_docks: "Loading Docks",
      walkways: "Walkways",
      other: "Other",
    };
    return labels[type] || type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const handleRequestClick = useCallback(async (requestId) => {
    const request = requestType === 'regular'

      ? requests.find(r => r.id === requestId)
      : snowEdgeRequests.find(r => r.id === requestId);
    setSelectedRequest(request);
    fetchRequest(requestId)
    setViewRequestOpen(true);
    setImageKey(prevKey => prevKey + 1);  // Force re-render of image

    if (request && map) {
      const lat = requestType === 'regular' ? request.latitude : (request.site && request.site.latitude);
      const lon = requestType === 'regular' ? request.longitude : (request.site && request.site.longitude);
      if (lat && lon) {
        map.flyTo([lat, lon], 14);
      }
    }

    // Fetch active dispatch if request is claimed
    if (request.status === 'claimed') {
      try {
        const response = await api.get('/request-manager/request-dispatches/', {
          params: {
            [requestType === 'regular' ? 'regular_request' : 'snowedge_request']: requestId,
            status: ['unread', 'read', 'confirmed'] // Get active (non-completed) dispatches
          }
        });
        
        // Log the response for debugging
        console.log('Dispatch response:', response.data);
        
        if (response.data && response.data.length > 0) {
          setActiveDispatch(response.data[0]); // Get the most recent active dispatch
        } else {
          setActiveDispatch(null); // Clear active dispatch if none found
        }
      } catch (error) {
        console.error('Failed to fetch active dispatch:', error);
        message.error('Failed to fetch dispatch information');
      }
    } else {
      setActiveDispatch(null); // Clear active dispatch if request is not claimed
    }
  }, [requestType, requests, snowEdgeRequests, map]);

  const closeModal = () => {
    setViewRequestOpen(false);
    setSelectedRequest(null);
    setOpenRequest(null);
  };

  const handleClaimRequest = async (requestId) => {
    try {
      const formData = new FormData();
      formData.append('status', 'claimed');
      formData.append('notes', claimNotes);
      if (statusPicture) {
        formData.append('status_picture', statusPicture);
      }

      const response = await api.post(`/request-manager/requests/${requestId}/update_status/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        message.success('Request Claimed Successfully');
        setActiveRequestId(null);
        setClaimNotes('');
        setStatusPicture(null);
        // Update the local state
        setRequests(prevRequests =>
          prevRequests.map(req =>
            req.id === requestId ? { ...req, status: 'claimed', claimed_notes: claimNotes } : req
          )
        );
        setSelectedRequest(req => ({ ...req, status: 'claimed', claimed_notes: claimNotes }));
      }
    } catch (error) {
      console.error('Error claiming request:', error);
      if (error.response && error.response.data && error.response.data.error) {
        message.error(`Failed to claim request: ${error.response.data.error}`);
      } else {
        message.error('Error claiming request');
      }
    }
  };

  const handleCompleteRequest = async (requestId) => {
    try {
      const formData = new FormData();
      formData.append('status', 'completed');
      formData.append('notes', completeNotes);
      if (statusPicture) {
        formData.append('status_picture', statusPicture);
      }

      const response = await api.post(`/request-manager/requests/${requestId}/update_status/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        message.success('Request Completed Successfully');
        setActiveRequestId(null);
        setCompleteNotes('');
        setStatusPicture(null);
        // Update the local state
        setRequests(prevRequests =>
          prevRequests.map(req =>
            req.id === requestId ? { ...req, status: 'completed', completed_notes: completeNotes } : req
          )
        );
        closeModal();
      }
    } catch (error) {
      console.error('Error completing request:', error);
      message.error('Error completing request');
    }
  };

  const handleApproveSnowEdgeRequest = async (requestId) => {
    try {
      const formData = new FormData();
      formData.append('status', 'approved');
      formData.append('status_notes', statusNotes);
      if (statusPicture) {
        formData.append('status_picture', statusPicture);
      }

      await api.post(`/request-manager/snow-edge-requests/${requestId}/update_status/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('SnowEdge Request Approved Successfully');
      setStatusNotes('');
      setStatusPicture(null);
      // Update the local state
      setSnowEdgeRequests(prevRequests =>
        prevRequests.map(req =>
          req.id === requestId ? { ...req, status: 'approved' } : req
        )
      );
    } catch (error) {
      console.error('Error approving SnowEdge request:', error);
      message.error('Error approving SnowEdge request');
    }
  };
  
/*   const debouncedStaffSearch = useCallback(
    debounce(async (searchValue) => {
      if (!searchValue) {
        setStaffMembers([]);
        return;
      }
      
      setStaffSearchLoading(true);
      try {
        const response = await api.get('/staff/', {
          params: { search: searchValue }
        });
        setStaffMembers(response.data);
      } catch (error) {
        console.error('Failed to search staff:', error);
        message.error('Failed to search staff members');
      } finally {
        setStaffSearchLoading(false);
      }
    }, 500),
    []
  ); */

  const filteredRequests = useMemo(() => {
    return requests
      .filter((request) => {
        if (clientFilter === null) return true;
        return request?.site?.client?.client_id === clientFilter;
      })
      .filter((request) => typeFilter === null || request.request_type === typeFilter)
      .filter((request) => {
        if (statusFilter === null) return true;
        if (Array.isArray(statusFilter)) {
          return statusFilter.includes(request.status);
        }
        return request.status === statusFilter;
      });
  }, [requests, clientFilter, typeFilter, statusFilter]);

  const handleEditNotes = () => {
    setIsEditingNotes(true);
    setEditedStatusNotes(selectedRequest.status_notes || '');
  };

  const handleSaveNotes = async () => {
    try {
      const formData = new FormData();
      formData.append('status_notes', editedStatusNotes);
      if (statusPicture) {
        formData.append('status_picture', statusPicture);
      }

      const response = await api.patch(`/request-manager/requests/${selectedRequest.id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        // Update the local state
        setRequests(prevRequests =>
          prevRequests.map(req =>
            req.id === selectedRequest.id ? { ...req, status_notes: editedStatusNotes } : req
          )
        );
        setSelectedRequest({ ...selectedRequest, status_notes: editedStatusNotes });
        setIsEditingNotes(false);
        setStatusPicture(null);
        message.success('Status notes and pictures updated successfully');
      } else {
        throw new Error('Failed to update status notes');
      }
    } catch (error) {
      console.error('Error updating status notes and pictures:', error);
      message.error('Failed to update status notes and pictures');
    }
  };

  const handleRequestReceiverChange = async (value) => {
    try {
      await api.post(`/staff/${value}/set_request_receiver/`);
      setSelectedRequestReceiver(value);
      message.success('Request receiver updated successfully');
    } catch (error) {
      console.error('Failed to update request receiver:', error);
      message.error('Failed to update request receiver');
    }
  };

  const handleCreateDispatch = async () => {
    try {
      if (!selectedStaff) {
        message.error('Please select a staff member');
        return;
      }

      const dispatchData = {
        staff: selectedStaff,
        notes: dispatchNotes,
        [requestType === 'regular' ? 'regular_request' : 'snowedge_request']: selectedRequest.id
      };

      const response = await api.post('/request-manager/request-dispatches/', dispatchData);
      message.success('Dispatch created successfully');
      
      // Refresh the active dispatches
      const updatedDispatches = await api.get('/request-manager/request-dispatches/', {
        params: {
          [requestType === 'regular' ? 'regular_request' : 'snowedge_request']: selectedRequest.id,
          status: ['unread', 'read', 'confirmed']
        }
      });
      setActiveDispatches(updatedDispatches.data);
      
      // Reset form
      setSelectedStaff(null);
      setDispatchNotes('');
    } catch (error) {
      console.error('Failed to create dispatch:', error);
      message.error('Failed to create dispatch');
    }
  };

  const handleCompleteDispatch = async (dispatchId) => {
    try {
      await api.post(`/request-manager/request-dispatches/${dispatchId}/update_status/`, {
        status: 'completed',
        response_notes: 'Dispatch completed successfully'
      });
      
      // Refresh active dispatch
      const response = await api.get(`/request-manager/request-dispatches/${dispatchId}/`);
      setActiveDispatch(response.data);
      message.success('Dispatch completed successfully');
    } catch (error) {
      message.error('Failed to complete dispatch');
    }
  };

  const handleGenerateReport = async (clientId) => {
    if (!clientId) {
      message.warning('Please select a client first');
      return;
    }

    try {
      setGeneratingReport(true);
      const response = await api.get(`/request-manager/client/${clientId}/report/`, {
        responseType: 'blob', // Important for handling PDF response
      });

      // Create a blob from the PDF stream
      const file = new Blob([response.data], { type: 'application/pdf' });
      
      // Create a link element and trigger download
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = response.headers['content-disposition']
        ? response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
        : 'client_requests.pdf';
      link.click();

      message.success('Report generated successfully');
    } catch (error) {
      console.error('Error generating report:', error);
      message.error('Failed to generate report');
    } finally {
      setGeneratingReport(false);
    }
  };

  const renderAdminStaffContent = () => (
    <div className="content-wrapper">
      <div className="left-panel">
        <div className="filter-container">
          <Switch
            checkedChildren="SnowEdge"
            unCheckedChildren="Regular"
            checked={requestType === 'snowedge'}
            onChange={(checked) => setRequestType(checked ? 'snowedge' : 'regular')}
          />

          <Space>
            <Select
              placeholder="Client"
              onChange={(value) => setClientFilter(value ?? null)}
              allowClear
              style={{ minWidth: '200px' }}
              dropdownMatchSelectWidth={false}
              options={clients
                .map((client) => ({
                  label: client.client_name,
                  value: client.client_id,
                }))
                .filter(
                  (option, index, self) =>
                    self.findIndex((o) => o.value === option.value) === index
                )}
              optionFilterProp='label'
              showSearch
              defaultOpen={false}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input.toLowerCase()) || false
              }
            />
            <Tooltip title="Generate Client Report">
              <Button
                type="primary"
                icon={<FileTextOutlined />}
                onClick={() => handleGenerateReport(clientFilter)}
                loading={generatingReport}
                disabled={!clientFilter}
                style={{ backgroundColor: '#034832' }}
              >
                Report
              </Button>
            </Tooltip>
          </Space>

          <Select placeholder="Type" onChange={(value) => setTypeFilter(value ?? null)} allowClear dropdownStyle={{ width: 'auto', whiteSpace: 'nowrap' }}>
            <Option value="salting_lots">Salting Lots</Option>
            <Option value="plowing_lots">Plowing Lots</Option>
            <Option value="emergency_exits">Emergency Exits</Option>
            <Option value="loading_docks">Loading Docks</Option>
            <Option value="walkways">Walkways</Option>
            <Option value="other">Other</Option>
          </Select>
          <Select 
            placeholder="Status" 
            onChange={(value) => setStatusFilter(value === undefined ? ['pending', 'claimed'] : value)} 
            allowClear 
            dropdownStyle={{ width: 'auto', whiteSpace: 'nowrap' }}
          >
            <Option value="pending">Pending</Option>
            <Option value="claimed">Claimed</Option>
            <Option value="completed">Completed</Option>
          </Select>
        </div>

        <List
          className="request-list"
          itemLayout="horizontal"
          dataSource={requestType === 'regular' ? filteredRequests : snowEdgeRequests}
          loading={requestsLoading}
          renderItem={(request) => (
            <List.Item
              key={request.id}
              onClick={() => handleRequestClick(request.id)}
              className={selectedRequest && selectedRequest.id === request.id ? 'active-request' : ''}
              style={{ cursor: 'pointer', borderLeft: selectedRequest && selectedRequest.id === request.id ? '4px solid #1890ff' : '4px solid transparent' }}
            >
              <List.Item.Meta
                title={
                  <div className="request-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Space>
                      <span><strong>{request.site && request.site.site_name ? request.site.site_name : 'Unassigned'}</strong></span>
                      <Tooltip title={request.client_email}>
                        <MailOutlined style={{ color: '#1890ff' }} />
                      </Tooltip>
                    </Space>
                    <span className="request-date" style={{ color: '#888' }}>
                      {request.created_at ? new Date(request.created_at).toLocaleDateString() : 'No Date'}
                    </span>
                  </div>
                }
                description={
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                      <span>{requestType === 'regular' ? getRequestTypeLabel(request.request_type) || 'No Type' : 'SnowEdge'}</span>
                      <span>
                        <Badge
                          status={
                            request.status === 'pending' ? 'warning' :
                            request.status === 'claimed' || request.status === 'approved' ? 'processing' :
                            'success'
                          }
                          text={request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                        />
                      </span>
                    </div>
                    {requestType === 'snowedge' && (
                      <div style={{ fontSize: '0.9em', color: '#666' }}>
                        <Space>
                          <CalendarOutlined />
                          <Text>
                            Requested: {request.requested_date ? new Date(request.requested_date).toLocaleDateString() : 'Not specified'}
                          </Text>
                        </Space>
                      </div>
                    )}
                    <div style={{ fontSize: '0.9em', color: '#666' }}>
                      <Space>
                        <EnvironmentOutlined />
                        <Text>{request.site && request.site.address ? request.site.address : 'No address specified'}</Text>
                      </Space>
                    </div>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>


      <div className="right-panel">
        <MapContainer
          center={[43.6532, -79.3832]}
          zoom={12}
          whenCreated={setMap}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url={getMapboxUrl()}
            attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            maxZoom={19}
          />
          {(requestType === 'regular' ? filteredRequests : snowEdgeRequests).map((request) => {
            const lat = requestType === 'regular' ? request.latitude : (request.site && request.site.latitude);
            const lon = requestType === 'regular' ? request.longitude : (request.site && request.site.longitude);

            if (lat && lon) {
              return (
                <Marker
                  key={request.id}
                  position={[lat, lon]}
                  icon={customMarkerIcon}
                  eventHandlers={{ click: () => handleRequestClick(request.id) }}
                >
                  <Popup>{requestType === 'regular' ? request.address : (request.site && request.site.address)}</Popup>
                </Marker>
              );
            }
            return null; // Skip rendering marker if coordinates are not available
          })}
        </MapContainer>
      </div>

      <Modal
        title={selectedRequest ? <Title level={4}>{requestType === 'regular' ? `Request #${selectedRequest.id}` : `SnowEdge Request #${selectedRequest.id}`}</Title> : <Title level={4}>Request Details</Title>}
        open={viewRequestOpen}
        onCancel={closeModal}
        footer={null}
        centered
        destroyOnClose
        width={1000} // Increased width slightly
      >
        {selectedRequest && (
          <Row gutter={[16, 16]}> {/* Reduced gutter */}
            {/* Left Column - Basic Info */}
            <Col span={7}>
              <Space direction="vertical" size="small" style={{ width: '100%' }}>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Statistic
                      title="Status"
                      value={selectedRequest.status.charAt(0).toUpperCase() + selectedRequest.status.slice(1)}
                      valueStyle={{
                        color: requestType === 'regular'
                          ? (selectedRequest.status === 'pending' ? '#faad14' :
                            selectedRequest.status === 'claimed' ? '#1890ff' : '#52c41a')
                          : (selectedRequest.status === 'submitted' ? '#faad14' :
                            selectedRequest.status === 'approved' ? '#52c41a' : '#1890ff')
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Type"
                      value={requestType === 'regular' ? selectedRequest.request_type : 'SnowEdge'}
                    />
                  </Col>
                </Row>
                <Card size="small">
                  <Space direction="vertical" size="small">
                    <div>
                      <CalendarOutlined style={{ marginRight: 8 }} />
                      <Text strong>Created:</Text> {selectedRequest.created_at ? new Date(selectedRequest.created_at).toLocaleDateString() : 'No Date'}
                    </div>
                    {requestType === 'regular' ? (
                      <>
                        {selectedRequest.claimed_at && (
                          <div>
                            <CalendarOutlined style={{ marginRight: 8 }} />
                            <Text strong>Claimed:</Text> {new Date(selectedRequest.claimed_at).toLocaleString()}
                          </div>
                        )}
                        {selectedRequest.completed_at && (
                          <div>
                            <CalendarOutlined style={{ marginRight: 8 }} />
                            <Text strong>Completed:</Text> {new Date(selectedRequest.completed_at).toLocaleString()}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div>
                          <CalendarOutlined style={{ marginRight: 8 }} />
                          <Text strong>Requested:</Text> {selectedRequest.requested_date ? new Date(selectedRequest.requested_date).toLocaleDateString() : 'Not specified'}
                        </div>
                        <div>
                          <ClockCircleOutlined style={{ marginRight: 8 }} />
                          <Text strong>Time:</Text> {selectedRequest.requested_time || 'Not specified'}
                        </div>
                      </>
                    )}
                  </Space>
                </Card>

                <Card size="small">
                  <Space direction="vertical" size="small">
                    <div>
                      <EnvironmentOutlined style={{ marginRight: 8 }} />
                      <Text strong>Site:</Text> {selectedRequest.site && selectedRequest.site.site_name ? selectedRequest.site.site_name : 'Unassigned'}
                    </div>
                    {selectedRequest.site && (
                      <Text type="secondary" style={{ marginLeft: 24 }}>
                        {selectedRequest.site.address || 'No address specified'}
                      </Text>
                    )}
                  </Space>
                </Card>
                
                <Card size="small" title={<><EnvironmentOutlined /> Location</>}>
                  <RequestLocationMap request={selectedRequest} requestType={requestType} />
                </Card>
                {requestType === 'snowedge' && (
                  <Card size="small">
                    <Space direction="vertical" size="small">
                      <div>
                        <UserOutlined style={{ marginRight: 8 }} />
                        <Text strong>Contact:</Text> {selectedRequest.contact_name || 'Not specified'}
                      </div>
                      <div>
                        <PhoneOutlined style={{ marginRight: 8 }} />
                        <Text strong>Phone:</Text> {selectedRequest.contact_phone || 'Not specified'}
                      </div>
                    </Space>
                  </Card>
                )}
              </Space>
            </Col>

            {/* Middle Column - Update to include dispatch section when claimed */}
            <Col span={9}>
              <Card
                size="small"
                title={<><InfoCircleOutlined /> Status Updates</>}
                style={{ height: '100%' }}
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  {/* Show claim/complete buttons if not claimed */}


                  {/* Show dispatch section if claimed but not completed */}
                  {selectedRequest.status === 'claimed' && (
                    <Collapse 
                      ghost
                      defaultActiveKey={['summary']}
                      style={{ marginTop: 16, marginLeft: -16, marginRight: -16 }}
                    >
                      <Collapse.Panel 
                        key="summary"
                        header={
                          <>
                            <Text strong style={{ fontSize: '16px', marginBottom: 8, display: 'block' }}>
                              Request Dispatches <InfoCircleOutlined style={{ fontSize: '14px', color: '#8c8c8c' }} />
                            </Text>
                            <Row gutter={[16, 16]} style={{ width: '100%' }}>
                              <Col span={8}>
                                <Statistic 
                                  title={<Text type="secondary">Total</Text>} 
                                  value={activeDispatches.length}
                                  valueStyle={{ fontSize: '20px' }}
                                />
                              </Col>
                              <Col span={8}>
                                <Statistic 
                                  title={<Text type="secondary">Completed</Text>}
                                  value={activeDispatches.filter(d => d.status === 'completed').length}
                                  valueStyle={{ color: '#52c41a', fontSize: '20px' }}
                                />
                              </Col>
                              <Col span={8}>
                                <Statistic 
                                  title={<Text type="secondary">In Progress</Text>}
                                  value={activeDispatches.filter(d => d.status !== 'completed').length}
                                  valueStyle={{ color: '#1890ff', fontSize: '20px' }}
                                />
                              </Col>
                            </Row>
                          </>
                        }
                      >
                        {activeDispatches.length > 0 && (
                          <div style={{ marginTop: 16 }}>
                            <div style={{ position: 'relative', margin: '0 20px' }}>
                              <Carousel
                                dots={{ className: 'carousel-dots' }}
                                infinite={false}
                              >
                                {activeDispatches.map(dispatch => (
                                  <div key={dispatch.id}>
                                    <Card 
                                      size="small"
                                      style={{ margin: '0 4px' }}
                                      title={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                          <Space>
                                            <Badge status={getDispatchStatusColor(dispatch.status)} />
                                            <Text strong>Dispatch #{dispatch.id}</Text>
                                          </Space>
                                          {dispatch.status !== 'completed' && (
                                            <Button 
                                              type="primary"
                                              size="small"
                                              onClick={() => handleCompleteDispatch(dispatch.id)}
                                            >
                                              Complete
                                            </Button>
                                          )}
                                        </div>
                                      }
                                    >
                                      <Space direction="vertical" size="small" style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <Text type="secondary">Staff:</Text>
                                          <Text strong>{dispatch.staff_name}</Text>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <Text type="secondary">Scheduled:</Text>
                                          <Text>{moment(dispatch.dispatched_for).format('LLL')}</Text>
                                        </div>
                                        {dispatch.notes && (
                                          <div>
                                            <Text type="secondary">Notes:</Text>
                                            <div style={{ marginTop: 4, backgroundColor: '#f5f5f5', padding: '4px 8px', borderRadius: 4 }}>
                                              <Text>{dispatch.notes}</Text>
                                            </div>
                                          </div>
                                        )}
                                      </Space>
                                    </Card>
                                  </div>
                                ))}
                              </Carousel>
                            </div>
                          </div>
                        )}

                        <Collapse 
                          ghost 
                          style={{ marginTop: 16, marginBottom: -16 }}
                        >
                          <Collapse.Panel 
                            header={
                              <Button type="primary" icon={<PlusOutlined />} size="small">
                                Add New Dispatch
                              </Button>
                            } 
                            key="1"
                          >
                            <Form layout="vertical">
                              <Form.Item label="Assign Staff">
                                <Select
                                  size="small"
                                  placeholder="Search staff..."
                                  value={selectedStaff}
                                  onChange={value => setSelectedStaff(value)}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) => 
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                  }
                                  searchValue={staffSearchValue}
                                  onSearch={value => setStaffSearchValue(value)}
                                >
                                  {staffMembers.map(staff => (
                                    <Select.Option key={staff.staff_id} value={staff.staff_id}>
                                      {staff.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>

                              <Form.Item label="Notes">
                                <Input.TextArea
                                  size="small"
                                  value={dispatchNotes}
                                  onChange={e => setDispatchNotes(e.target.value)}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>

                              <Button 
                                type="primary"
                                size="small"
                                onClick={handleCreateDispatch}
                                block
                              >
                                Create Dispatch
                              </Button>
                            </Form>
                          </Collapse.Panel>
                        </Collapse>
                      </Collapse.Panel>
                    </Collapse>
                  )}



                  {/* Claim Notes Section */}
                  <div>
                    <Text strong style={{ color: '#1890ff' }}>
                      <Badge status="processing" /> Claim Notes
                    </Text>
                    {selectedRequest.status === 'pending' ? (
                      <Input.TextArea
                        rows={3}
                        value={claimNotes}
                        onChange={(e) => setClaimNotes(e.target.value)}
                        placeholder="Enter claim notes..."
                        style={{ marginTop: '4px', backgroundColor: '#f9f9f9' }}
                      />
                    ) : (
                      <div style={{ padding: '8px', backgroundColor: '#f5f5f5', borderRadius: '4px', marginTop: '4px', minHeight: '40px' }}>
                        {selectedRequest.claimed_notes || 'No claim notes available.'}
                      </div>
                    )}
                  </div>

                  <Divider style={{ margin: '8px 0' }} />

                  {/* Complete Notes Section */}
                  <div>
                    <Text strong style={{ color: '#52c41a' }}>
                      <Badge status="success" /> Completion Notes
                    </Text>
                    {selectedRequest.status !== 'completed' ? (
                      <Input.TextArea
                        rows={3}
                        value={completeNotes}
                        onChange={(e) => setCompleteNotes(e.target.value)}
                        placeholder="Enter completion notes..."
                        style={{ marginTop: '4px', backgroundColor: '#f9f9f9' }}
                      />
                    ) : (
                      <div style={{ padding: '8px', backgroundColor: '#f5f5f5', borderRadius: '4px', marginTop: '4px', minHeight: '40px' }}>
                        {selectedRequest.completed_notes || 'No completion notes available.'}
                      </div>
                    )}
                  </div>

                  <Divider style={{ margin: '8px 0' }} />

                  {/* Action Buttons */}
                  <div style={{ textAlign: 'center' }}>
                    {requestType === 'regular' ? (
                      <Space>
                        <Button
                          type="primary"
                          onClick={() => handleClaimRequest(selectedRequest.id)}
                          disabled={selectedRequest.status !== 'pending'}
                        >
                          Claim Request
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => handleCompleteRequest(selectedRequest.id)}
                          disabled={selectedRequest.status === 'completed'}
                        >
                          Complete Request
                        </Button>
                      </Space>
                    ) : (
                      <Button
                        type="primary"
                        onClick={() => handleApproveSnowEdgeRequest(selectedRequest.id)}
                        disabled={selectedRequest.status === 'approved'}
                      >
                        Approve Request
                      </Button>
                    )}
                  </div>
                </Space>
              </Card>
            </Col>

            {/* Right Column - Pictures */}
            <Col span={8}>
              <Card
                size="small"
                title={<><InfoCircleOutlined /> Request Details</>}
                style={{ marginBottom: '16px', maxHeight: '150px', overflowY: 'auto' }}
              >
                <Descriptions size="small" column={1}>
                  <Descriptions.Item label="Details">
                    {selectedRequest.details || 'No details provided'}
                  </Descriptions.Item>
                  {requestType === 'snowedge' && (
                    <>
                      <Descriptions.Item label="Contact Name">
                        {selectedRequest.contact_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Contact Phone">
                        {selectedRequest.contact_phone}
                      </Descriptions.Item>
                      <Descriptions.Item label="Notes">
                        {selectedRequest.notes}
                      </Descriptions.Item>
                    </>
                  )}
                </Descriptions>
              </Card>

              {/* Pictures Section */}
              <Card
                size="small"
                title={<><PictureOutlined /> Pictures</>}
                style={{ flexGrow: 1, overflowY: 'auto' }}
              >
                {(openRequest === null) ? null : openRequest['image_urls'].map((image_url) => (
                  <Image
                   key={image_url}
                   src={image_url}
                   alt="Status Picture"
                   style={{ width: '100%', height: '200px', objectFit: 'contain' }}
                 />
                )
                )}
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={handleUpload}
                  disabled={fileList.length === 0}
                  loading={uploading}
                  style={{
                    marginTop: 16,
                  }}
                >
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Card>
            </Col>
          </Row>
        )}
      </Modal>
    </div>
  );

  const handleTabChange = (key) => {
    setLoading(true);
    setTimeout(() => {
      setActiveTab(key);
      setLoading(false);
    }, 500); // Adjust this delay as needed
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case '1':
        return <ClientRequestForm />;
      case '2':
        return <SnowEdgeRequestForm />;
      case '3':
        return <ClientRequestsPortal clientEmail={userEmail} />;
      default:
        return null;
    }
  };

  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
      <Spin spinning={loading} tip="Loading...">
        <div className="requests-page">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
            {!isMobile ? (
              <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              style={{ flex: 1 }}
              tabBarExtraContent={
                userRole !== 'client' && (
                  <Space>
                  <Text strong>Active Dispatcher:</Text>
                  <Select
                    placeholder="Request Receiver"
                    value={selectedRequestReceiver}
                    onChange={handleRequestReceiverChange}
                    style={{ width: '200px' }}
                    showSearch
                    optionFilterProp="label" // Use label for search
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {staffMembers
                      .filter(staff => staff.work_email && staff.is_dispatcher)
                      .map(staff => (
                        <Option 
                          key={staff.staff_id} 
                          value={staff.staff_id} 
                          label={`${staff.name} (${staff.work_email})`} // Provide a string label for search
                        >
                          {staff.name} ({staff.work_email})
                        </Option>
                      ))}
                  </Select>
                </Space>
                )
              }
            >
              {userRole !== 'client' && (
                <>
                <TabPane tab="View Requests" key="1">
                  {renderAdminStaffContent()}
                </TabPane>
                <TabPane tab="Dispatches" key="5">
                  <DispatchRequestsTable />
                </TabPane>
              </>  
              )}
              {(userRole !== 'client' || hasRegularRequestAccess) && (
                <TabPane tab="Submit Regular Request" key="2">
                  <ClientRequestForm />
                </TabPane>
              )}
              {(userRole !== 'client' || hasSnowEdgeAccess) && (
                <TabPane tab="Submit SnowEdge Request" key="3">
                  <SnowEdgeRequestForm />
                </TabPane>
              )}
              <TabPane tab="Client Requests" key="4">
                <ClientRequestsPortal clientEmail={userEmail} />
              </TabPane>
            </Tabs>) : (
              <div style={{ width: '100%' }}>
                <Select
                  value={activeTab}
                  onChange={handleTabChange}
                  style={{ width: '100%', marginBottom: '16px' }}
                  defaultValue={"1"}
                >
                  <Select.Option value="1">Submit Regular Request</Select.Option>
                  {(userRole !== 'client' || hasSnowEdgeAccess) && (
                    <Select.Option value="2">Submit SnowEdge Request</Select.Option>
                  )}
                  <Select.Option value="3">Client Requests</Select.Option>
                </Select>
                <div>{renderTabContent()}</div>
              </div>
            )}
          </div>
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default RequestsPage;