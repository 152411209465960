import React, { useState, useEffect, useCallback } from 'react';
import { Table, Typography, Select, DatePicker, Button, message, Modal, Tag, Space } from 'antd';
import { Box } from '@mui/material';
import { IconDownload, IconThermometer, IconCloud, IconDroplet, IconWind, IconCheck, IconX, 
  IconCalendar, IconMail, IconUsers, IconMapPin } from '@tabler/icons-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import api from '../authentication/axiosInstance';
import styles from './StormStrategiesPage.module.css';
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const EST_TIMEZONE = 'America/New_York';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const StormStrategiesPage = () => {
  const [isTestMode, setIsTestMode] = useState(false);
  const [strategies, setStrategies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchRegion, setSearchRegion] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [regions, setRegions] = useState([]);
  const [stats, setStats] = useState({
    total_strategies: 0,
    successful_strategies: 0,
    failed_strategies: 0,
    recent_strategies: 0,
    unique_regions: 0
  });
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const handleKeyDown = useCallback((event) => {
    if (event.ctrlKey && event.altKey && event.key === 't') {
      setIsTestMode(prev => {
        const newMode = !prev;
        message.success(`Test mode ${newMode ? 'enabled' : 'disabled'}`);
        return newMode;
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    fetchStrategies();
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await api.get('/weatherworks/storm-strategies/stats/');
      if (response.data.success) {
        setStats(response.data.stats);
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const handleViewStrategy = async (strategy) => {
    try {
      // Fetch associated contacts for each site
      const sitesWithContacts = await Promise.all(strategy.sites.map(async (site) => {
        try {
          const response = await api.get(`/sites/${site.site_id}/associated_contacts/`);
          return {
            ...site,
            contacts: response.data
          };
        } catch (error) {
          console.error(`Error fetching contacts for site ${site.site_id}:`, error);
          return {
            ...site,
            contacts: []
          };
        }
      }));

      setSelectedStrategy({
        ...strategy,
        sites: sitesWithContacts
      });
      setModalVisible(true);
    } catch (error) {
      console.error('Error preparing strategy view:', error);
      message.error('Failed to load strategy details');
    }
  };

  const handleSendSurvey = async (strategyId) => {
    try {
      const response = await api.post(`/weatherworks/storm-strategies/${strategyId}/send-survey/`, {
        test_mode: isTestMode
      });
      if (response.data.success) {
        message.success(`Survey email sent successfully${isTestMode ? ' (Test Mode)' : ''}`);
      } else {
        message.error('Failed to send survey email');
      }
    } catch (error) {
      console.error('Error sending survey email:', error);
      message.error('Failed to send survey email');
    }
  };

  const formatToEST = (dateString) => {
    if (!dateString) return null;
    return dayjs(dateString).tz(EST_TIMEZONE).format('MMM D, YYYY h:mm A z');
  };

  const fetchStrategies = async () => {
    setLoading(true);
    try {
      const response = await api.get('/weatherworks/storm-strategies/');
      if (response.data.success) {
        const formattedStrategies = response.data.strategies.map(strategy => ({
          ...strategy,
          formatted_created_at: formatToEST(strategy.created_at),
          formatted_email_sent_at: formatToEST(strategy.email_sent_at)
        }));
        setStrategies(formattedStrategies);
        const uniqueRegions = [...new Set(formattedStrategies.map(s => s.region))];
        setRegions(uniqueRegions);
      }
    } catch (error) {
      console.error('Error fetching strategies:', error);
      message.error('Failed to fetch storm strategies');
    } finally {
      setLoading(false);
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [startDate, endDate] = dates;
      const estStartDate = startDate.tz(EST_TIMEZONE).startOf('day');
      const estEndDate = endDate.tz(EST_TIMEZONE).endOf('day');
      setDateRange([estStartDate, estEndDate]);
    } else {
      setDateRange(null);
    }
  };

  const applyFilters = () => {
    let url = '/weatherworks/storm-strategies/?';
    const params = new URLSearchParams();

    if (searchRegion) params.append('region', searchRegion);
    if (searchStatus) params.append('status', searchStatus);
    if (dateRange && dateRange[0] && dateRange[1]) {
      params.append('from_date', dateRange[0].format('YYYY-MM-DD'));
      params.append('to_date', dateRange[1].format('YYYY-MM-DD'));
    }

    fetchStrategiesWithFilters(url + params.toString());
  };

  const fetchStrategiesWithFilters = async (url) => {
    setLoading(true);
    try {
      const response = await api.get(url);
      if (response.data.success) {
        const formattedStrategies = response.data.strategies.map(strategy => ({
          ...strategy,
          formatted_created_at: formatToEST(strategy.created_at),
          formatted_email_sent_at: formatToEST(strategy.email_sent_at)
        }));
        setStrategies(formattedStrategies);
      }
    } catch (error) {
      console.error('Error fetching filtered strategies:', error);
      message.error('Failed to fetch filtered strategies');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      sorter: (a, b) => a.region.localeCompare(b.region),
      render: (text) => <span style={{ fontWeight: 500 }}>{text}</span>,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      width: 400,
      render: (text) => (
        <div className={styles.messageCell}>
          {text}
        </div>
      ),
    },
    {
      title: 'Sites',
      dataIndex: 'site_count',
      key: 'site_count',
      width: 100,
      sorter: (a, b) => a.site_count - b.site_count,
      render: (count) => (
        <span style={{ fontWeight: 500 }}>{count} sites</span>
      ),
    },
    {
      title: 'Weather Summary',
      key: 'weather_summary',
      width: 200,
      render: (_, record) => {
        const summary = record.weather_summary;
        if (!summary) return 'No weather data';
        return (
          <div className={styles.weatherSummary}>
            <div className={styles.weatherDetail}>
              <IconThermometer size={16} className={styles.weatherIcon} />
              <span>{summary.temperature}°C</span>
            </div>
            <div className={styles.weatherDetail}>
              <IconCloud size={16} className={styles.weatherIcon} />
              <span>{summary.conditions}</span>
            </div>
            <div className={styles.weatherDetail}>
              <IconDroplet size={16} className={styles.weatherIcon} />
              <span>{summary.precipitation}% precipitation</span>
            </div>
            <div className={styles.weatherDetail}>
              <IconWind size={16} className={styles.weatherIcon} />
              <span>{summary.wind_speed} km/h</span>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (status) => (
        <div className={`${styles.statusBadge} ${styles[status]}`}>
          {status === 'sent' ? (
            <IconCheck size={16} />
          ) : (
            <IconX size={16} />
          )}
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'formatted_created_at',
      key: 'created_at',
      width: 200,
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: 'Email Sent At',
      dataIndex: 'formatted_email_sent_at',
      key: 'email_sent_at',
      width: 200,
    }
  ];

  const renderStrategyModal = () => {
    if (!selectedStrategy) return null;

    // Get all contacts from each site
    const recipientsBysite = isTestMode ? 
      selectedStrategy.sites?.map(site => ({
        site_name: site.site_name,
        contacts: [{ email: 'mmicic@consalt.ca', first_name: 'Test', last_name: 'Mode' }]
      })) :
      selectedStrategy.sites?.map(site => ({
        site_name: site.site_name,
        contacts: site.contacts || []
      }));

    return (
      <Modal
        title={null}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            gap: '12px',
            padding: '20px 24px',
            marginTop: '20px',
            borderTop: '1px solid #f0f0f0'
          }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {isTestMode && (
                <Tag color="warning">Test Mode - Sending to mmicic@consalt.ca</Tag>
              )}
            </div>
            <div style={{ display: 'flex', gap: '12px' }}>
              <Button 
                onClick={() => handleSendSurvey(selectedStrategy.id)}
                type="primary"
                icon={<IconMail size={16} />}
                className={styles.surveyButton}
              >
                Send Survey Email
              </Button>
              <Button onClick={() => setModalVisible(false)}>
                Close
              </Button>
            </div>
          </div>
        }
        width={800}
        className={styles.strategyModal}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <div className={styles.modalHeaderContent}>
              <h2 className={styles.modalTitle}>Storm Strategy Details</h2>
              <div className={`${styles.statusBadge} ${styles[selectedStrategy.status]}`}>
                {selectedStrategy.status === 'sent' ? (
                  <IconCheck size={16} />
                ) : (
                  <IconX size={16} />
                )}
                {selectedStrategy.status.charAt(0).toUpperCase() + selectedStrategy.status.slice(1)}
              </div>
            </div>
            <div className={styles.modalSubheader}>
              <div className={styles.modalDetail}>
                <IconMapPin size={18} />
                <span>{selectedStrategy.region}</span>
              </div>
              <div className={styles.modalDetail}>
                <IconCalendar size={18} />
                <span>{selectedStrategy.formatted_created_at}</span>
              </div>
              <div className={styles.modalDetail}>
                <IconUsers size={18} />
                <span>{selectedStrategy.site_count} sites affected</span>
              </div>
              {selectedStrategy.formatted_email_sent_at && (
                <div className={styles.modalDetail}>
                  <IconMail size={18} />
                  <span>Sent at {selectedStrategy.formatted_email_sent_at}</span>
                </div>
              )}
            </div>
          </div>

          <div className={styles.modalSection}>
            <h3 className={styles.sectionTitle}>
              Recipients by Site
              {isTestMode && <Tag color="warning" style={{ marginLeft: '8px', fontSize: '12px' }}>Test Mode</Tag>}
            </h3>
            <div className={styles.recipientsList}>
              {recipientsBysite?.map((siteData, siteIndex) => (
                <div key={siteIndex} className={styles.siteRecipients}>
                  <Tag className={styles.siteTag}>{siteData.site_name}</Tag>
                  <div className={styles.contactsList}>
                    {siteData.contacts.map((contact, contactIndex) => (
                      <Tag key={contactIndex} className={styles.emailTag}>
                        {contact.first_name} {contact.last_name} - {contact.email}
                      </Tag>
                    ))}
                    {siteData.contacts.length === 0 && (
                      <Typography.Text type="secondary" italic>No contacts assigned</Typography.Text>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.modalSection}>
            <h3 className={styles.sectionTitle}>Message</h3>
            <div className={styles.messageBox}>
              {selectedStrategy.message}
            </div>
          </div>

          <div className={styles.modalSection}>
            <h3 className={styles.sectionTitle}>Weather Conditions</h3>
            <div className={styles.weatherBox}>
              {selectedStrategy.weather_summary ? (
                <div className={styles.weatherGrid}>
                  <div className={styles.weatherGridItem}>
                    <IconThermometer size={24} className={styles.weatherIcon} />
                    <div className={styles.weatherValue}>
                      {selectedStrategy.weather_summary.temperature}°C
                    </div>
                    <div className={styles.weatherLabel}>Temperature</div>
                  </div>
                  <div className={styles.weatherGridItem}>
                    <IconCloud size={24} className={styles.weatherIcon} />
                    <div className={styles.weatherValue}>
                      {selectedStrategy.weather_summary.conditions}
                    </div>
                    <div className={styles.weatherLabel}>Conditions</div>
                  </div>
                  <div className={styles.weatherGridItem}>
                    <IconDroplet size={24} className={styles.weatherIcon} />
                    <div className={styles.weatherValue}>
                      {selectedStrategy.weather_summary.precipitation}%
                    </div>
                    <div className={styles.weatherLabel}>Precipitation</div>
                  </div>
                  <div className={styles.weatherGridItem}>
                    <IconWind size={24} className={styles.weatherIcon} />
                    <div className={styles.weatherValue}>
                      {selectedStrategy.weather_summary.wind_speed} km/h
                    </div>
                    <div className={styles.weatherLabel}>Wind Speed</div>
                  </div>
                </div>
              ) : (
                <Typography.Text type="secondary">No weather data available</Typography.Text>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Title level={4} className={styles.title}>Storm Strategies History</Title>
        <Typography.Text className={styles.subtitle}>
          View and manage all storm strategies sent to sites
          {isTestMode && (
            <Tag color="warning" style={{ marginLeft: '8px' }}>
              Test Mode
            </Tag>
          )}
        </Typography.Text>
      </div>

      <div className={styles.statsContainer}>
        <div className={styles.statCard}>
          <span className={styles.statLabel}>Total Strategies</span>
          <span className={styles.statValue}>{stats.total_strategies}</span>
        </div>
        <div className={styles.statCard}>
          <span className={styles.statLabel}>Successfully Sent</span>
          <span className={styles.statValue}>{stats.successful_strategies}</span>
        </div>
        <div className={styles.statCard}>
          <span className={styles.statLabel}>Failed to Send</span>
          <span className={styles.statValue}>{stats.failed_strategies}</span>
        </div>
        <div className={styles.statCard}>
          <span className={styles.statLabel}>Last 30 Days</span>
          <span className={styles.statValue}>{stats.recent_strategies}</span>
        </div>
      </div>

      <div className={styles.filtersContainer}>
        <div className={styles.filterGroup}>
          <div className={styles.filterItem}>
            <Typography.Text className={styles.filterLabel}>Region</Typography.Text>
            <Select
              style={{ width: 200 }}
              placeholder="Filter by region"
              allowClear
              value={searchRegion}
              onChange={setSearchRegion}
            >
              {regions.map(region => (
                <Option key={region} value={region}>{region}</Option>
              ))}
            </Select>
          </div>

          <div className={styles.filterItem}>
            <Typography.Text className={styles.filterLabel}>Status</Typography.Text>
            <Select
              style={{ width: 200 }}
              placeholder="Filter by status"
              allowClear
              value={searchStatus}
              onChange={setSearchStatus}
            >
              <Option value="sent">Sent</Option>
              <Option value="failed">Failed</Option>
            </Select>
          </div>

          <div className={styles.filterItem}>
            <Typography.Text className={styles.filterLabel}>Date Range</Typography.Text>
            <RangePicker
              style={{ width: 300 }}
              onChange={handleDateRangeChange}
            />
          </div>

          <Button 
            type="primary"
            onClick={applyFilters}
            className={styles.button}
            style={{ backgroundColor: '#034832', borderColor: '#034832' }}
          >
            Apply Filters
          </Button>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <Table
          dataSource={strategies}
          columns={columns}
          rowKey="id"
          loading={loading}
          onRow={(record) => ({
            onClick: () => handleViewStrategy(record),
            className: styles.tableRow
          })}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} strategies`,
            style: { marginTop: 16 }
          }}
        />
      </div>

      {renderStrategyModal()}
    </div>
  );
};

export default StormStrategiesPage; 