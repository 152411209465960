import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import EquipmentAllocationTable from './components/EquipmentAllocationTable';
import SitesListView from './components/SitesListView';
import MapView from './components/MapView';
import EstimatePage from './pages/EstimatePage';
import HomePage from './pages/HomePage';
import Layout from './Layout';
import RequestsPage from './pages/RequestsPage';
import EquipmentListView from './components/EquipmentListView';
import EquipmentsPage from './components/EquipmentsPage';
import StaffListView from './components/StaffListView';
import ClientsListView from './components/ClientsListView';
import PlowAllocationPage from './pages/PlowAllocationPage';
import StaffAllocationPage from './pages/StaffAllocationPage';
import ProjectionPage from './pages/ProjectionPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ProtectedRoute from './authentication/ProtectedRoute';
import ProfilePage from './pages/ProfilePage';
import VehicleSubtypeListView from './components/VehicleSubtypeListView';
import StormsListView from './components/StormListView';
import SaltingRoutesAllocationPage from './pages/SaltingRoutesAllocationPage';
import WalkwayCrewAllocationPage from './pages/WalkwayCrewAllocationPage';
import PlowRouteAllocationPage from './pages/PlowRouteAllocationPage';
import WalkwayCrewDispatchPage from './pages/WalkwayCrewDispatch';
import SaltingRoutesDispatchPage from './pages/SaltingRoutesDispatch';
import LoaderAllocationDispatchPage from './pages/LoaderAllocationDispatch';
import DispatchHistoryPage from './pages/DispatchHistory';
import MessageListView from './components/MessageListView';
import NotificationListView from './components/NotificationListView';
import DebriefJobs from './pages/DebriefJobs';
import StormStrategyPage from './pages/StormStrategyPage';
import StormStrategiesPage from './pages/StormStrategiesPage';
import StormStrategySurveys from './pages/StormStrategySurveys';
//import ClientDashboard from './components/ClientDashboard';

function AppContent() {
  const { userRole } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <ProtectedRoute>
              {userRole === 'client' ? <Navigate to="/requests" replace /> : <HomePage />}
            </ProtectedRoute>
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="allocation"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <EquipmentAllocationTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="sites"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <SitesListView />
            </ProtectedRoute>
          }
        />
        <Route
          path="map"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <MapView />
            </ProtectedRoute>
          }
        />
        <Route
          path="plow"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <PlowAllocationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="staffAllocation"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <StaffAllocationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="walkwayCrew"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <WalkwayCrewAllocationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="walkwayCrewDispatch"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <WalkwayCrewDispatchPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="SaltingRoute"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <SaltingRoutesAllocationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="SaltingRouteDispatch"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <SaltingRoutesDispatchPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="LoaderDispatch"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <LoaderAllocationDispatchPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="estimatepage"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <EstimatePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="requests"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard', 'client']}>
              <RequestsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="vehicles"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <EquipmentListView />
            </ProtectedRoute>
          }
        />
        <Route
          path="equipments"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <EquipmentsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="staff"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <StaffListView />
            </ProtectedRoute>
          }
        />
        <Route
          path="projection"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <ProjectionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="subtypes"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <VehicleSubtypeListView />
            </ProtectedRoute>
          }
        />
        <Route
          path="storms"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <StormsListView />
            </ProtectedRoute>
          }
        />
        <Route
          path="messages"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <MessageListView />
            </ProtectedRoute>
          }
        />
        <Route
          path="clients"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <ClientsListView />
            </ProtectedRoute>
          }
        />
        <Route
          path="dispatchHistory"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <DispatchHistoryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="notifications"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <NotificationListView />
            </ProtectedRoute>
          }
        />
        <Route
          path="debriefJobs"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <DebriefJobs />
            </ProtectedRoute>
          }
        />
        <Route
          path="storm-strategy"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <StormStrategyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="storm-strategies"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <StormStrategiesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="storm-strategy-surveys"
          element={
            <ProtectedRoute allowedRoles={['admin', 'standard']}>
              <StormStrategySurveys />
            </ProtectedRoute>
          }
        />
{/*         <Route
          path="client-dashboard"
          element={
            <ProtectedRoute allowedRoles={['client']}>
              <ClientDashboard />
            </ProtectedRoute>
          }
        /> */}
      </Route>
    </Routes>
  );
}

export default AppContent;
